export const SEND_OTP = 'SEND_OTP';
export const VERIFY_OTP = 'VERIFY_OTP';
export const REGISTER_USER = 'REGISTER_USER';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_WITH_OTP = 'LOGIN_WITH_OTP';
export const TOKEN_LOGIN = 'TOKEN_LOGIN';
export const LOGOUT = 'LOGOUT';
export const CLEAR_AUTH_MESSAGE = 'CLEAR_AUTH_MESSAGE';
export const CLEAR_VERIFICATION_TOKEN = 'CLEAR_VERIFICATION_TOKEN';
export const TABLE_FILTER_LIST = 'TABLE_FILTER_LIST';
export const CLEAR_TABLE_FILTER_LIST = 'CLEAR_TABLE_FILTER_LIST';
export const TABLE_LIST = 'TABLE_LIST';
export const CLEAR_TABLE_LIST = 'CLEAR_TABLE_LIST';