import React, { useState, useEffect } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
} from "reactstrap";
import SafeSecureIcon from "../../assets/images/icons/safe-secure.png";
import Service24x7Icon from "../../assets/images/icons//24x7_service.png";
import PlayMobileIcon from "../../assets/images/icons/play-mobile.png";
import RealCashIcon from "../../assets/images/icons/real-cash.png";
import TrustIcon1Icon from "../../assets/images/icons/trust-icon-1.png";
import TrustIcon2Icon from "../../assets/images/icons/trust-icon-2.png";
import TrustIcon3Icon from "../../assets/images/icons/trust-icon-3.png";
import TrustIcon4Icon from "../../assets/images/icons/trust-icon-4.png";
import TrustIcon5Icon from "../../assets/images/icons/trust-icon-5.png";
import TrustIcon6Icon from "../../assets/images/icons/trust-icon-6.png";
import ResponsibleIcon from "../../assets/images/icons/responsible-icon.svg";
import LegalIcon from "../../assets/images/icons/legal-icon.svg";
import blog1 from "../../assets/images/blog/blog-img1.png";
import classnames from "classnames";

import UserHitenImg from "../../assets/images/img/user-hiten.jpg";
import UserChiragImg from "../../assets/images/img/user-chirag.jpg";
import UserProfile1Img from "../../assets/images/img/profile-icon1.jpg";
import UserProfile2Img from "../../assets/images/img/profile-icon2.jpg";
import UserProfileChetan from "../../assets/images/img/img1.png";
import UserProfileYash from "../../assets/images/img/yash.png";

// export { default as UserHitenImg } from "./img/user-hiten.jpg";
// export { default as UserChiragImg } from "./img/user-chirag.jpg";
// export { default as UserProfile1Img } from "./img/profile-icon1.jpg";
// export { default as UserProfile2Img } from "./img/profile-icon2.jpg";

// React slick files
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

// import { Nav, NavItem, NavLink } from 'reactstrap';

function Homepage() {
  const [tab, setTab] = useState("1");

  const [slideNav, setSlideNav] = useState();
  const [slideFor, setSlideFor] = useState();
  var sliderNavSettings = {
    slidesToShow: 3,
    centerMode: true,
    dots: true,
    autoplaySpeed: 2000,
    centerPadding: "10px",
    focusOnSelect: true,
  };
  var sliderForSettings = {
    slidesToShow: 1,
    fade: true,
    arrows: false,
  };
  console.log("Version 1.0");


  return (
    <div>
      <div className="high-light banner-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <ul className="d-flex justify-content-center">
                <li className="d-flex align-items-center">
                  <figure>
                    <img src={SafeSecureIcon} alt="safe-secure" />
                  </figure>
                  <p>Safe & Secure</p>
                </li>
                <li className="d-flex align-items-center">
                  <figure>
                    <img src={Service24x7Icon} alt="24x7_service" />
                  </figure>
                  <p>24/7 Service</p>
                </li>
                <li className="d-flex align-items-center">
                  <figure>
                    <img src={PlayMobileIcon} alt="play-mobile" />
                  </figure>
                  <p>Play on Mobile</p>
                </li>
                <li className="d-flex align-items-center">
                  <figure>
                    <img src={RealCashIcon} alt="real-cash" />
                  </figure>
                  <p>Win Real Cash</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Onliine Rummy --> */}
      <section className="trust-rummy-site">
        <div className="container">
          <h3 className="text-center theme-color">
            Most Trusted Rummy Site
          </h3>
          <div className="block-content d-flex justify-content-around trusted-items-list">
            <div className="d-flex align-items-center card-block">
              <figure className="game-logo">
                <img src={TrustIcon1Icon} alt="trust-icon" />
              </figure>
              <div className="block-desc">
                <h5>Play Most Favourite Rummy Games</h5>
                <ul className="highlight-list">
                  <li>Play Points, Pool & Deals Variations</li>
                  <li>24/7 Rummy Gameplay</li>
                  <li>Weekly Tournaments</li>
                </ul>
              </div>
            </div>
            <div className="d-flex align-items-center card-block">
              <figure className="game-logo">
                <img src={TrustIcon2Icon} alt="trust-icon" />
              </figure>
              <div className="block-desc">
                <h5>100% Safe and Secure</h5>
                <ul className="highlight-list">
                  <li>Various encryption layers for utmost security</li>
                  <li>Complete data privacy with cloud technology</li>
                  <li>SSL Secured</li>
                </ul>
              </div>
            </div>
            <div className="d-flex align-items-center card-block">
              <figure className="game-logo">
                <img src={TrustIcon3Icon} alt="trust-icon" />
              </figure>
              <div className="block-desc">
                <h5>The Ultimate Rummy Experience</h5>
                <ul className="highlight-list">
                  <li>Zero waiting time means more fun</li>
                  <li>Simultaneous rummy table gameplay</li>
                  <li>Swift rummy tables allocation</li>
                </ul>
              </div>
            </div>
            <div className="d-flex align-items-center card-block">
              <figure className="game-logo">
                <img src={TrustIcon4Icon} alt="trust-icon" />
              </figure>
              <div className="block-desc">
                <h5>Best Reward and offers in the market</h5>
                <ul className="highlight-list">
                  <li>Win real cash every day!</li>
                  <li>Welcome bonus of Rs 1000</li>
                  <li>Bonus and prizes for each tournament</li>
                </ul>
              </div>
            </div>
            <div className="d-flex align-items-center card-block">
              <figure className="game-logo">
                <img src={TrustIcon5Icon} alt="trust-icon" />
              </figure>
              <div className="block-desc">
                <h5>Responsible Gaming</h5>
                <ul className="highlight-list">
                  <li>Strict algorithm for a fair play policy</li>
                  <li>24/7 Customer Support for fraud detection</li>
                  <li>KYC processes to make sure a player’s genuineness</li>
                </ul>
              </div>
            </div>
            <div className="d-flex align-items-center card-block">
              <figure className="game-logo">
                <img src={TrustIcon6Icon} alt="trust-icon" />
              </figure>
              <div className="block-desc">
                <h5>Fastest Withdrawals</h5>
                <ul className="highlight-list">
                  <li>Hassle-free Withdrawal within Minutes</li>
                  <li>Withdraw real money anytime</li>
                  <li>No withdrawal fee</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Tab Panel Section --> */}
      <section className="sidebar-sec">
        <div className="container">
          <div className="game-overview">
            <h3 className="text-center theme-color">
              Play Rummy Online on Rummy Rani
            </h3>
            <p style={{ "color": "black" }}>
              Rummy is one of the most popular games in India. It’s easy, fun, and packed with a healthy dose of challenge that will keep you coming back for more. Rummy Rani is the online rummy platform that delivers this excitement directly to your preferred device. We bring the same game that was previously limited to small groups of friends and family to a digital format. With fast gameplay, a secure platform, and safe transactions, combined with international standards of gameplay, we are among India’s most popular gaming websites.
            </p>
            <p style={{ "color": "black" }}>
              Experiencing rummy just like you did offline is what every player looks for. We use advanced technology and extensive data analysis to provide each player with a personalized gameplay experience. Every time you come online, you’ll find your preferred 13-card game ready on your dashboard.
            </p>
          </div>
          {/* ***** */}
          <div className="cmn-pad-50 rr-info-tabs">
            <div className="">
              <ul className="player-role d-flex">
                <li>
                  <div className="d-flex align-items-center">
                    <div className="img-area">
                      <figure>
                        <img src={ResponsibleIcon} alt="responsible-icon" />
                      </figure>
                    </div>
                    <div className="player-desc">
                      <h4>Responsible Play:</h4>
                      <p style={{ "color": "black" }}>
                        Rummy Rani encourages all its players to play
                        responsibly. Stay in control, stay entertained!.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="d-flex align-items-center">
                    <div className="img-area">
                      <figure>
                        <img src={LegalIcon} alt="legal-icon" />
                      </figure>
                    </div>
                    <div className="player-desc">
                      <h4>100% Legal:</h4>
                      <p style={{ "color": "black" }}>
                        Rummy was declared to be a game of skill by the Hon'ble.
                        Supreme Court of India in 1968 and is 100% legal to play
                        for free or for cash.
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          {/* ****** */}
        </div>
      </section>
      {/* Slider review */}
    </div>
  );
}

export default Homepage;
