import React, { Fragment } from 'react'
import Header from '../../../components/Header'
import Sidebar from '../../../components/Sidebar'
import TournamentTransaction from './TournamentTransaction'
import rotate_img from '../../../assets/images/rotate.png'


import { connect } from 'react-redux'

function GameTransactions(props) {
    return (
        <Fragment>
            <div>
                <Header {...props} />
                <div className="account-container d-flex">
                    <Sidebar {...props} />
                    <TournamentTransaction {...props} />
                </div>
                <div className="rotate-box" style={{ "display": "none" }}>
                    <div>
                        <img src={rotate_img} alt="Rotate-Phone" />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default connect()(GameTransactions)