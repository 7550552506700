import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import Header from '../../../components/Header'
import Sidebar from '../../../components/Sidebar'
import InviteContent from './Invite'
import rotate_img from '../../../assets/images/rotate.png'


function Invite(props) {
    return (
        <Fragment>
            <div>
                <Header {...props} />
                {/* <div className="account-container d-flex"> */}
                    <Sidebar {...props} />
                    <InviteContent {...props} />
                {/* </div> */}
                <div className="rotate-box" style={{ "display": "none" }}>
                    <div>
                        <img src={rotate_img} alt="Rotate-Phone" />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default connect()(Invite)