import axios from '../axios'

import constants from '../constants'

const errMsg = 'Server is unavailable.'

export const getPassbook = (size, pageIndex, token, eType) => (dispatch) => {
    dispatch({ type: constants.CLEAR_PASSBOOK })
    let url = ``
    if (eType) {
        url = `/profile/getPassbook?size=${size}&pageIndex=${pageIndex}&eType=${eType}`
    } else {
        url = `/profile/getPassbook?size=${size}&pageIndex=${pageIndex}`
    }
    axios.get(url, {
        headers: {
            'authorization': token
        }
    })
        .then((response) => {
            dispatch({
                type: constants.GET_PASSBOOK,
                payload: {
                    data: response.data.data[0],
                    resStatus: true,
                    resMessage: response.data.message
                }
            })
        }).catch((error) => {
            dispatch({
                type: constants.GET_PASSBOOK,
                payload: {
                    resStatus: false,
                    resMessage: error.response ? error.response.data.message : errMsg
                }
            })
        })
}