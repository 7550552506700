import React, { useEffect } from 'react';
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import ReduxThunk from 'redux-thunk'
import { createBrowserHistory } from 'history';
import { ToastContainer } from 'react-toastify';
import { Router } from 'react-router-dom';
import Routes from './routes/index'
import reducers from './reducers/index'
import Socket from './Socket'

export const history = createBrowserHistory()


const componentEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
export const store = createStore(reducers, {}, componentEnhancers(applyMiddleware(ReduxThunk)))

function App() {
  useEffect(() => {
    // console.log = function () { }

    const handleScroll = () => {
      if (window.scrollY > 50) {
        document.body.classList.add('sticky');
      } else {
        document.body.classList.remove('sticky');
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div>
      <Provider store={store}>
        <Router history={history}>
          <Routes />
          <Socket />
        </Router>
      </Provider>
      <ToastContainer
        position='top-right'
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

const token = localStorage.getItem('Token')

if (token) {
  store.dispatch({
    type: 'TOKEN_LOGIN',
    payload: {
      token
    }
  })
  if (history.location.pathname === '/') {
    history.push('/lobby')
  } else {
    history.push({ pathname: history.location.pathname, search: history.location.search })
  }
} else {
  history.push({ pathname: history.location.pathname })
}

export default App;
