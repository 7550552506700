import React from 'react'
// import Home
import Footer from '../auth/Footer'
import Header from '../auth/Header';


const Legality = () => {
    return (
        <div>
            {/* <!--***** Start Body *****-->
            <!--***** Header *****--> */}
            {/* <Header /> */}
            <Header />
            {/* <!--***** //Header *****--> */}
            <section className="site-content about-page-section cmn-banner-bg cmn-pad">
                <div className="container">
                    <div className="text-center">
                        <h3 className="text-center page-title">Legality</h3>
                    </div>
                    <div className="about-us-content desclaimer">
                        <div className='about-details-sec'>
                            <h3>Understanding Gambling vs. Skill Games Under Indian Law</h3>
                            <p>The concept of gambling, or gaming, has been defined by the Supreme Court of India in a landmark 1996 judgment. According to this judgment, gambling refers specifically to betting and wagering on games of chance. The Supreme Court clarified that games of skill are excluded from the definition of gambling, regardless of whether they involve monetary stakes. The Court stated:</p>
                            <p>"The term 'gaming' in the relevant Acts should be understood in accordance with the legal principles established by this Court in two 1957 cases, where it was conclusively determined that a competition largely based on skill is not considered gambling. Gaming involves the act of betting or wagering on a game of chance, where chance predominates as the determining factor. Thus, 'gaming' under these Acts refers to betting or wagering on games of chance and does not encompass games of skill such as horse racing."</p>
                            <p>Additionally, the Public Gambling Act, which serves as the central legislation on gambling in India, and various subsequent state laws on the subject, explicitly state that "nothing in this Act shall apply to games of mere skill wherever played." This provision is also mentioned in the 1996 Supreme Court judgment concerning the laws of Tamil Nadu, which states:</p>
                            <p>"In any event, Section 11 of the Gaming Act explicitly exempts games of mere skill from the penal provisions of the two Acts."</p>
                            <h3>Definition of a Game of Skill Under Indian Law</h3>
                            <p>The Supreme Court of India, in a 1996 judgment, provided a clear definition of a "game of mere skill." According to this judgment:</p>
                            <p>Competitions where success relies on a substantial degree of skill are not considered "gambling." Even if there is an element of chance involved, if the game is predominantly a game of skill, it qualifies as a game of "mere skill."</p>
                            <p>The Court elaborated:</p>
                            <p>"We, therefore, hold that the expression 'mere skill' signifies a substantial degree or predominance of skill."</p>
                            <h3>Determining Whether Rummy is a Game of Skill</h3>
                            <p>The Supreme Court of India, in a 1968 ruling, addressed whether rummy constitutes a game of skill. The Court observed:</p>
                            <p>"Rummy, on the other hand, requires a certain amount of skill because the fall of the cards must be memorized, and the building up of Rummy requires considerable skill in holding and discarding cards. We cannot, therefore, say that the game of Rummy is a game of entire chance. It is mainly and predominantly a game of skill. The chance in Rummy is of the same character as the chance in a deal at a game of bridge."</p>
                            <p>Further reinforcing this perspective, the Supreme Court in a 1996 judgment noted:</p>
                            <p>"A game of skill, although the element of chance cannot be entirely eliminated, is one in which success depends principally upon the superior knowledge, training, attention, experience, and adroitness of the player. Golf, chess, and even Rummy are considered to be games of skill. The Courts have reasoned that few games, if any, consist purely of chance or skill. As such, a game of chance is one in which the element of chance predominates over the element of skill, and a game of skill is one in which the element of skill predominates over the element of chance. It is the dominant element—'skill' or 'chance'—that determines the character of the game."</p>
                            <h3>Do Games of Skill Enjoy Any Other Legal Protections?</h3>
                            <p>Yes, they do. In a 1957 ruling, the Supreme Court of India declared that prize competitions involving substantial skill are considered business activities. As such, they are protected under Article 19(1)(g) of the Constitution of India, which guarantees the right to practice any profession, or to carry on any occupation, trade, or business.</p>
                            <h3>Is It Legal to Play Rummy for Cash on www.RummyRani.co.in?</h3>
                            <p>According to various Supreme Court rulings and the Gaming Acts of India, the following points
                                are clear:</p>
                            <ul>
                                <li>Gaming or gambling refers to betting and wagering on games of chance.</li>
                                <li>Playing games of skill for cash does not constitute gambling.</li>
                                <li>Games of skill are exempt from the penal provisions of most gambling acts.</li>
                                <li>Rummy is considered a game of skill.</li>
                            </ul>
                            <p>Therefore, it is perfectly legal to play rummy for cash on <a href='www.RummyRani.co.in' target='_blank'>www.RummyRani.co.in</a>, provided you are not playing from the states of Assam, Telangana, Andhra Pradesh, Nagaland, and Sikkim. As we gain more clarity on the laws in these states, we may consider offering our services to their residents as well.</p>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--***** Footer *****--> */}
            <Footer />
        </div>
    )
}

export default Legality