import axios from '../axios'

import constants from '../constants'

const errMsg = 'Server is unavailable.'

export const getLobbyTableList = (eRummyType, eGameType, token) => (dispatch) => {
    dispatch({ type: constants.CLEAR_LOBBY })
    axios.post('/rummy/table/weblist', {
        eRummyType: eRummyType,
        eGameType: eGameType
    }, {
        headers: {
            'authorization': token
        },
    })
        .then((response) => {
            dispatch({
                type: constants.GET_LOBBY_TABLE_LIST,
                payload: {
                    data: response.data.data,
                    resStatus: true,
                    resMessage: response.data.message
                }
            })
        }).catch((error) => {
            dispatch({
                type: constants.GET_LOBBY_TABLE_LIST,
                payload: {
                    resStatus: false,
                    resMessage: error.response ? error.response.data.message : errMsg
                }
            })
        })
}

export const joinTable = (eRummyType, eGameType, nMaxPlayer, nTablePoint, token, nTableFee) => (dispatch) => {
    dispatch({ type: constants.CLEAR_LOBBY })
    axios.post('/rummy/table/join', {
        eRummyType, eGameType, nMaxPlayer, nTablePoint,
        ...(nTableFee ? { nTableFee } : {})
    }, {
        headers: {
            'authorization': token
        },
    })
        .then((response) => {
            dispatch({
                type: constants.JOIN_TABLE,
                payload: {
                    data: response.data.data,
                    resStatus: true,
                    resMessage: response.data.message
                }
            })
        }).catch((error) => {
            dispatch({
                type: constants.JOIN_TABLE,
                payload: {
                    resStatus: false,
                    resMessage: error.response ? error.response.data.message : errMsg
                }
            })
        })
}

export const getJoinedTables = (token) => (dispatch) => {
    dispatch({ type: constants.CLEAR_LOBBY })
    axios.get('/rummy/table/joined', {
        headers: {
            'authorization': token
        }
    })
        .then((response) => {
            dispatch({
                type: constants.GET_JOINED_TABLES,
                payload: {
                    data: response.data.data,
                    resStatus: true,
                    resMessage: response.data.message
                }
            })
        }).catch((error) => {
            dispatch({
                type: constants.GET_JOINED_TABLES,
                payload: {
                    resStatus: false,
                    resMessage: error.response ? error.response.data.message : errMsg
                }
            })
        })
}

export const getJoinedTournaments = (token) => (dispatch) => {
    dispatch({ type: constants.CLEAR_LOBBY })
    axios.get('/state/tournament', {
        headers: {
            'authorization': token
        }
    })
        .then((response) => {
            dispatch({
                type: constants.GET_JOINED_TOURNAMENTS,
                payload: {
                    data: response.data.data,
                    resStatus: true,
                    resMessage: response.data.message
                }
            })
        }).catch((error) => {
            dispatch({
                type: constants.GET_JOINED_TOURNAMENTS,
                payload: {
                    resStatus: false,
                    resMessage: error.response ? error.response.data.message : errMsg
                }
            })
        })
}

export const getTournamentQualifiers = (iTournamentId, token) => (dispatch) => {
    dispatch({ type: constants.CLEAR_LOBBY })
    axios.post('/state/tournament/qualifiers', {
        iTournamentId
    }, {
        headers: {
            'authorization': token
        }
    })
        .then((response) => {
            dispatch({
                type: constants.GET_TOURNAMENT_QUALIFIERS,
                payload: {
                    data: response.data.data,
                    resStatus: true,
                    resMessage: response.data.message
                }
            })
        }).catch((error) => {
            dispatch({
                type: constants.GET_TOURNAMENT_QUALIFIERS,
                payload: {
                    resStatus: false,
                    resMessage: error.response ? error.response.data.message : errMsg
                }
            })
        })
}


export const joinPrivateTable = (sPrivateCode, token) => (dispatch) => {
    dispatch({ type: constants.CLEAR_LOBBY })
    axios.post('/rummy/table/private/join', {
        sPrivateCode: sPrivateCode
    }, {
        headers: {
            'authorization': token
        },
    })
        .then((response) => {
            dispatch({
                type: constants.JOIN_PRIVATE_TABLE,
                payload: {
                    data: response.data.data,
                    resStatus: true,
                    resMessage: response.data.message
                }
            })
        }).catch((error) => {
            dispatch({
                type: constants.JOIN_PRIVATE_TABLE,
                payload: {
                    resStatus: false,
                    resMessage: error.response ? error.response.data.message : errMsg
                }
            })
        })
}

export const createPrivateTable = (eRummyType, nTablePoint, nMaxPlayer, token) => (dispatch) => {
    dispatch({ type: constants.CLEAR_LOBBY })
    axios.post('/rummy/table/private/create', {
        eRummyType, nTablePoint, nMaxPlayer
    }, {
        headers: {
            'authorization': token
        },
    })
        .then((response) => {
            dispatch({
                type: constants.CREATE_PRIVATE_TABLE,
                payload: {
                    data: response.data.data,
                    resStatus: true,
                    resMessage: response.data.message
                }
            })
        }).catch((error) => {
            dispatch({
                type: constants.CREATE_PRIVATE_TABLE,
                payload: {
                    resStatus: false,
                    resMessage: error.response ? error.response.data.message : errMsg
                }
            })
        })
}

export const getDailyBonus = (token) => (dispatch) => {
    dispatch({ type: constants.CLEAR_LOBBY })
    axios.post('/dailybonus/list', {}, {
        headers: {
            'authorization': token
        },
    })
        .then((response) => {
            dispatch({
                type: constants.DAILY_BONUS,
                payload: {
                    data: response.data.data,
                    resStatus: true,
                    resMessage: response.data.message
                }
            })
        }).catch((error) => {
            dispatch({
                type: constants.DAILY_BONUS,
                payload: {
                    resStatus: false,
                    resMessage: error.response ? error.response.data.message : errMsg
                }
            })
        })
}

export const getBonusWinAmount = (token) => (dispatch) => {
    dispatch({ type: constants.CLEAR_LOBBY })
    axios.post('/dailyBonus/spin', {}, {
        headers: {
            'authorization': token
        },
    })
        .then((response) => {
            dispatch({
                type: constants.BONUS_WIN,
                payload: {
                    data: response.data.data,
                    resStatus: true,
                    resMessage: response.data.message
                }
            })
        }).catch((error) => {
            dispatch({
                type: constants.BONUS_WIN,
                payload: {
                    resStatus: false,
                    resMessage: error.response ? error.response.data.message : errMsg
                }
            })
        })
}

export const dismissDailyBonus = (token) => (dispatch) => {
    dispatch({ type: constants.CLEAR_LOBBY })
    axios.post('/dailyBonus/dismiss', {}, {
        headers: {
            'authorization': token
        },
    })
        .then((response) => {
            dispatch({
                type: constants.DISMISS_BONUS,
                payload: {
                    resStatus: true,
                    resMessage: response.data.message
                }
            })
        }).catch((error) => {
            dispatch({
                type: constants.DISMISS_BONUS,
                payload: {
                    resStatus: false,
                    resMessage: error.response ? error.response.data.message : errMsg
                }
            })
        })
}