import React from 'react'
import { Link } from 'react-router-dom';

// import Home
import Footer from '../auth/Footer'
import Header from '../auth/Header';
import blog_img from '../../assets/images/blog/blog-img1.png'
import gameplay_image from '../../assets/images/blog/Game-play.png'

const Blog = () => {
    return (
        <div>
            {/* <!--***** Start Body *****-->
            <!--***** Header *****--> */}
            {/* <Header /> */}
            <Header />
            {/* <!--***** //Header *****--> */}
            {/* <!-- Tab Panel Section --> */}
            <section className="site-content cmn-banner-bg">
                <div className="container">
                    <div className="page-title cmn-pad mb-0">
                        <h3 className="text-center">blog</h3>
                    </div>
                </div>
            </section>
            <section className="blog-area cmn-pad">
                <div className="container">
                    <div className="row" style={{"display": "flex !important" , "justify-content": "center"}}>
                        <div className="col-md-6 col-lg-4">
                            <div className=" blog-content">
                                <Link to="/blog-inner"><div className="blog-hero" style={{ backgroundImage: `url(${gameplay_image})` }}></div></Link>

                                <div className="blog-desc text-center">
                                    <ul className="d-flex justify-content-between">
                                        <li className="user-icon">Hiten Dodiya</li>
                                        <li className="date-icon">15 jun 2023</li>
                                    </ul>
                                    <p className="short-desc">5 reasons for you to start learning online rummy</p>
                                    {/* <a className="theme-btn" href="#">view more</a> */}
                                    <Link to="/blog-inner" className="theme-btn">view more</Link>

                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </section >
            {/* <!--***** //Middle Section *****--> */}
            {/* <!--***** Footer *****--> */}
            <Footer />
        </div >
    )
}

export default Blog