/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, Nav, NavItem, NavLink, TabContent, TabPane, Table, Button, UncontrolledTooltip, List, Modal } from 'reactstrap';
import classnames from 'classnames';
import { connect, useDispatch, useSelector } from 'react-redux'
import info from '../../../assets/images/icons/info.png';
import filter from '../../../assets/images/icons/filter.png';
import Loading from '../../../components/Loading';
import ResetIcon from "../../../assets/images/icons/reset.png";
import TwoPlayer from "../../../assets/images/player-2.png";
import SixPlayer from "../../../assets/images/player-6.png";
import upArrow from '../../../assets/images/up-arrow.svg';
import downArrow from '../../../assets/images/down-arrow.svg';
import { getLobbyTableList, joinTable } from '../../../actions/lobby';



function Practice(props) {

    const [subTab, setSubTab] = useState('5');
    const [tableData, setTableData] = useState([]);
    const [filterToggle, setFilterToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [maxPlayerFilter, setMaxPlayerFilter] = useState(true);
    const [gameDetails, setGameDetails] = useState('');
    const [twoPlayerChecked, setTwoPlayerChecked] = useState(false);
    const [sixPlayerChecked, setSixPlayerChecked] = useState(false);
    const [showWarning, setShowWarning] = useState(false);


    const dispatch = useDispatch()
    const token = useSelector(state => state.auth.token)
    const lobbyTableData = useSelector(state => state.lobby.data)
    const mainTab = props?.mainTab
    const maintainance = props?.maintainance
    const hour = props?.hour
    const min = props?.min
    const sec = props?.sec

    useEffect(() => {
        if (lobbyTableData) {
            setLoading(false)
            setTableData(lobbyTableData)
        }
    }, [lobbyTableData])

    const toggleSubTab = tab => {
        if (subTab !== tab) setSubTab(tab);
    }

    useEffect(() => {
        if (mainTab === '1' && subTab === '5') {
            setLoading(true)
            dispatch(getLobbyTableList('point', 'practice', token))
            setFilterToggle(false)
            setGameDetails('Play for points which have a pre-decided value. One winner wins the complete prize pool at the end of each game.')

        }
        if (mainTab === '1' && subTab === '6') {
            setLoading(true)
            dispatch(getLobbyTableList('pool', 'practice', token))
            setFilterToggle(false)
            setGameDetails('Play until score reaches the maximum limit [101 or 201]. Winner of a deal gets 0 points and the rest accumulate points which are added to their score. A fixed entry fee forms the prize pool.')
        }
        if (mainTab === '1' && subTab === '7') {
            setLoading(true)
            dispatch(getLobbyTableList('deal', 'practice', token))
            setFilterToggle(false)
            setGameDetails('Play with chips that are allocated at the beginning of the deal for pre-decided number of deals. One winner wins all the chips at the end of each deal. At the end of pre-decided number of deals, player with  maximum number of chips is selected as the winner for the game.')
        }
        if (mainTab === '1' && subTab === '8') {
            setLoading(true)
            dispatch(getLobbyTableList('point21', 'practice', token))
            setFilterToggle(false)
            setGameDetails('Play for points which have a pre-decided value. One winner wins the complete prize pool at the end of each game.')
        }

    }, [mainTab, subTab])

    function handleAscending(sortType) {
        const tableObj = [...tableData];
        const ascendingSorted = tableObj.sort((a, b) => a[sortType] - b[sortType]);
        setTableData(ascendingSorted)
    }
    function handleDescending(sortType) {
        const tableObj = [...tableData];
        const descendingSorted = tableObj.sort((a, b) => b[sortType] - a[sortType]);
        setTableData(descendingSorted)
    }

    function handleFilter(e, type) {
        setFilterToggle(false)
        setMaxPlayerFilter(maxPlayerFilter => !maxPlayerFilter)
        switch (type) {
            case 'two':
                setTwoPlayerChecked(!twoPlayerChecked)
                break
            case 'six':
                setSixPlayerChecked(!sixPlayerChecked)
                break
        }
        if (maxPlayerFilter) {
            const tableObj = [...tableData];
            const result = tableObj.filter((tb) => tb.nMaxPlayer == e.target.value);
            setTableData(result)
        } else {
            if (mainTab === '1' && subTab === '5') {
                dispatch(getLobbyTableList('point', 'practice', token))
            }
            if (mainTab === '1' && subTab === '6') {
                dispatch(getLobbyTableList('pool', 'practice', token))
            }
            if (mainTab === '1' && subTab === '7') {
                dispatch(getLobbyTableList('deal', 'practice', token))
            }
            if (mainTab === '1' && subTab === '8') {
                dispatch(getLobbyTableList('point21', 'practice', token))
            }
        }
    }

    function resetFilter() {
        setFilterToggle(false)
        setMaxPlayerFilter(true)
        setTwoPlayerChecked(false)
        setSixPlayerChecked(false)
        if (mainTab === '1' && subTab === '5') {
            dispatch(getLobbyTableList('point', 'practice', token))
        }
        if (mainTab === '1' && subTab === '6') {
            dispatch(getLobbyTableList('pool', 'practice', token))
        }
        if (mainTab === '1' && subTab === '7') {
            dispatch(getLobbyTableList('deal', 'practice', token))
        }
        if (mainTab === '1' && subTab === '8') {
            dispatch(getLobbyTableList('point21', 'practice', token))
        }
    }


    function handleGamePopup(rummyType, gameType, maxPlayer, tablePoint, tableFee) {
        // if (tableFee) {
        //     dispatch(joinTable(rummyType, gameType, maxPlayer, tablePoint, token, tableFee))
        // }
        // else {
        //     dispatch(joinTable(rummyType, gameType, maxPlayer, tablePoint, token))
        // }

        if (maintainance == false) {
            if (tableFee) {
                dispatch(joinTable(rummyType, gameType, maxPlayer, tablePoint, token, tableFee))
            }
            else {
                dispatch(joinTable(rummyType, gameType, maxPlayer, tablePoint, token))
            }
        } else if (maintainance == true) {
            setShowWarning(true)
        }

    }

    return (
        <div>
            {loading && <Loading />}

            <div className="tab-inner-header">
                <Nav tabs className="">
                    <NavItem className={classnames({ active: subTab === '5' },)} onClick={() => { toggleSubTab('5'); }} >

                        <span>Points Rummy</span>

                        {/* <button type="button" className={classnames({ active: subTab === '5' }, 'common-btn btn')} onClick={() => { toggleSubTab('5'); }}><span>Points Rummy</span>
                        </button> */}
                        {/* <NavLink className={classnames({ active: subTab === '5' }, 'common-btn btn')} onClick={() => { toggleSubTab('5'); }}><span>Points Rummy</span></NavLink> */}

                    </NavItem>
                    {/* <NavItem className={classnames({ active: subTab === '6' },)} onClick={() => { toggleSubTab('6'); }}><span>Pools Rummy</span> */}

                    {/* <button className={classnames({ active: subTab === '6' }, 'common-btn btn')} onClick={() => { toggleSubTab('6'); }}><span>Pools Rummy</span>
                        </button> */}


                    {/* <NavLink className={classnames({ active: subTab === '6' }, 'common-btn btn')} onClick={() => { toggleSubTab('6'); }}><span>Pools Rummy</span></NavLink> */}
                    {/* </NavItem> */}
                    {/* <NavItem className={classnames({ active: subTab === '7' },)} onClick={() => { toggleSubTab('7'); }}><span>Deals Rummy</span> */}
                    {/* <button className={classnames({ active: subTab === '7' }, 'common-btn btn')} onClick={() => { toggleSubTab('7'); }}><span>Deals Rummy</span>
                        </button> */}

                    {/* <NavLink className={classnames({ active: subTab === '7' }, 'common-btn btn')} onClick={() => { toggleSubTab('7'); }}><span>Deals Rummy</span></NavLink> */}
                    {/* </NavItem> */}
                    {/* <NavItem> */}

                    {/* <button className={classnames({ active: subTab === '8' }, 'common-btn btn')} onClick={() => { toggleSubTab('8'); }}><span>21 Card Point Rummy</span>
                        </button> */}

                    {/* <NavLink className={classnames({ active: subTab === '8' }, 'common-btn btn')} onClick={() => { toggleSubTab('8'); }}><span>21 Card Point Rummy</span></NavLink> */}

                    {/* RR old code below
                         <button
                                className={`nav-link common-btn ${eRummyType === "point21" && "active"
                                    }`}
                                id="card-point-rummy-tab"
                                data-toggle="tab"
                                href="#card-point-rummy"
                                role="tab"
                                aria-controls="card-point-rummy"
                                aria-selected="false"
                                onClick={() => setRummyType("point21")}
                            >
                                <span>21 Card Point Rummy</span>
                            </button> */}

                    {/* </NavItem> */}
                </Nav>

                <List type="unstyled" className="actions">
                    <li>
                        <a id="infoTooltip2" >
                            <img src={info} alt="info" />
                        </a>
                        <UncontrolledTooltip autohide={false} fade={false} placement="bottom" target="infoTooltip2" className="pop-desc">
                            {gameDetails}
                        </UncontrolledTooltip>
                    </li>
                    <li>
                        <a
                            onClick={() => setFilterToggle(filterToggle => !filterToggle)}
                        // className={filterToggle ? "common-btn yellow-btn" : "common-btn"}
                        >
                            <img src={filter} alt="filter" /></a>
                    </li>
                </List>

            </div>
            <div className={filterToggle ? " player-filter show" : " player-filter hide"}>
                {/* <div className="d-flex align-items-center"> */}
                <ul className="card card-body d-flex align-items-center">
                    <li>
                        <div className=" ">
                            <UncontrolledDropdown className=" ">
                                <DropdownToggle caret>
                                    Max Players
                                </DropdownToggle>
                                <DropdownMenu>
                                    <div>
                                        <label htmlFor="chkbox7" className="d-flex align-items-center custom-checkbox ">
                                            <input readOnly id="chkbox7" checked={twoPlayerChecked} type="checkbox" value="2" onClick={(e) => handleFilter(e, 'two')} />
                                            <span className="custom-control-label"> 2 Players</span>

                                        </label>
                                    </div>
                                    <div>
                                        <label htmlFor="chkbox8" className="d-flex align-items-center custom-checkbox">

                                            <input readOnly id="chkbox8" checked={sixPlayerChecked} type="checkbox" value="6" onClick={(e) => handleFilter(e, 'six')} />
                                            <span className="custom-control-label">6 Players</span>

                                        </label>
                                    </div>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    </li>
                    <li>
                        <button type="button" className='reset' onClick={resetFilter}>
                            {/* <img src={refresh} alt="refresh" /> Reset */}
                            <img src={ResetIcon} alt="reset" />
                        </button>
                    </li>
                </ul>
                {/* </div> */}
            </div>
            <TabContent activeTab={subTab}>
                <TabPane tabId="5">
                    <Table responsive className={tableData?.length ? "common-table" : "empty-list"}>
                        <thead>
                            <tr>
                                <th width="20%" className="text-center text-capitalize">name</th>
                                <th width="5%%" className="text-center text-capitalize">decks</th>
                                <th width="20%" className="text-capitalize">
                                    <div className="th-actions">
                                        <span> max players </span>
                                        <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending("nMaxPlayer")} ><img src={upArrow} alt="up-arrow" /></button>
                                        <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending("nMaxPlayer")} ><img src={downArrow} alt="down-arrow" /></button>

                                    </div>
                                </th>
                                <th width="20%" className="text-capitalize">
                                    <div className="th-actions">
                                        <span>Point</span>
                                        <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending("nTablePoint")} ><img src={upArrow} alt="up-arrow" /></button>
                                        <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending("nTablePoint")} ><img src={downArrow} alt="down-arrow" /></button>

                                    </div>
                                </th>
                                {/* <th width="20%" className="text-capitalize">
                                    <div className="th-actions">
                                        <span> min entry</span>
                                        <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending("nTableFee")} ><img src={upArrow} alt="up-arrow" /></button>
                                        <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending("nTableFee")} ><img src={downArrow} alt="down-arrow" /></button>

                                    </div>
                                </th> */}
                                <th width="15%" className="text-capitalize"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData?.length ? tableData.map((pointPrac, i) => (
                                <tr key={i}>
                                    <td className="text-center">
                                        <div className="favourite ">
                                            {/* <button type="button" onClick={() => handleStarPracPoint(pointPrac._id, 'pointPractice')} className={pointPrac?.favourite ? "active border-0 bg-transparent" : "border-0 bg-transparent"}><img src={star} alt="star" /></button> */}
                                            <span>{pointPrac?.sName}</span>
                                        </div>
                                    </td>
                                    <td className="text-center">{pointPrac?.nDeck}</td>
                                    <td className="text-center">
                                        {/* {pointPrac?.nMaxPlayer} */}
                                        {pointPrac?.nMaxPlayer == 2 ? <img src={TwoPlayer} alt="Info" className='table-info-ico' /> : <img src={SixPlayer} alt="Info" className='table-info-ico' />}
                                    </td>
                                    <td className="text-center">{pointPrac?.nTablePoint}</td>
                                    {/* <td className="text-center">{pointPrac?.nTableFee} &nbsp;
                                        {pointPrac?.oBonus?.nValue > 0 &&
                                            <>
                                                <Button id={`pointCashBtn-${i}`} type="button" className="p-0 bg-transparent border-0" >
                                                    <img src={infoIcon} alt="Info" className='table-info-ico' />
                                                </Button>
                                                <UncontrolledTooltip placement="bottom" target={`pointCashBtn-${i}`} className="pop-desc bottom">
                                                    Bonus utilisation for this table is: {pointPrac?.oBonus?.nValue}%
                                                </UncontrolledTooltip>
                                            </>
                                        }
                                    </td> */}
                                    <td>
                                        <div className="text-end">
                                            <button type="button" onClick={() => handleGamePopup(pointPrac?.eRummyType, pointPrac?.eGameType, pointPrac?.nMaxPlayer, pointPrac?.nTablePoint)} className="common-outline-btn">
                                                <span className="bg-layer">
                                                    <span className="bg-layer-inner"></span>
                                                </span>
                                                <span className="main-layer">
                                                    Play
                                                </span>
                                            </button>

                                        </div>
                                    </td>
                                </tr>
                            )
                            ) :
                                <>
                                    <tr>
                                        <td colSpan="6" className="text-center">There are no Tables to join.<br /> Please come back later.</td>
                                    </tr>
                                </>
                            }
                        </tbody>
                    </Table>
                </TabPane>
                <TabPane tabId="6">
                    <Table responsive className={tableData?.length ? "common-table" : "empty-list"}>
                        <thead>
                            <tr>
                                <th width="20%" className="text-center text-capitalize">name</th>
                                <th width="5%">
                                    <div className="th-actions">
                                        <span> Type </span>
                                        <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending("nTablePoint")} ><img src={upArrow} alt="up-arrow" /></button>
                                        <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending("nTablePoint")} ><img src={downArrow} alt="down-arrow" /></button>
                                    </div>
                                </th>
                                <th width="20%" className="text-capitalize ">
                                    <div className=" th-actions">
                                        <span> max players </span>
                                        <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending("nMaxPlayer")} ><img src={upArrow} alt="up-arrow" /></button>
                                        <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending("nMaxPlayer")} ><img src={downArrow} alt="down-arrow" /></button>

                                    </div>
                                </th>
                                <th width="20%" className="text-capitalize">
                                    <div className="th-actions">
                                        <span>Entry Fee </span>
                                        <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending("nTableFee")} ><img src={upArrow} alt="up-arrow" /></button>
                                        <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending("nTableFee")} ><img src={downArrow} alt="down-arrow" /></button>

                                    </div>
                                </th>
                                {/* <th width="20%" className="text-capitalize">
                                    <div className="th-actions">
                                        <span>Prize </span>
                                        <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending("aWinningAmount[0]")} ><img src={upArrow} alt="up-arrow" /></button>
                                        <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending("aWinningAmount[0]")} ><img src={downArrow} alt="down-arrow" /></button>

                                    </div>
                                </th> */}
                                <th width="15%" className="text-capitalize"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData?.length ? tableData.map((poolPrac, i) =>
                                <tr key={i}>
                                    <td className="text-center">
                                        <div className="favourite text-center ">
                                            {/* <button onClick={() => handleStarPracPoint(poolPrac._id, 'poolPractice')} type="button" className={poolPrac?.favourite ? "active border-0 bg-transparent" : "border-0 bg-transparent"}><img src={star} alt="star" /></button> */}
                                            <span>{poolPrac?.sName}</span>
                                        </div>
                                    </td>
                                    <td className="text-center">{poolPrac?.nTablePoint}</td>
                                    <td className="text-center">
                                        {poolPrac?.nMaxPlayer == 2 ? <img src={TwoPlayer} alt="Info" className='table-info-ico' /> : <img src={SixPlayer} alt="Info" className='table-info-ico' />}
                                    </td>
                                    <td className="text-center">{poolPrac?.nTableFee?.toFixed(2)}</td>
                                    {/* <td className="text-center">{poolPrac?.aWinningAmount?.length ? poolPrac?.aWinningAmount[0] : '---'} &nbsp;
                                        {poolPrac?.oBonus?.nValue > 0 &&
                                            <>
                                                <Button id={`pointCashBtn-${i}`} type="button" className="p-0 bg-transparent border-0" >
                                                    <img src={infoIcon} alt="Info" className='table-info-ico' />
                                                </Button>
                                                <UncontrolledTooltip placement="bottom" target={`pointCashBtn-${i}`} className="pop-desc bottom">
                                                    Bonus utilisation for this table is: {poolPrac?.oBonus?.nValue}%
                                                </UncontrolledTooltip>
                                            </>
                                        }
                                    </td> */}
                                    <td>
                                        <div className="text-end">
                                            <button type="button" onClick={() => handleGamePopup(poolPrac?.eRummyType, poolPrac?.eGameType, poolPrac?.nMaxPlayer, poolPrac?.nTablePoint, poolPrac?.nTableFee)} className="common-outline-btn">
                                                <span className="bg-layer">
                                                    <span className="bg-layer-inner"></span>
                                                </span>
                                                <span className="main-layer">
                                                    Play
                                                </span>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ) :
                                <>
                                    <tr>
                                        <td colSpan="6" className="text-center">There are no Tables to join.<br /> Please come back later.</td>
                                    </tr>
                                </>}
                        </tbody>
                    </Table>
                </TabPane>
                <TabPane tabId="7">
                    <Table responsive className={tableData?.length ? "common-table" : "empty-list"}>
                        <thead>
                            <tr>
                                <th className="text-center">name</th>
                                <th>
                                    <div className="th-actions">
                                        <span>Deals </span>
                                        <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending("nTablePoint")} ><img src={upArrow} alt="up-arrow" /></button>
                                        <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending("nTablePoint")} ><img src={downArrow} alt="down-arrow" /></button>
                                    </div>
                                </th>
                                <th>
                                    <div className="th-actions">
                                        <span>max players</span>
                                        <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending("nMaxPlayer")} ><img src={upArrow} alt="up-arrow" /></button>
                                        <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending("nMaxPlayer")} ><img src={downArrow} alt="down-arrow" /></button>
                                    </div>
                                </th>
                                <th>
                                    <div className="th-actions">
                                        <span>Entry Fee</span>
                                        <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending("nTableFee")} ><img src={upArrow} alt="up-arrow" /></button>
                                        <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending("nTableFee")} ><img src={downArrow} alt="down-arrow" /></button>
                                    </div>
                                </th>
                                {/* <th>
                                    <div className="th-actions">
                                        <span> Prize </span>
                                        <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending("aWinningAmount[0]")} ><img src={upArrow} alt="up-arrow" /></button>
                                        <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending("aWinningAmount[0]")} ><img src={downArrow} alt="down-arrow" /></button>
                                    </div>
                                </th> */}
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData?.length ? tableData.map((dealPrac, i) =>
                                <tr key={i}>
                                    <td className="text-center">
                                        <div className="favourite text-center ">
                                            {/* <button type="button" onClick={() => handleStarPracPoint(dealPrac._id, 'dealPractice')} className={dealPrac?.favourite ? "active border-0 bg-transparent" : "border-0 bg-transparent"}><img src={star} alt="star" /></button> */}
                                            <span>{dealPrac?.sName}</span>
                                        </div>
                                    </td>
                                    <td className="text-center">{dealPrac?.nTablePoint}</td>
                                    <td className="text-center">
                                        {dealPrac?.nMaxPlayer == 2 ? <img src={TwoPlayer} alt="Info" className='table-info-ico' /> : <img src={SixPlayer} alt="Info" className='table-info-ico' />}
                                    </td>
                                    <td className="text-center">{dealPrac?.nTableFee}</td>
                                    {/* <td className="text-center">{dealPrac?.aWinningAmount?.length ? dealPrac?.aWinningAmount[0] : '---'} &nbsp;
                                        {dealPrac?.oBonus?.nValue > 0 &&
                                            <>
                                                <Button id={`pointCashBtn-${i}`} type="button" className="p-0 bg-transparent border-0" >
                                                    <img src={infoIcon} alt="Info" className='table-info-ico' />
                                                </Button>
                                                <UncontrolledTooltip placement="bottom" target={`pointCashBtn-${i}`} className="pop-desc bottom">
                                                    Bonus utilisation for this table is: {dealPrac?.oBonus?.nValue}%
                                                </UncontrolledTooltip>
                                            </>
                                        }
                                    </td> */}
                                    <td>
                                        <div className="text-end">
                                            <button type="button" onClick={() => handleGamePopup(dealPrac?.eRummyType, dealPrac?.eGameType, dealPrac?.nMaxPlayer, dealPrac?.nTablePoint, dealPrac?.nTableFee)} className="common-outline-btn">
                                                <span className="bg-layer">
                                                    <span className="bg-layer-inner"></span>
                                                </span>
                                                <span className="main-layer">
                                                    Play
                                                </span>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ) :
                                <>
                                    <tr>
                                        <td colSpan="6" className="text-center">There are no Tables to join.<br /> Please come back later.</td>
                                    </tr>
                                </>}
                        </tbody>
                    </Table>
                </TabPane>
                <TabPane tabId="8">
                    <Table responsive className={tableData?.length ? "common-table" : "empty-list"}>
                        <thead>
                            <tr>
                                <th className="text-center">name</th>
                                <th>
                                    <div className="th-actions">
                                        <span>Point</span>
                                        <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending("nTablePoint")} ><img src={upArrow} alt="up-arrow" /></button>
                                        <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending("nTablePoint")} ><img src={downArrow} alt="down-arrow" /></button>
                                    </div>
                                </th>
                                <th>
                                    <div className="th-actions">
                                        <span>max players </span>
                                        <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending("nMaxPlayer")} ><img src={upArrow} alt="up-arrow" /></button>
                                        <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending("nMaxPlayer")} ><img src={downArrow} alt="down-arrow" /></button>
                                    </div>
                                </th>
                                <th>
                                    <div className="th-actions">
                                        <span>Entry Fee</span>
                                        <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending("nTableFee")} ><img src={upArrow} alt="up-arrow" /></button>
                                        <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending("nTableFee")} ><img src={downArrow} alt="down-arrow" /></button>
                                    </div>
                                </th>
                                {/* <th>
                                    <div className="th-actions">
                                        <span>Prize </span>
                                        <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending("aWinningAmount[0]")} ><img src={upArrow} alt="up-arrow" /></button>
                                        <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending("aWinningAmount[0]")} ><img src={downArrow} alt="down-arrow" /></button>
                                    </div>
                                </th> */}
                                <th></th>
                            </tr>
                        </thead>
                        {/* <tbody>
                            <tr className='no-data-wrap'>
                                <td colSpan="6" className="text-center no-data">
                                    <h2 className='text-capitalize'> coming soon</h2>
                                </td>
                            </tr>
                        </tbody> */}

                        <tbody>
                            {tableData?.length ? tableData.map((point21Prac, i) =>
                                <tr key={i}>
                                    <td className="text-center">
                                        <div className="favourite text-center ">
                                            <span>{point21Prac?.sName}</span>
                                        </div>
                                    </td>
                                    <td className="text-center">{point21Prac?.nTablePoint?.toFixed(2)}</td>
                                    <td className="text-center">
                                        {point21Prac?.nMaxPlayer == 2 ? <img src={TwoPlayer} alt="Info" className='table-info-ico' /> : <img src={SixPlayer} alt="Info" className='table-info-ico' />}
                                    </td>
                                    <td className="text-center">{point21Prac?.nTableFee}</td>
                                    <td>
                                        <div className="text-end">
                                            <button type="button" onClick={() => handleGamePopup(point21Prac?.eRummyType, point21Prac?.eGameType, point21Prac?.nMaxPlayer, point21Prac?.nTablePoint)} className="common-outline-btn">
                                                <span className="bg-layer">
                                                    <span className="bg-layer-inner"></span>
                                                </span>
                                                <span className="main-layer">
                                                    Play
                                                </span>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ) :
                                <>
                                    <tr>
                                        <td colSpan="6" className="text-center">There are no Tables to join.<br /> Please come back later.</td>
                                    </tr>
                                </>}
                        </tbody>
                    </Table>
                </TabPane>
            </TabContent>

            <Modal isOpen={showWarning} className="logout-modal modal-dialog-centered" >
                <div className="modal-body logoutbackground maintainace-bg">
                    <div className="txt-msg-box">
                        <p><strong>The game is currently under maintenance.</strong> <br /> <br />It will be back in {hour > 0 && ("0" + hour).slice(-2)}{hour > 0 && ":"}{("0" + min).slice(-2)}:{("0" + sec).slice(-2)}.
                            {/* {endMaintainanace}. */}
                        </p>
                    </div>
                    <div className="new-create-box text-center">
                        <button
                            type="button"
                            className="btn theme-orange-btn"
                            onClick={() => setShowWarning(false)}
                        >
                            <span>okay</span>
                        </button>
                    </div>
                </div>
            </Modal>
        </div >
    );
}

export default connect()(Practice);
