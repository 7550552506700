import React from 'react'
// import Home
import Footer from '../auth/Footer'
import Header from '../auth/Header';


const GST = () => {
    return (
        <div>
            {/* <!--***** Start Body *****-->
            <!--***** Header *****--> */}
            {/* <Header /> */}
            <Header />
            {/* <!--***** //Header *****--> */}
            {/* <!--***** Middle Section *****--> */}
            <section className="site-content about-page-section cmn-banner-bg cmn-pad">
                <div className="container">
                    <div className="text-center">
                        <h3 className="text-center page-title">GST Policy</h3>
                    </div>
                    <div className="about-us-content desclaimer">
                        <div className='about-details-sec'>
                            <p>Effective from 1st October 2023, the Government of India has revised the National GST Laws to include a 28% GST on every deposit made across all online money gaming platforms.</p>

                            <p>However, there's some good news for you—we are absorbing this GST on your behalf! Take advantage of our limited-time Post-GST promotional offer and enjoy uninterrupted gaming.</p>
                            <p>Note: Withdrawals without gameplay will typically not be allowed. In certain cases, a 5% fee on the deposited amount, along with the 28% GST on the deposit, may be applied to process such withdrawals.</p>

                        </div>
                    </div>
                </div>
            </section>



            {/* <!--***** Footer *****--> */}
            <Footer />
        </div>
    )
}

export default GST