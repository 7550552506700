import React, { useState } from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames';

// import Home
import Footer from '../auth/Footer'
import Header from '../auth/Header';
import play_bg from '../../assets/images/how-to-play-bg.png'
import blog_inner from '../../assets/images/blog/blog-inner-banner.jpg'


const HowToPlay = () => {
    const [tab, setTab] = useState('1')
    return (
        <div>
            {/* <!--***** Start Body *****-->
            <!--***** Header *****--> */}
            {/* <Header /> */}
            <Header />
            {/* <!--***** //Header *****--> */}
            <section className="site-content how-to-play-page">
                <div className="sidebar-sec how-to-play-block parallax cmn-banner-bg " id="parallax-1">
                    <div className="container position-relative">
                        <div className="how-to-play-title">How to play</div>
                        <div className="how-to-play-content">
                            <div className='how-to-play'>
                                <div className="how-play-content">
                                    <p>Want to start playing Rummy but don't know how? Don't worry, because we have the perfect guide that tells you the rules and offers some tips to help you win the game. Remember that practice and experience in Rummy will sharpen your skills and increase your chances of winning.</p>
                                    <p>Rummy is a game played with two decks of cards, including two Jokers. To excel in the game, a player must decide whether to pick and discard cards from two heaps. One heap is a closed deck, where a player cannot see the card being picked, while the other is an open deck consisting of discarded cards. To win, players need to gather cards in a valid sequence or set.</p>
                                    <p>The cards in Rummy are ranked from low to high: Ace, 2, 3, 4, 5, 6, 7, 8, 9, 10, Jack, Queen, and King. The Ace, King, Queen, and Joker are worth 10 points each, while the rest are worth their face value</p>
                                    <figure className="image-banner">
                                        <img src={blog_inner} alt="blog-inner-banner" />

                                    </figure>
                                    <p>The basic rule of Rummy is to arrange 13 cards in a particular sequence or set. To win the game, a player must have two sequences, one of which must be a pure sequence, and the other a valid set. Without a pure sequence, one cannot win the game.</p>
                                    <p>A valid sequence of cards is formed only if it consists of three or more cards of the same suit. There are two types of sequences in Rummy: the pure sequence and the impure sequence.</p>
                                    <div className="highlighted-sec mar-top-25">
                                        <h3>Pure Sequence</h3>
                                        <p>A pure sequence is formed when three or more cards of the same suit are placed together in order. Keep in mind that the Joker or wild card cannot be part of a pure sequence.</p>
                                        <p>Examples of a pure sequence are: 2♥ 3♥ 4♥ </p>
                                    </div>
                                    <div className="highlighted-sec mar-top-25">
                                        <h3>Impure Sequence</h3>
                                        <p>An impure sequence is formed when three or more cards, including one or more Joker cards of the same suit, make a group.</p>
                                    </div>
                                    <figure className="image-banner">
                                        <img src={blog_inner} alt="blog-inner-banner" />

                                    </figure>
                                    <p>Now that you have a basic understanding of the types of cards used in Rummy, let's look at some instructions to help you start your game:</p>
                                    <ol>
                                        <li>The Rummy card game is played between 2 to 6 players with 2 decks of cards. Each player is dealt 13 cards, and a random card is chosen as the wild Joker or Joker card.</li>
                                        <li>Players must discard cards to form proper sequences and sets. They can also use a wild Joker or Joker card to form an impure sequence.</li>
                                        <li>During the game, if a player forms at least one pure sequence along with other groups, they can make an announcement and win the game.</li>
                                    </ol>
                                </div>
                            </div>
                        </div>


                    </div>  {/* container closed */}
                </div>
            </section>
            {/* <!--***** Footer *****--> */}
            <Footer />
        </div>
    )
}

export default HowToPlay