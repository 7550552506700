import React from 'react'
// import Home
import Footer from '../auth/Footer'
import Header from '../auth/Header';


const TermsOfService = () => {
    return (
        <div>
            {/* <!--***** Start Body *****-->
            <!--***** Header *****--> */}
            {/* <Header /> */}
            <Header />
            {/* <!--***** //Header *****--> */}
            {/* <!--***** Middle Section *****--> */}
            <section className="site-content about-page-section cmn-banner-bg cmn-pad">
                <div className="container">
                    <div className="text-center">
                        <h3 className="text-center page-title">Terms Of Condition</h3>
                    </div>
                    <div className="about-us-content desclaimer">
                        <div className='about-details-sec'>
                            <h3>Introduction</h3>
                            <p>Welcome to RummyRani, operated by Heksa Skills Private Ltd. By accessing or using the services provided on the RummyRani platform (hereafter referred to as the "Portal"), you agree to comply with and be bound by these Terms and Conditions (hereafter referred to as the "Terms"). If you do not agree with these Terms, please do not use the Portal.</p>
                            <h3>Parent Company and Contact Information</h3>
                            <p>
                                RummyRani is a service provided by Heksa Skills Private Ltd, registered at the following address:
                            </p>
                            <h5>Address of Registration:</h5>
                            <p>CN-307, SCO 60- 61-62, 3RD FLOOR, SECTOR 17C, Chandigarh G.P.O., Chandigarh, Chandigarh- 160017, Chandigarh.
                            </p>
                            <p>For any support-related queries, you can reach us at: Email:<a href='mailto:support@rummyrani.co in'>support@rummyrani.co.in</a> .</p>
                            <p>For grievance redressal, please contact our Grievance Officer at: Email:
                                <a href='mailto:grievanceofficer@rummyrani.co.in'> grievanceofficer@rummyrani.co.in</a>.</p>
                            <h3>User Account Creation and Maintenance</h3>
                            <p>
                                To use our services, you must create a user account by providing accurate and complete information. You are responsible for maintaining the confidentiality of your account details and for all activities that occur under your account. You must notify us immediately of any unauthorized use of your account or any other breach of security.
                            </p>
                            <h3>Eligibility</h3>
                            <p>You must be at least 18 years old to use our services. By creating an account, you represent and warrant that you are legally capable of entering into binding contracts and that you are not prohibited by law from accessing or using the Portal.
                            </p>
                            <h3>User Account Validation and Personal Information Verification</h3>

                            <p>RummyRani may from time to time attempt to validate its players' user accounts via phone or email. If we are unable to establish contact with you after multiple attempts, we reserve the right to disallow you from logging into the Portal or to reduce your play limits and/or Add Cash limits until we can satisfactorily validate your account. We may also ask you for proof of identification and address. Upon receipt of suitable documents, we will try to enable your user account at the earliest, but this may take a few business days. If validation attempts fail, we may permanently suspend your account and refund the balance (net of applicable GST) to the financial instrument used or by cheque to the provided address. The Privacy Policy of our Portal forms part of these Terms.</p>
                            <h3>User Restrictions</h3>
                            <h5>Anti-Cheating and Anti-Collusion</h5>
                            <p>You agree to play personally without external assistance and not to use unauthorized components, cheats, exploits, bots, hacks, or other third-party software. Team formation for collusion or any form of cheating is strictly prohibited. Detected cheating or collusion will result in actions including game cancellation and potential account bans as per our "Game Cancellation Settlement Policy."</p>
                            <h5>Money Laundering</h5>
                            <p>Activities construed as money laundering, including withdrawing cash from unutilized deposits or deliberately losing money to certain players, are prohibited.</p>
                            <h5>Anti-Spamming</h5>
                            <p>Sending unsolicited communications for registrations on the Portal is strictly prohibited.</p>
                            <h5>Multiple IDs</h5>
                            <p>You are restricted to a single user account. Creating or using multiple IDs is prohibited.</p>
                            <h5>
                                Prohibited Content
                            </h5>
                            <p>You must not upload, distribute, transmit, or post content that is defamatory, obscene, invasive of privacy, abusive, illegal, or otherwise objectionable.</p>
                            <h5>Proprietary Communication Protocols</h5>
                            <p>You shall not host, intercept, emulate, or redirect proprietary communication protocols used by the Portal.</p>
                            <h5>Commercial Use</h5>
                            <p>Using the Portal for commercial purposes, such as in a cyber cafe or network play over the Internet, is prohibited.</p>
                            <h5>Fraudulent Activity</h5>
                            <p>Any form of fraudulent activity, including using another person’s credit card or payment details, is strictly prohibited.</p>
                            <h5>Transfer of Winnings</h5>
                            <p>Winnings, bonuses, and prizes are unique to the player and non-transferable.</p>
                            <h5>Associated Persons</h5>
                            <p>Officers, directors, employees, consultants, agents of RummyRani, or their relatives, are prohibited from playing games that entitle them to prizes, except in the course of their engagement with us.
                            </p>
                            <h5>Defamatory Comments</h5>
                            <p>You shall not post material or comments on public media that are defamatory or detrimental to our business interests.</p>
                            <h5>Simultaneous Logins</h5>
                            <p>Accessing Cash Games using your ID on multiple devices simultaneously is discouraged, and we may disallow continuation of games from the earlier logged-in devices.</p>
                            <h3>Payments and Player Funds</h3>
                            <p>All transactions on the Portal shall be in Indian Rupees. Payments connected with participation in Cash Games must be made through your RummyRani user account. All cash prizes won are credited into this account. We maintain a record of all your transactions.
                            </p>
                            <h5>Add Cash Limits</h5>
                            <p>You can deposit money into your account subject to monthly Add Cash limits. Credit card, debit card, prepaid cash cards, and internet banking payments are processed through third-party payment gateways. We are not responsible for delays or denials by these gateways.</p>
                            <h5>Refund Policy</h5>
                            <p>We have the right to cancel a transaction at any point solely at our discretion. If a transaction is canceled after payment, the amount will be refunded to your payment instrument.</p>
                            <h5>User Funds</h5>
                            <p>All user funds are held in separate, non-interest-bearing accounts. Payouts can be made to users for withdrawals, platform fees, and tax deductions on winnings.</p>
                            <h3>User Account Suspension</h3>
                            <p>We reserve the right to suspend user accounts that violate these Terms or engage in prohibited activities. Suspended accounts may be subject to investigation, and reinstatement is at our discretion.</p>
                            <h3>Breach and Consequences</h3>
                            <p>Any breach of these Terms may result in immediate suspension or termination of your account, forfeiture of winnings, and legal action if necessary. We reserve the right to pursue all remedies available under the law for any breach.</p>
                            <h3>Complaints, Grievances, and Disputes</h3>
                            <p>Users can address complaints and grievances to our Grievance Officer at <a href='mailto:grievanceofficer@rummyrani.co.in'>grievanceofficer@rummyrani.co.in </a>. We will endeavor to resolve issues promptly and fairly. For unresolved disputes, the matter will be referred to arbitration as per the Arbitration and Conciliation Act, 1996.</p>
                            <h3>Modifications and Alterations</h3>
                            <p>RummyRani reserves the right to modify or alter these Terms at any time. Users will be notified of significant changes via email or on the Portal. Continued use of the Portal after changes implies acceptance of the revised Terms.
                            </p>
                            <h3>Limitation of Liability</h3>
                            <p>RummyRani and its parent company, Heksa Skills Private Ltd, are not liable for any indirect, incidental, consequential, or punitive damages arising from your use of the Portal. Our total liability for any claims arising under these Terms shall not exceed the amount paid by you to us in the six months preceding the claim.</p>
                            <h3>Disclaimer and Indemnity</h3>
                            <p>The Portal and its services are provided "as is" without warranties of any kind. You agree to indemnify, defend, and hold harmless RummyRani and its affiliates from any claims, liabilities, damages, and expenses arising from your use of the Portal, violation of these Terms, or infringement of any third-party rights.</p>
                            <h3>Governing Law, Dispute Resolution, and Jurisdiction</h3>
                            <p>These Terms shall be governed by and construed in accordance with the laws of India. Any disputes arising out of these Terms shall be subject to the exclusive jurisdiction of the courts in Chandigarh, India.</p>
                            <h3> Responsible Game Play</h3>
                            <p>We encourage responsible gaming and provide tools to help you play responsibly. If you feel you have a gambling problem, please seek help from professional organizations and consider using our self-exclusion tools.
                            </p>
                            <h3>Service Fee</h3>
                            <p>The service fee for different game formats ranges between 9% and 15%. This fee may be revised at the Platform's discretion, and we will make efforts to inform users of any changes promptly. However, there may be instances where the updated fee is not immediately reflected on the Portal.</p>
                            <h3>Account Deactivation and Reactivation</h3>
                            <p>RummyRani reserves the right to deactivate user accounts that are inactive for extended periods or that violate the Terms. Inactive accounts may be subject to closure after a specified period of inactivity, during which users will be notified via email. Reactivation of deactivated accounts will be subject to compliance with the current Terms and completion of any required verification processes. If your account is deactivated, you may contact RummyRani’s support team to inquire about reactivation procedures, but RummyRani is under no obligation to reactivate accounts.</p>
                            <h3>User Content and Intellectual Property</h3>
                            <p>All content provided by users on the Portal, including but not limited to profiles, messages, and uploaded materials, remains the property of the user but grants RummyRani a non-exclusive, royalty-free, perpetual, and worldwide license to use, reproduce, and display such content for the operation and promotion of the Portal. Users must ensure that any content uploaded does not infringe on the intellectual property rights of others and complies with applicable laws. RummyRani is not responsible for monitoring user-generated content, but reserves the right to remove or modify content that violates these Terms or is otherwise deemed inappropriate.
                            </p>
                            <h3>Force Majeure</h3>
                            <p>RummyRani will not be liable for any failure or delay in performance under these Terms if such failure or delay is due to circumstances beyond our reasonable control, including but not limited to natural disasters, acts of war, terrorism, or technological failures. In such cases, RummyRani will make reasonable efforts to mitigate the impact of these circumstances but is not responsible for any resulting losses or damages.
                            </p>
                            <h3>Communication and Notices</h3>
                            <p>All notices or communications required under these Terms will be provided via email to the address associated with your user account or through notifications on the Portal. You are responsible for keeping your contact information up-to-date and ensuring that you receive and review all communications from RummyRani. Notices sent via email are considered delivered upon sending, and notices posted on the Portal are considered delivered upon posting.
                            </p>
                            <h3>Severability</h3>
                            <p>If any provision of these Terms is found to be invalid or unenforceable by a court of competent jurisdiction, such provision will be deemed to be modified or removed to the extent necessary to make it enforceable. The remaining provisions will continue to be valid and enforceable.</p>
                            <h3>Entire Agreement</h3>
                            <p>These Terms constitute the entire agreement between you and RummyRani regarding your use of the Portal and supersede all prior or contemporaneous agreements, understandings, and communications, whether written or oral, regarding the subject matter of these Terms.</p>
                            <h3>Game Rules and Gameplay</h3>
                            <h5>Points Rummy Game Rules</h5>
                            <p>RummyRani offers an engaging gameplay experience with strict adherence to the classic rules of 13 Card Rummy. Here are the detailed rules and procedures for playing Points Rummy on our platform:
                            </p>
                            <h5>Cash Rummy Gameplay</h5>
                            <ul>
                                <li>
                                    <span>Points Rummy - Classic Rummy (13 Card Rummy): </span>
                                    <p>This format will be played against real players. If no real player is found, a Bot will appear to fill in.</p>
                                </li>
                                <li>
                                    <span>Entry Requirements:</span>
                                    <p>In Cash Rummy mode, users must bring cash or points (depending on the variation) to the table before starting the game. Users can add cash to play the game.</p>
                                </li>
                                <li>
                                    <span>Multi-Table Play: </span>
                                    <p>Users can play on multiple tables simultaneously, with a maximum of 3 tables allowed.</p>
                                </li>
                                <li>
                                    <span>Game Settings: </span>
                                    <p>A menu option is available in the gameplay for basic settings such as sound on/off, vibration on/off, card display, disconnection settings, game rules, last deal, report an issue, and refresh the game table.</p>
                                </li>
                                <li>
                                    <span>TDS Deduction: </span>
                                    <p>TDS (Tax Deducted at Source) will be deducted from the winning amount as per government tax laws.</p>
                                </li>
                            </ul>
                            <h5>Points Rummy Gameplay Mechanics
                            </h5>
                            <ul>
                                <li>
                                    <span>Selection of Players and Entry Fees: </span>
                                    <p>When selecting Points Rummy, users must choose the number of players (2 or 6) and set the entry fees accordingly.</p>
                                </li>
                                <li>
                                    <span>Objective:</span>
                                    <p>The primary objective is to arrange all 13 cards in sequences and/or sets. The first player to make a valid declaration wins the game.</p>
                                </li>
                                <li>
                                    <span>Game Start:  </span>
                                    <p>Once all players have joined, the game will start after a 10-second countdown. The player to make the first move is determined by dealing one card each at random. The highest card holder starts the game, followed by the distribution of 13 cards to each player.</p>
                                </li>
                                <li>
                                    <span>Deck Setup:</span>
                                    <p>The first card from the pack is placed in the open deck, signaling the start of the game. Remaining cards are placed face-down in the closed deck slot. A joker card is randomly selected at the beginning of each game.</p>
                                </li>
                                <li>
                                    <span>Types of Jokers: </span>
                                    <p>Two types of jokers are used - Wild Joker and printed Joker. Both can replace a card to form a sequence or set. Players can use the joker (randomly selected at the beginning) to complete a set/sequence.</p>
                                </li>
                                <li>
                                    <span>Gameplay:</span>
                                    <p>Each player takes turns picking/discarding cards from the closed/open deck to complete sets and/or sequences. Players can use one or more jokers to complete their sets/sequences. They can also drop out at any time during their turn, with points
                                        calculated accordingly.</p>
                                </li>
                                <li>
                                    <span>Declaration: </span>
                                    <p>To declare, players must show their cards by grouping them into sets/sequences and clicking the 'Declare' button. At least two sequences are mandatory, one of which must be a pure sequence. Players cannot use the same card twice in a set with two decks.</p>
                                </li>
                                <li>
                                    <span>Scoring: </span>
                                    <p>The first player to finish wins with 0 points. Players dropping in their first move get 20 points each; after the first move, they get 40 points each. Invalid declarations earn 80 points. Points calculation for losing players is based on ungrouped cards' face value, with a maximum of 80 points.</p>
                                </li>
                            </ul>
                            <h5>Special Rules</h5>
                            <ul>
                                <li>
                                    <span>Timer:</span>
                                    <p>Each player has a limited time to make their move</p>
                                </li>
                                <li>
                                    <span>Points Calculation:</span>
                                    <p>Cards carry points as follows: J, Q, K, and A carry 10 points each;
                                        other numbered cards carry their face value points; jokers carry zero points. Losing
                                        players’ points are calculated based on ungrouped cards, with a maximum of 80 points.</p>
                                </li>
                                <li>
                                    <span>Automatic Drop:</span>
                                    <p>Missing three consecutive turns results in an automatic drop with a
                                        middle drop score of 40 points.</p>
                                </li>


                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--***** Footer *****--> */}
            <Footer />
        </div>
    )
}

export default TermsOfService