import React, { Component, useState } from 'react'

import FancyVideo from 'react-videojs-fancybox';
import FancyVideo_one from '../../assets/videos/Temp.mp4';
import FancyVideo_two from '../../assets/videos/Temp.mp4';

import screenshot_one from '../../assets/video-screenshot/screenshot_1.png'
import screenshot_two from '../../assets/video-screenshot/screenshot_2.png'

// import ModalVideo from 'react-modal-videojs'

// import Home
import Footer from '../auth/Footer'
import Header from '../auth/Header';






const tutorials = () => {
    // const src = "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"
    // const poster = "https://raw.githubusercontent.com/waskito/react-modal-videojs/master/example/public/preview.png"

    // const [show, setShow] = useState(false)

    // function showModal() {
    //     setShow(true);
    // }

    // function hideModal() {
    //     setShow(false);
    // }

    return (
        <div>
            {/* <!--***** Start Body *****-->
        <!--***** Header *****--> */}
            {/* <Header /> */}
            <Header />
            {/* <!--***** //Header *****-->
   
            <!-- Tab Panel Section --> */}
            <section className="site-content">
                <div className="tutorials-block pad-50">
                    <div className="container">
                        <div className="page-title">
                            <h3>Rummy Tutorials</h3>
                        </div>
                        <ul className="d-flex justify-content-center">
                            <li>
                                <div className='position-relative'>
                                    <div className='container'>
                                        <FancyVideo
                                            source={FancyVideo_one}
                                            // source="https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/Sintel.mp4"
                                            // poster="https://raw.githubusercontent.com/waskito/react-modal-videojs/master/example/public/preview.png"
                                            poster='https://raw.githubusercontent.com/waskito/react-modal-videojs/master/example/public/preview.png'
                                            id={"demo-video-1"}
                                        />
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className='position-relative'>
                                    <div className='container'>
                                        <FancyVideo
                                            // source="https://www.youtube.com/watch?v=y9vJWwGRkiA"
                                            // poster="https://raw.githubusercontent.com/waskito/react-modal-videojs/master/example/public/preview.png"
                                            source={FancyVideo_two}
                                            poster='https://raw.githubusercontent.com/waskito/react-modal-videojs/master/example/public/preview.png'
                                            id={"demo-video-2"}
                                        />
                                    </div>
                                </div>
                                {/* <div className="thumbnail-section">
                                    <a data-fancybox href="https://youtu.be/rahRaVtEQaM">
                                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/yAoLSRbwxL8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </a> 
                                </div>*/}
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            {/* <!--***** //Middle Section *****--> */}


            {/* <!-- Onliine Rummy -->
        <!--***** Footer *****--> */}
            <Footer />
        </div>
    )
}

export default tutorials