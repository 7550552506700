/* eslint-disable react/prop-types */
import React from 'react';
import Footer from '../auth/Footer';
import Header from '../auth/Header';
// import Accordion from 'react-responsive-accordion';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

const Faq2 = () => {
    return (
        <div>
            <Header />
            <section className="site-content faq-page">
                <div className="sidebar-sec how-to-play-block cmn-banner-bg">
                    <div className="container position-relative">
                        <div className="faq-title">FAQ&apos;s</div>
                        <div className='faq-content'>
                            <div className='faq'>
                                <Accordion id='accordion' preExpanded={['acc1']} allowZeroExpanded>
                                    {/* {items.map((item) => ( */}
                                    <AccordionItem className='card' uuid='acc1'>
                                        <AccordionItemHeading className='card-header'>
                                            <AccordionItemButton className='btn-link'>
                                                How do you register on RummyRani.co.in?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            You can register for free through our application. Just
                                            enter your details like User ID, email, password and receive
                                            our welcome bonus straight into your inbox.
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    {/* ***** item closed ***** */}
                                    <AccordionItem className='card' uuid='acc2'>
                                        <AccordionItemHeading className='card-header'>
                                            <AccordionItemButton className='btn-link'>
                                                When can I start playing Rummy on RummyRani.co.in?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            Right after you register with us. We provide an absolute
                                            zero waiting time for all our players so they can play and
                                            win big.
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    {/* ***** item closed ***** */}
                                    <AccordionItem className='card' uuid='acc3'>
                                        <AccordionItemHeading className='card-header'>
                                            <AccordionItemButton className='btn-link'>
                                                I am new to Rummy and would like to learn more about the
                                                game. Please help!
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            We have a range of video tutorials listed on our website
                                            that’ll help you get an idea of the game. You can also learn
                                            through our practice sessions and become better with every
                                            game.
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    {/* ***** item closed ***** */}
                                    <AccordionItem className='card' uuid='acc4'>
                                        <AccordionItemHeading className='card-header'>
                                            <AccordionItemButton className='btn-link'>
                                                I have played practice matches and want to play for real
                                                cash now. How can I do that?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            You can quickly add cash into your account through our
                                            trusted Payment partners and begin playing and winning real
                                            cash.
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    {/* ***** item closed ***** */}
                                    <AccordionItem className='card' uuid='acc5'>
                                        <AccordionItemHeading className='card-header'>
                                            <AccordionItemButton className='btn-link'>
                                                Is my cash safe with RummyRani.co.in?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            Yes, your cash is always safe and accessible in your account
                                            in which you register with us.
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    {/* ***** item closed ***** */}
                                    <AccordionItem className='card' uuid='acc6'>
                                        <AccordionItemHeading className='card-header'>
                                            <AccordionItemButton className='btn-link'>
                                                How can I withdraw my money from RummyRani.co.in?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            You can easily withdraw cash from your account by simply
                                            filling up a form and choosing a method of payment. The cash
                                            will be transferred into your bank account without any
                                            delays or withdrawal charges applied.
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    {/* ***** item closed ***** */}
                                    <AccordionItem className='card' uuid='acc7'>
                                        <AccordionItemHeading className='card-header'>
                                            <AccordionItemButton className='btn-link'>
                                                What is a private room/table?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            On RummyRani.co.in, you can easily create or join a private
                                            table and compete with friends and family. All you have to
                                            do is create it and share the room code with people you want
                                            to play with.
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    {/* ***** item closed ***** */}
                                    <AccordionItem className='card' uuid='acc8'>
                                        <AccordionItemHeading className='card-header'>
                                            <AccordionItemButton className='btn-link'>
                                                Can I earn money by referral?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            Yes, RummyRani.co.in offers a very lucrative friend referral
                                            program for its players. Simply share the URL with your
                                            friends and once they download the app, you`&apos;ll receive real
                                            cash to play the game.
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    {/* ***** item closed ***** */}
                                    <AccordionItem className='card' uuid='acc9'>
                                        <AccordionItemHeading className='card-header'>
                                            <AccordionItemButton className='btn-link'>
                                                How much time does a player have to take their turn?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            Ideally, there would be a 30 seconds timer for each player.
                                            If he/she misses out, then additional 10 seconds are
                                            allotted to the player, after which their turn is considered
                                            over.
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    {/* ***** item closed ***** */}
                                    <AccordionItem className='card' uuid='acc10'>
                                        <AccordionItemHeading className='card-header'>
                                            <AccordionItemButton className='btn-link'>
                                                What is the agent program on RummyRani.co.in? Can I earn
                                                money by becoming an agent?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            The agent program is a unique feature of RummyRani.co.in
                                            that helps our users become agents by bringing many players
                                            under them. And, the agent will get an X% commission from
                                            the profit amount the users will generate for each round
                                            they win.
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    {/* ))} */}
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <Footer />
        </div >
    )
}

export default Faq2