import React from 'react'
import { Link } from 'react-router-dom';

// import Home
import Footer from '../auth/Footer'
import Header from '../auth/Header';
import blog_img from '../../assets/images/blog/blog-img1.png'
import blog_inner from '../../assets/images/blog/blog-inner-banner.jpg'
import gameplay_image from '../../assets/images/blog/Game-play.png'




const BlogInner = () => {
    return (
        <div>
            {/* <!--***** Start Body *****-->
            <!--***** Header *****--> */}
            {/* <Header /> */}
            <Header />
            {/* <!--***** //Header *****--> */}
            {/* <!-- Tab Panel Section --> */}
            <section className="site-content blog-inner-view">
                <div className="container">
                    <div className="blog-inner">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="blog-inner-content">
                                    <h3 className="pad-lr-25 title pad-tb-25">5 reasons for you to start learning online rummy</h3>
                                    <div className="blog-hero" style={{ backgroundImage: `url(${blog_inner})` }} ></div>
                                    <div className="blog-inner-desc pad-tb-25 pad-lr-15">
                                        <ul className="d-flex justify-content-between">
                                            <li className="user-icon">Hiten Dodiya</li>
                                            <li className="date-icon">15 jun 2023</li>
                                        </ul>
                                        <div className="cmn-content">
                                            <p>It may seem hard to believe, but there are people out there who cast a sceptical eye on learning Rummy - but wait, let&apos;s not judge them yet!</p>
                                            <p>Instead, letisiust shed some light to see the potential a game of Rurnmy has, both in terms of entertainment and skill. Look around and notice- isn&apos;t everything about learning? Delivering learning, evaluating lessons, facilitating learning, or maybe finding new people (read players) to learn with. We know, that&apos;s a lot of learning!</p>
                                            <p>Learning Rummy is so much fun: But why would you believe us? With loads of tournaments, variants, daily Mos, and so much more, online Rummy confronls you with so many challenges, including making yourself a pro at the game. </p>
                                            <p className="text-center"><strong>But remember the philosophy-Slow is smooth, and smooth is fast.</strong></p>
                                            <p>Online Rummy is a game of itO own and demands nothing less than focus, practice, and skill in equal parts And it goes without saying &something is bringing so much value, it&apos;s supposed to feed your curiosity too -Rummy, for instance, lets you play and learn at the same time. If you haven&apos;t found your perfect reason to at learning Rummy yet, let us help you find yours!</p>
                                            <ol className="numeric-list">
                                                <li>
                                                    <h5>Analytical skills? Check!</h5>
                                                    <p>Oh, so you didn&apos;t know about it yet. Well, from visual judgments, decision-making abilities, math concepts and problem solving, Rummy is a perfect balance of skill development and entertainment. Is there anything else you&apos;d expect from a mere game? Surprise us!</p>
                                                </li>
                                                <li>
                                                    <h5>Online Rummy is your one-stop destination. How?</h5>
                                                    <p>Oh, so you didn&apos;t know about it yet. Well, from visual judgments, decision-making abilities, math concepts and problem solving, Rummy is a perfect balance of skill development and entertainment. Is there anything else you&apos;d expect from a mere game? Surprise us!</p>
                                                </li>
                                                <li>
                                                    <h5>Rummy could be your guide to personal development! </h5>
                                                    <p>Oh, so you didn&apos;t know about it yet. Well, from visual judgments, decision-making abilities, math concepts and problem solving, Rummy is a perfect balance of skill development and entertainment. Is there anything else you&apos;d expect from a mere game? Surprise us!</p>
                                                </li>
                                                <li>
                                                    <h5>Say NO to stagnancy by saying YES to Runny! </h5>
                                                    <p>While other games might help you engage in one form of it, the nature of Rummy is such that it has variations and also thanks to the regular tournaments that keep players entertained. With every no game comes a new opponent, and therefore, new strategies. If you consider every new game as an opportunity to learn a new tactic, you&apos;d soon become an expert, wait for it! </p>
                                                </li>
                                                <li>
                                                    <h5>Focused and a calm mind-</h5>
                                                    <p>Mark our words; being anxious, nervous, and restless will never help in the game of online Rummy. You&apos;ll eventually pick wrong cards, make mistakes, a nd lose with high scores. The journey to winning a Rummy game is to stay calm and make every move stra teg !catty. Try to notice moves and use them as an opportunity to win. Rummy needs focus and before stepping in to learn the game, make sure your mind is relaxed.</p>
                                                    <p>And yes, there&apos;ll always be newer technologies to embrace and new skills to learn, but the knowledge you once gain while learning the game of Rum, would never go to waste.</p>
                                                </li>
                                            </ol>
                                            <p><strong>But wait, before you leave to start learning online Rummy, here are a few features you&apos;ll get when you download the online Rummy app -</strong></p>
                                            <ul>
                                                <li>Welcome Bonuses</li>
                                                <li>Real-time Updates</li>
                                                <li>Push Notifications</li>
                                                <li>Daily and Monthly Limits</li>
                                                <li>Interactive User Interface</li>
                                                <li>Regular Tournaments</li>
                                                <li>Instant Play and Quick to Download</li>
                                                <li>Certified by The Online Rummy Federation (TORF) </li>
                                            </ul>
                                            <p><strong>With the dedicated versions for both Android and i0S, RummyCircle&apos;s mobile application works wonders for every player who wants to access the game from anywhere! Tempted to start playing the game right away? What are we here forlDownload the RummyCircle app now! </strong></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="more-blog pad-btm-25 mar-top-25">
                    <div className="container">
                        <div className="recent-blog blog-inner-content">
                            <h3>Share Now</h3>
                            <ul className="social-icon d-flex">
                                <li className="fb"><a href="https://www.facebook.com/profile.php?id=100076793341446" target="_blank" rel="noreferrer"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                                <li className="tw"><a href="https://twitter.com/" target="_blank" rel="noreferrer"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                <li className="insta"><a href="https://www.instagram.com//" target="_blank" rel="noreferrer"><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
                            </ul>
                            <h3>More Blogs</h3>
                            <div className="row mar-top-25">
                                <div className="col-md-4">
                                    <Link to="/blog-inner">
                                        <div className="blog-hero recent-post" style={{ backgroundImage: `url(${gameplay_image})` }}></div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--***** //Middle Section *****--> */}
            {/* <!--***** Footer *****--> */}
            <Footer />
        </div >
    )
}

export default BlogInner