import React, { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile } from '../../../actions/account';
import { withdraw } from '../../../actions/withdraw';
import Loading from '../../../components/Loading';
import { verifyLength } from '../../../helper';

function Withdraw() {
    const [withdrawBalance, setWithdrawBalance] = useState(0);
    const [amount, setAmount] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const dispatch = useDispatch();
    const token = useSelector(state => state.auth.token);
    const profileData = useSelector(state => state.account.dataProfile);
    const withdrawMessage = useSelector(state => state.withdraw.resMessage);
    const withdrawStatus = useSelector(state => state.withdraw.resStatus);

    const previousProps = useRef({ withdrawMessage, withdrawStatus }).current;

    useEffect(() => {
        document.title = "RUMMY RANI | Withdraw";
        dispatch(getProfile(token));
    }, [dispatch, token]);

    useEffect(() => {
        if (previousProps.withdrawMessage !== withdrawMessage && withdrawMessage?.length) {
            toast(withdrawMessage);
        }
        previousProps.withdrawMessage = withdrawMessage;
    }, [withdrawMessage, previousProps]);

    useEffect(() => {
        if (previousProps.withdrawStatus !== withdrawStatus) {
            setLoading(false);
            if (withdrawStatus === true) {
                setAmount('');
                dispatch(getProfile(token));
            }
        }
        previousProps.withdrawStatus = withdrawStatus;
    }, [withdrawStatus, dispatch, token, previousProps]);

    useEffect(() => {
        if (profileData) {
            setWithdrawBalance(profileData?.nWithdrawable || 0);
        }
    }, [profileData]);

    const handleAmount = (e) => {
        const value = e.target.value.replace(/[^\d]/, '');
        if (parseInt(value) > withdrawBalance) {
            setError('Amount exceeds withdrawable balance');
        } else if (parseInt(value) < 1) {
            setError('Minimum withdrawal amount is ₹1');
        } else {
            setError('');
        }
        setAmount(value);
    };

    const handleWithdraw = () => {
        if (!amount) {
            setError('Please enter an amount');
            return;
        }
        if (parseInt(amount) > withdrawBalance) {
            setError('Amount exceeds withdrawable balance');
            return;
        }
        if (parseInt(amount) < 1) {
            setError('Minimum withdrawal amount is ₹1');
            return;
        }
        setLoading(true);
        dispatch(withdraw(amount, token));
    };

    return (
        <>
            {loading && <Loading />}
            <div className="account-cnt">
                <div className="withdraw-page">
                    <h3 className="text-center account-title">Withdrawable Cash</h3>
                    <div className="withdraw-blue-box text-center">
                        <p>Withdrawable Balance : <span className="yellow-txt"> ₹{withdrawBalance.toFixed(2)} </span></p>
                        <div className="deposit-cash-form-box d-flex w-100">
                            <div className="form-group text-right w-100">
                                <input 
                                    type="text" 
                                    placeholder="Enter Amount" 
                                    onChange={handleAmount} 
                                    value={amount} 
                                    className="form-control w-100" 
                                />
                                {error && <p className="error-text">{error}</p>}
                            </div>
                        </div>
                        <button 
                            type="button" 
                            disabled={!amount || !!error} 
                            style={{"padding":"14px"}} 
                            className="common-btn yellow-btn small-btn" 
                            onClick={handleWithdraw}
                        >
                            <span className="main-layer">Withdraw</span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Withdraw;