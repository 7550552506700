import React from 'react'
// import Home
import Footer from '../auth/Footer'
import Header from '../auth/Header';


const AboutUs = () => {
    return (
        <div>
            <Header />
            <section className="site-content about-page-section cmn-banner-bg cmn-pad">
                <div className='text-center'>
                    <h3 className="text-center page-title">About Us</h3>
                </div>
                <div className="container">
                    <div className="about-us-content">
                        <div className='about-details-sec'>
                            <p>RummyRani.co.in is a one-stop destination for all rummy lovers. The platform is packed with cash prizes, tournaments, and amazing entertainment.</p>
                            <p>We are a young team that believes in the passion for gaming and how it shapes us. Considering the growth of the card game industry in the last decade, we launched our very own rummy platform, RummyRani.co.in.</p>
                            <p>
                                Our platform comprises all the features needed to provide the ultimate gaming experience. Every rummy game on RummyRani.co.in is packed with excitement and surprises. The gaming modes of each rummy variant welcome every player and encourage them to build and improve their skills. So, whether you choose points in the 13-card rummy variant, we have an interesting gaming session waiting for you.</p>
                            <p>We understand the concerns and expectations of our customers when they register on our platform and, therefore, have SSL protocols that ensure fair gameplay and equal opportunities for everyone. Features like 24/7 assistance, KYC processes for players, and complete verification make RummyRani.co.in one of the safest and best platforms to play rummy.</p>
                            <p>
                                Our players can choose to play for free or for cash prizes. So, don’t wait—register at RummyRani.co.in to gain rewards and a thrilling experience.</p>
                        </div>

                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default AboutUs