import React, { useEffect, useRef, useState } from 'react'
import { Input, Modal, ModalBody, Col, Row, Button, Form, FormGroup, Label, ModalHeader } from "reactstrap";
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux'
import { getTicketData, removeTicket, reportedIssue } from '../../../../actions/report'
import { toast } from 'react-toastify';
import Loading from '../../../../components/Loading'
import Paginate from '../../../../components/Paginate';
import Select from 'react-select';


function ReportedProblem() {

    // const [problemModal, setProblemModal] = useState(false)
    // const problemModalTable = () => setProblemModal(!problemModal);

    const [issuesList, setIssuesList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [totalData, setTotalData] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [firstPage, setFirstPage] = useState(false);
    const [modal, setModal] = useState(false);
    const [title, setTitle] = useState('');
    const [id, setId] = useState('');
    const [description, setDescription] = useState('');
    const [state, setState] = useState('');
    const [date, setDate] = useState('');

    const dispatch = useDispatch()
    const token = useSelector(state => state.auth.token)
    const issuesData = useSelector(state => state.report.issuesData)
    const ticketData = useSelector(state => state.report.ticketData)
    const removeTicketStatus = useSelector(state => state.report.removeTicketResStatus)
    const removeTicketMessage = useSelector(state => state.report.removeTicketResMessage)

    const previousProps = useRef({ removeTicketStatus, removeTicketMessage }).current


    useEffect(() => {
        setLoading(true)
        dispatch(reportedIssue(pageSize, currentPage, token))
    }, [currentPage, pageSize])


    useEffect(() => {
        if (issuesData) {
            setLoading(false)
            setPageCount(Math.ceil((issuesData[0]?.count?.totalData) / pageSize));
            setTotalData(issuesData[0]?.count?.totalData / 2)
            setIssuesList(issuesData && issuesData[0]?.tickets)
        }
    }, [issuesData])

    useEffect(() => {
        if (ticketData) {
            setLoading(false)
            setTitle(ticketData?.eCategory)
            setId(ticketData?._id)
            setDescription(ticketData?.sDescription)
            setState(ticketData?.eState)
            const Formatteddate = dateFormat(ticketData?.dCreatedDate)
            setDate(Formatteddate)
        }
    }, [ticketData])

    useEffect(() => {
        if (previousProps.removeTicketStatus !== removeTicketStatus) {
            if (removeTicketStatus === true) {
                setLoading(false)
                setModal(false)
                dispatch(reportedIssue(pageSize, currentPage, token))
            } else if (removeTicketStatus === false) {
                setLoading(false)
                setModal(false)
            }
        }
        return () => {
            previousProps.removeTicketStatus = removeTicketStatus
        }
    }, [removeTicketStatus])

    useEffect(() => {
        if (previousProps.removeTicketMessage !== removeTicketMessage) {
            if (removeTicketMessage) {
                toast(
                    <div>
                        <div>{removeTicketMessage}</div>
                    </div>
                );
            }
        }
        return () => {
            previousProps.removeTicketMessage = removeTicketMessage
        }
    }, [removeTicketMessage])

    function dateFormat(date) {
        return moment(date).format("MMM DD, yyyy, hh:mm A")
    }

    const handlePageClick = async (data) => {
        setCurrentPage(data.selected)
    }

    const toggleModal = () => setModal(!modal);

    function handlePageSize(e) {
        if (Number(e.value) > 10) {
            setCurrentPage(0)
            setFirstPage(true)
        }
        setPageSize(Number(e.value))
        setPageCount(Math.ceil(totalData / e.value))
    }

    function handleViewIssue(id) {
        setLoading(true)
        dispatch(getTicketData(id, token))
        setModal(!modal)
    }

    function handleRemoveTicket() {
        setLoading(true)
        dispatch(removeTicket(id, token))
    }

    const options = [{ value: '10', label: 'Show 10' }, { value: '25', label: 'Show 25' }, { value: '40', label: 'Show 40' }]

    return (
        <>
            {loading && <Loading />}
            <div className="account-cnt">
                <h3 className="text-center account-title">Reported problem</h3>
                <div className="game-transaction-section passbook-wrap reported-page-box">
                    {issuesList?.length ?
                        <>
                            <div className="table-cnt table-cnt-box br-6">
                                <div className="table-responsive">
                                    <table className="common-table table">
                                        <thead>
                                            <tr>
                                                <th className="text-center" width="25%">Ticket ID</th>
                                                <th className="text-center" width="25%">Status</th>
                                                <th className="text-center" width="25%">Date & Time</th>
                                                <th className="text-center" width="25%">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {issuesList.map((issue, i) => (
                                                <tr key={i} className="text-center">
                                                    <td className="text-center">
                                                        {issue._id}
                                                    </td>

                                                    <td className="text-center">
                                                        {issue.eState}
                                                    </td>
                                                    <td className="text-center">
                                                        {issue.dCreatedDate ? dateFormat(issue.dCreatedDate) : "Not Available"}
                                                    </td>
                                                    <td className="text-center">
                                                        <button type="button" className="backgroundbtn"  style={{"padding": "16px 46px"}}  onClick={() => handleViewIssue(issue._id)}>View</button>
                                                    </td>
                                                </tr>
                                            ))
                                            }

                                            {/* <tr>
                                        <td>
                                            10235486
                                        </td>
                                        <td className="text-center">
                                            InReview
                                        </td>
                                        <td className="text-center">
                                            Mar 09 2022 4:50PM
                                        </td>
                                        <td className="text-center">
                                            <button type="submit" className="browse-btn btn-boxshadow yellow-btn" >View</button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            10235487
                                        </td>
                                        <td className="text-center">
                                            InReview
                                        </td>
                                        <td className="text-center">
                                            Mar 10 2022 4:50PM
                                        </td>
                                        <td className="text-center">
                                            <button type="submit" className="browse-btn btn-boxshadow yellow-btn" >View</button>
                                        </td>
                                    </tr> */}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="transaction-btn-wrap d-flex align-items-center">
                                <div className="cust-dropdown-wrap">
                                    <div className="cust-dropdown-box text-center">
                                        <Select
                                            value={options.filter(function (option) {
                                                return option.value == pageSize;
                                            })}
                                            onChange={e => handlePageSize(e)}
                                            options={options}
                                            className="dropdown-blue-box"
                                            classNamePrefix={'inner-dropdown'}
                                            menuPlacement={'auto'}
                                        />
                                    </div>
                                </div>
                                <div className="pagination-wrap text-center">
                                    <div>
                                        <Paginate pageCount={pageCount} handlePageClick={handlePageClick} firstPage={firstPage} />
                                    </div>
                                </div>
                            </div>
                        </>
                        : <div className="table-cnt table-cnt-box br-6">
                            <div className="table-responsive">
                                <table className="common-table table">
                                    <thead>
                                        <tr>
                                            <th className="text-center">Ticket ID</th>
                                            <th className="text-center">State</th>
                                            <th className="text-center">Date & Time</th>
                                            <th className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan="6" className="text-center">
                                                Not Available
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                    {/* <div className="transaction-btn-wrap d-flex align-items-center">
                        <div className="pagination-wrap text-center">
                        </div>
                    </div> */}
                </div>
            </div>

            <Modal isOpen={modal} toggle={toggleModal} className='modal-dialog-centered complain-wrap'>

                {/* <div className='modal-header' toggle={toggleModal}>
                    <h3 className="modal-title text-center" id="exampleModalLongTitle">Winning Not Credited</h3> */}
                {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true"><img src="../images/Close.png" alt="close" onClick={() => setProblemModal(!problemModal)} /></span>
                    </button> */}
                {/* </div> */}
                <div className="modal-cnt" >
                    <ModalHeader toggle={toggleModal}>
                        <h3 
                            className="modal-title text-center"
                            id="exampleModalLongTitle3"
                        >
                            {title}
                        </h3>
                    </ModalHeader>
                    <ModalBody >
                        <div className="panel with-nav-tabs panel-info">
                            <div className="panel-body">
                                <div className="tab-content">
                                    <>
                                        {/* join table content */}
                                        <Form className='complain-form'>
                                            <FormGroup row className='align-items-baseline'>
                                                <Label for="exampleEmail" sm={2}>Ticket ID :</Label>
                                                <Col sm={10}>
                                                    <Input type="text" name="email" id="exampleEmail" disabled value={id} />
                                                    {/* <Input type="email" name="email" id="exampleEmail" placeholder="" /> */}
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row className='align-items-baseline'>
                                                <Label for="exampleText" sm={2}>Description :</Label>
                                                <Col sm={10}>
                                                    {/* <Input type="textarea" name="text" id="exampleText" rows="8" /> */}
                                                    <Input type="textarea" name="text" id="exampleText" disabled value={description} rows="7" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row className='align-items-baseline'>
                                                <Label for="exampleText" sm={2}>State :</Label>
                                                <Col sm={10}>
                                                    <Row className='align-items-baseline'>
                                                        <Col sm={4}>
                                                            {/* <Input type="text" name="text" id="exampleText" /> */}
                                                            <Input type="text" name="text" id="exampleText" disabled value={state} />
                                                        </Col>
                                                        <Label for="exampleText" sm={3}>Date & Time :</Label>
                                                        <Col sm={5}>
                                                            {/* <Input type="text" name="text" id="exampleText" /> */}
                                                            <Input type="text" name="text" id="exampleText" disabled value={date} />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </FormGroup>
                                            {ticketData?.eState === 'inReview' &&
                                                <FormGroup check row className='text-center'>
                                                    <Col>
                                                        <Button className="red-btn" onClick={handleRemoveTicket}>Cancel Request</Button>
                                                    </Col>
                                                </FormGroup>
                                            }

                                            {/* <FormGroup check row className='text-center'>
                                                <Col>
                                                    <Button className="red-btn">Cancel Request</Button>
                                                </Col>
                                            </FormGroup> */}
                                        </Form>
                                    </>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </div>
            </Modal>

        </>
    )
}

export default ReportedProblem