const errMsg = 'Server is unavailable.'
// export function getUrl () {
//   const url = 'https://yudiz-fantasy-media.s3.ap-south-1.amazonaws.com'
//   return url
// }

export function catchError(type, error) {
  return {
    type,
    payload: {
      resStatus: false,
      resMessage: error && error.response ? error.response.data.message : errMsg
    }
  }
}

export function catchBlankData(type) {
  return {
    type,
    payload: {
      resStatus: false,
      data: []
    }
  }
}

export function catchBlankDataObj(type) {
  return {
    type,
    payload: {
      resStatus: false,
      data: {}
    }
  }
}

export function verifyEmail(value) {
  const emailRex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
  if (emailRex.test(value) && value.length <= 320) {
    return true
  }
  return false
}

export function verifyPassword(value) {
  // const passwordRex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,16}$/
  // if (passwordRex.test(value)) {
  //   return true
  // }
  // return false
  if (value.length >= 8 && value.length <= 16) {
    return true;
  }
  
  return false;
}

export function verifyUsername(value, start, end) {
  if (value.length >= start && value.length <= end) {
    return true
  }
  return false
}

export function withoutSpace(value) {
  const SpacelessRex = /\s/g
  if (SpacelessRex.test(value)) {
    return true
  }
  return false
}

export function verifySpecialCharacter(value) {
  const regex = /^[0-9a-zA-Z]+$/
  if (regex.test(value)) {
    return true
  }
  return false
}

export function verifySpecCharWithSpace(value) {
  const regex = /^[A-Za-z0-9 ]+$/
  if (regex.test(value)) {
    return true
  }
  return false
}

export function verifyLength(value, length) {
  if (value.length >= length) {
    return true
  }
  return false
}

export function verifyMobileNumber(value) {
  const mobRex = /^[6-9]\d{9}$/
  if (mobRex.test(value)) {
    return true
  }
  return false
}


export function maxValue(value1, value2) {
  if (value1 > value2) {
    return value1
  } else {
    return value2
  }
}

export function isNumber(value) {
  const numRex = /^[0-9\b]+$/
  if (numRex.test(value)) {
    return true
  }
  return false
}

export function isPositive(value) {
  if (value > 0) {
    return true
  }
  return false
}

export function isUpperCase(value) {
  if (value === value.toUpperCase()) {
    return true
  }
  return false
}

export function panCardNumber(value) {
  const panRex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/
  if (!panRex.test(value)) {
    return true
  }
  return false
}
export function ifscCode(value) {
  const ifscRex = /^[A-Z]{4}0[A-Z0-9]{6}$/
  if (!ifscRex.test(value)) {
    return true
  }
  return false
}
