/* eslint-disable react/prop-types */
import { React, useEffect, useState } from "react";
import CopyIcon from "../../../assets/images/icons/copy-icon.png";
import InviteAllIcons from "../../../assets/images/icons/invite-all-icons.png";
import InfoInviteIcon from "../../../assets/images/icons/info-icon-invite.png";
import { connect, useDispatch, useSelector } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify';
import { FacebookShareButton, FacebookIcon } from "react-share";

import { getReferralCode } from "../../../actions/invite";
import Loading from '../../../components/Loading'
import inviteFrnd from '../../../assets/images/invite-img.png';
import copy from '../../../assets/images/copy-icon.svg';
import mail from '../../../assets/images/mail.svg';
import facebook from '../../../assets/images/facebook.svg';
import social from '../../../assets/images/social-3.svg';
import { getSettings } from "../../../actions/account";
import { Link } from "react-router-dom";


function Invite() {

    const [referralCode, setReferralCode] = useState('');
    const [referralLink, setReferralLink] = useState('');
    const [loading, setLoading] = useState(false);
    const [referralBonus, setReferralBonus] = useState(0);
    const [link, setLink] = useState('');


    const dispatch = useDispatch()
    const token = useSelector(state => state.auth.token)
    const referral = useSelector(state => state.invite.data)
    const gameSettings = useSelector(state => state.account.data)

    useEffect(() => {
        setLoading(true)
        dispatch(getReferralCode(token))
        dispatch(getSettings)
        document.title = "RUMMY RANI | My Account"
    }, [])


    useEffect(() => {
        if (referral) {
            const match = referral?.sReferralLink.match(/gifted you (\d+)/);
            const giftedNumber = match ? parseInt(match[1]) : null;
            setReferralBonus(giftedNumber)
        }
    }, [referral])

    useEffect(() => {
        if (referral) {
            setLoading(false)
            setReferralCode(referral.sReferralCode)
        }
    }, [referral])


    useEffect(() => {
        if (referral) {
            setLoading(false)
            setReferralCode(referral.sReferralCode)
            const link = referral?.sReferralLink?.match(/\bhttps?:\/\/\S+/gi);
            setReferralLink(link?.length && link[0])
            setLink(link?.length && link[0])
        }
    }, [referral])

    const copyText = async (referralType) => {
        if (referralType) {
            if (navigator.clipboard && window.isSecureContext) {
                await navigator.clipboard.writeText(referralType);
            }
            else {
                let textArea = document.createElement("textarea");
                textArea.value = referralType;
                textArea.style.position = "absolute";
                textArea.style.opacity = 0;
                document.body.appendChild(textArea);
                textArea.select();
                await new Promise((res, rej) => {
                    document.execCommand('copy') ? res() : rej();
                    textArea.remove();
                });
            }
        }
        toast(
            <div>
                <div>Copied to clipboard!</div>
            </div>
        );
    }


    const copyToClipboard = async (type) => {
        switch (type) {
            case "CODE":
                copyText(referralCode);
                break
            case "LINK":
                copyText(referralLink);
                break
        }
    }


    function Mailto({ email, subject = '', body = '', children }) {
        let params = subject || body ? '?' : '';
        if (subject) params += `subject=${encodeURIComponent(subject)}`;
        if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

        return <a href={`mailto:${email}${params}`}>{children}</a>;
    }

    return (
        <>
            <div className="account-cnt">
                <div className="invite-wrap">
                    <h3 className="text-center account-title">Invite Friends</h3>
                    <div className="invite-card">
                        <div className="row">
                            <div className="col-md-9 col-lg-9 col-xxl-6 col-sm-9 mx-auto">
                                <div className="kyc-body table-cnt-box invite-box-shadow br-6">
                                    <div className="invite-box text-center">
                                        <div className="d-flex align-items-center w-100 justify-content-center">
                                            <h3>Invite Code : <span className="coupen-code-txt"> {referralCode} </span></h3>
                                            <a type="button" onClick={() => copyToClipboard("CODE")}><img src={CopyIcon} alt="copy-icon" /></a>
                                        </div>
                                        <div className="invite-img-box">
                                            <img src={InviteAllIcons} alt="invite-images" />
                                        </div>
                                        <h3>Earn upto &#8377;{referralBonus} for each friend who join</h3>
                                        <Link to="/tearms-of-service-prv"> View our Terms & Conditions </Link>
                                        {/* <div>
                                        <button type="button" className="yellow-btn btn-boxshadow"><span className="main-layer">Invite</span></button>
                                        </div> */}
                                        <div className="google-btn">
                                            {/* <img src={facebook} alt="facebook" /> */}
                                            <FacebookShareButton url={link}>
                                                <FacebookIcon round />
                                            </FacebookShareButton>
                                        </div>
                                    </div>
                                    <Link to="/bonus" className="invite-info-box"> <img src={InfoInviteIcon} alt="info icon" /> </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Invite