import React from 'react'
import { Link } from 'react-router-dom';

// import Home
import Footer from '../auth/Footer'
import Header from '../auth/Header';
import blog_img from '../../assets/images/blog/blog-img1.png'

const Disclaimer = () => {
    return (
        <div>
            {/* <!--***** Start Body *****-->
            <!--***** Header *****--> */}
            {/* <Header /> */}
            <Header />
            {/* <!--***** //Header *****--> */}
            {/* <!-- Tab Panel Section --> */}
            <section className="site-content cmn-banner-bg cmn-pad about-page-section">
                <div className='text-center'>
                    <h3 className="text-center page-title">Disclaimer</h3>
                </div>
                <div className="container">
                    <div className="about-us-content desclaimer">
                        <div className='content-sec'>
                            <ul>
                                <li>The Services on the Websites and the Content present on it are provided strictly on an "as is" basis with all faults or failings. Any representations, warranties, conditions, or guarantees whatsoever, express or implied (including, without limitation, any implied warranty of accuracy, completeness, uninterrupted provision, quality, merchantability, fitness for a particular purpose, or non-infringement) are specifically excluded to the fullest extent permitted by law. PEPL does not ensure or guarantee continuous, error-free, secure, or virus-free operation of the Portal or its Content, including software, Games, your user account, the transactions in your user account, or the continued operation or availability of any facility on the website.</li>
                                <li>Additionally, PEPL does not promise or ensure that you will be able to access your user account or obtain Services whenever you want. It is entirely possible that you may not be able to access your user account or the Services provided by PEPL at times or for extended periods due to, but not limited to, system maintenance and updates.</li>
                                <li>PEPL disclaims responsibility and liability for any harm resulting from the cancellation of any Game organized by it. If you are a cash player on the Portal, you acknowledge and agree that you will not be entitled to any refund in the case of any service outages caused by failures of our service providers, computer viruses or contaminants, natural disasters, war, civil disturbance, or any other cause beyond the reasonable control of PEPL.</li>
                                <li>PEPL specifically disclaims any liability in connection with Games or events made available or organized on the Portal which may require specific statutory permissions, in the event such permissions are denied or cancelled whether prior to or during such Game or event.</li>
                                <li>PEPL specifically disclaims any liability in connection with your transactions with third parties which may have advertisements or are hyperlinked on the Website.</li>

                                <li>PEPL disclaims any liability in connection with the violation of intellectual property rights of any party with respect to third-party Content or user content posted on our Website. Intellectual property rights in any Content not belonging to us belong to the respective owners, and any claims related to such content must be directly addressed to the respective owners.
                                </li>
                                <li>PEPL specifically disclaims any liability arising out of the acts or omissions of the infrastructure providers or the failure of internet services used for providing and accessing the Services.</li>
                                <li>PEPL disclaims liability for any risk or loss resulting to you from your participation in Cash Games, including all risk of financial loss.</li>
                                <li>Random Number Generator Software: PEPL uses Random Number Generator ("RNG") software on the website <a href=" https://www.RummyRani.co.in/">www.RummyRani.co.in</a> as well as the app. PEPL complies with the relevant statistical standards of randomness. You understand that PEPL maintains the standards it considers appropriate in the best of its belief; however, it is not possible to test all possible scenarios in any testing environment, and PEPL specifically disclaims any and all liability in connection with the RNG used by PEPL‘s software.</li>
                            </ul>
                            <h3>Indemnity</h3>
                            <ul className="mar-top-25">
                                <li>To the extent permitted by law, and in consideration for being allowed to participate in the Activity, you hereby agree to indemnify, save, hold harmless, and defend us (to the extent of all benefits and awards, cost of litigation, disbursements, and reasonable attorney’s fees that we may incur in connection therewith, including any direct, indirect, or consequential losses, any loss of profit, and loss of reputation) from any claims, actions, suits, taxes, damages, injuries, causes of action, penalties, interest, demands, expenses, and/or awards asserted or brought against us by any person in connection with:
                                </li>
                                <li>Infringement of their intellectual property rights by your publication of any content on our Website.</li>
                                <li>Defamatory, offensive, or illegal conduct of any other player or for anything that turns out to be misleading, inaccurate, defamatory, threatening, obscene, or otherwise illegal whether originating from another player or otherwise;</li>
                                <li>Use, abuse, or misuse of your user account on our Website in any manner whatsoever;</li>
                                <li>Any disconnections, technical failures, system breakdowns, defects, delays, interruptions,
                                    manipulated or improper data transmission, loss or corruption of data, communication lines failure, distributed denial of service attacks, viruses, or any other adverse technological occurrences arising in connection with your access to or use of our Website; and</li>
                                <li>Access of your user account by any other person accessing the Services using your username or password, whether or not with your authorization.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section >
            {/* <!--***** //Middle Section *****--> */}
            {/* <!--***** Footer *****--> */}
            <Footer />
        </div >
    )
}

export default Disclaimer