import React, { useState, useRef, useEffect, Fragment } from 'react';
import { Collapse, FormGroup, Label, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, Button, Modal, ModalBody, NavLink, Row, Col, Container, Input } from 'reactstrap';
import logo from "../../assets/images/logo.png";
import GooglePlay from '../../assets/images/icons/google-play.png';
import AppleStore from '../../assets/images/icons/apps-store.png';
import { Link, useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { history } from '../../App'

function Header() {
    const [isOpen, setIsOpen] = useState(false);
    const [isTogglerActive, setIsTogglerActive] = useState(false);
    const history = useHistory();

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
        setIsTogglerActive(!isTogglerActive);
    };

    const handleNavLinkClick = () => {
        if (window.innerWidth <= 768 && isOpen) { // assuming toggler is visible on screens <= 768px width
            toggleNavbar();
        }
    };

    return (
        <div>
            <header>
                <div>
                    <Navbar expand="md" className='before-login'>
                        <NavbarBrand href="/">
                            <img src={logo} alt="logo" />
                        </NavbarBrand>
                        <NavbarToggler onClick={toggleNavbar} className={classnames({ 'active': isTogglerActive })}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </NavbarToggler>
                        <Collapse navbar isOpen={isOpen}>
                            <Nav className="mx-auto" navbar>
                                <NavItem>
                                    <Link className={classnames("nav-link", { "active": history.location && (history.location.pathname === '/game') })} to="/game" onClick={handleNavLinkClick}>Home</Link>
                                </NavItem>
                                <NavItem>
                                    <Link className={classnames("nav-link", { "active": history.location && (history.location.pathname === '/about-us' || history.location.pathname === '/about-us-prv') })} to="/about-us" onClick={handleNavLinkClick}>About us</Link>
                                </NavItem>
                                <NavItem>
                                    <Link className={classnames("nav-link", { "active": history.location && (history.location.pathname === '/faq') })} to="/faq" onClick={handleNavLinkClick}>FAQs</Link>
                                </NavItem>
                                <NavItem>
                                    <Link className={classnames("nav-link", { "active": history.location && (history.location.pathname === '/contact-support') })} to="/contact-support" onClick={handleNavLinkClick}>contact</Link>
                                </NavItem>
                            </Nav>
                            <Nav className="ml-auto align-items-center after-login download-btn" navbar>
                                <NavItem>
                                    <NavLink href="https://apk-s3-rummy-rani.s3.ap-south-1.amazonaws.com/rummyrani.apk" target="_blank" rel="noreferrer">
                                        DOWNLOAD
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </Navbar>
                </div>
            </header>
        </div>
    );
}

export default Header;
