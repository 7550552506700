import React, { useState, useEffect, useRef } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import classnames from "classnames";
// import { ChipsIcon, EditIcon, EditBlueIcon, UploadIcon, } from "../../../assets/images/index";
import Select from 'react-select';
import moment from 'moment'
// import EditIcon from '../../../assets/images/edit-profile-icon.svg'
import { toast } from 'react-toastify';
import { useDispatch, useSelector, connect } from 'react-redux'
import { Prompt, useLocation } from "react-router-dom";
import { useQueryState } from 'react-router-use-location-state'
import qs from 'query-string'
import { addBank, getSettings, updateProfile, updateEmail, updateMobileNumber, getProfile, uploadKYC, verifyEmailRequest, getClubInfo, refreshPracticeChips, updateAgentId } from '../../../actions/account';
// import { getBonus } from '../../../actions/bonus';
import { verifyLength, verifyEmail, verifyMobileNumber, verifyPincode, verifyIFSCcode, verifyPassword, verifyPAN, verifyAccountNumber } from '../../../helper';
import DatePicker from 'react-date-picker';
import Loading from '../../../components/Loading'
import { optionsState } from '../../../assets/data/state'
import EditIcon from '../../../assets/images/edit-profile-icon.svg';
import UploadIcon from '../../../assets/images/upload-icon.svg';
import EditBlueIcon from '../../../assets/images/edit-profile-icon-white.svg';
import verified from '../../../assets/images/icons/verified.png';
import cross from '../../../assets/images/cross-white.png';
import BlackCross from '../../../assets/images/cross.png';
import pokerChip from '../../../assets/images/icons/chips.png';
import axios from '../../../axios'
// '../assets/images/icons/chips.png';


function MyAccount() {
  const [activeTab, setActiveTab] = useState("1");
  const [profile, setProfile] = useState({})
  const [username, setUsername] = useState('')
  const [fullName, setFullName] = useState();
  const [DOB, setDOB] = useState('')
  const [email, setEmail] = useState('')
  const [mobileNumber, setMobileNumber] = useState('')
  const [state, setState] = useState(null)
  const [pincode, setPincode] = useState('')
  const [editable, setEditable] = useState(false)
  const [withdrawableCash, setWithdrawableCash] = useState(0)
  const [currentAvatar, setCurrentAvatar] = useState(0)
  const [updatedAvatar, setUpdatedAvatar] = useState(0)
  const [value, setValue] = useState('');
  const [isEmailVerified, setIsEmailVerified] = useState(false)
  const [isMobileVerified, setIsMobileVerified] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [showArrows, setShowArrows] = useState(false)
  const [showSubmit, setShowSubmit] = useState(false)
  const [passwordVisibility, setPasswordVisibility] = useState(false)
  const [eyeIcon, setEyeIcon] = useState(false)
  const [password, setPassword] = useState('')
  const [PANNumber, setPANNumber] = useState('');
  const [KYCDocumentType, setKYCDocumentType] = useState('Aadhaar Card');
  const [editKYC, setEditKYC] = useState(true);
  const [bankFormInput, setBankFormInput] = useState(false);
  const [disableBankSubmit, setDisableBankSubmit] = useState(false);
  const [disableKYC, setDisableKYC] = useState(false);
  const [loading, setLoading] = useState(false);
  const [statusKYC, setStatusKYC] = useState('');
  const [rejectReason, setRejectReason] = useState('');
  const [disableInfoEdit, setDisableInfoEdit] = useState(false);
  const [emailUpdatePopup, setEmailUpdatePopup] = useState(false);
  const [bonus, setBonus] = useState(0);
  const [passwordVerifyEmail, setPasswordVerifyEmail] = useState('');
  const [addressStatus, setAddressStatus] = useState('');
  const [updateEmailDisable, setUpdateEmailDisable] = useState(false);
  const [oldEmail, setOldEmail] = useState('');
  const [activeProfileTab, setActiveProfiletab] = useQueryState('myProfile', '1')
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [landmark, setLandmark] = useState('');
  const [city, setCity] = useState('');
  const [deposit, setDeposit] = useState(0);
  const [withdraw, setWithdraw] = useState(0);
  const [tds, setTds] = useState(0);
  const [totalCash, setTotalCash] = useState(0);
  const [instantPending, setInstantPending] = useState(0);
  const [lockedPending, setLockedPending] = useState(0);
  const [instantReleased, setInstantReleased] = useState(0);
  const [lockedReleased, setLockedReleased] = useState(0);
  const [errAddress1, setErrAddress1] = useState('');
  const [errAddress2, setErrAddress2] = useState('');
  const [errLandmark, setErrLandmark] = useState('');
  const [errCity, setErrCity] = useState('');
  const [clubDetails, setClubDetails] = useState();
  const [practiceChips, setPracticeChips] = useState(0);
  const [code, setCode] = useState('');



  const [bankName, setBankName] = useState('')
  const [IFSCCode, setIFSCCode] = useState('')
  const [holderName, setHolderName] = useState('')
  const [accountNumber, setAccountNumber] = useState('')
  const [confirmAccountNumber, setConfirmAccountNumber] = useState('')
  const [requiredFieldsFilled, setRequiredFieldsFilled] = useState(false);

  const [PANImage, setPANImage] = useState(null);
  const [PANInputDisable, setPANInputDisable] = useState(false);
  const [KYCFrontImage, setKYCFrontImage] = useState(null);
  const [KYCFrontInputDisable, setKYCFrontInputDisable] = useState(false);
  const [KYCBackImage, setKYCBackImage] = useState(null);
  const [KYCBackInputDisable, setKYCBackInputDisable] = useState(false);

  const [errUsername, setErrUsername] = useState('')
  const [errFullName, setErrFullName] = useState('');
  const [errEmail, setErrEmail] = useState('')
  const [errMobileNumber, setErrMobileNumber] = useState('')
  const [errPincode, setErrPincode] = useState('')
  const [errBankName, setErrBankName] = useState('')
  const [errIFSCCode, setErrIFSCCode] = useState('')
  const [errHolderName, setErrHolderName] = useState('')
  const [errAccountNumber, setErrAccountNumber] = useState('')
  const [errConfirmAccountNumber, setErrConfirmAccountNumber] = useState('')
  const [errPassword, setErrPassword] = useState('')
  const [errPANNumber, setErrPANNumber] = useState('');
  const [errPANImg, setErrPANImg] = useState('');
  const [errKYCFrontImg, setErrKYCFrontImg] = useState('');
  const [errKYCBackImg, setErrKYCBackImg] = useState('');
  const [errorDOB, setErrorDOB] = useState('');


  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)
  const resMessage = useSelector(state => state.account.resMessage)
  const avatarData = useSelector(state => state.account.data)
  const profileData = useSelector(state => state.account.dataProfile)
  const bankStatus = useSelector(state => state.account.resStatusBank)
  const KYCStatus = useSelector(state => state.account.resStatusKYC)
  const bonusData = useSelector(state => state.bonus.data)
  const updateEmailStatus = useSelector(state => state.account.resStatusUpdateEmail)
  const verifyEmailStatus = useSelector(state => state.account.resStatusVerifyEmail)
  const clubInfo = useSelector(state => state.account.clubData)
  const refreshChipsStatus = useSelector(state => state.account.resStatusRefreshChips)
  const agentStatus = useSelector(state => state.account.resStatusAgent)
  const agentMsg = useSelector(state => state.account.resMessageAgent)
  console.log('profileData', profileData)
  const [jointAgentModal, setjointAgentModal] = useState(false)

  function joinAgentModalTable() {
    setjointAgentModal(!jointAgentModal);
    if (jointAgentModal == false) {
      setCode('')
    }
  }

  const previousProps = useRef({ resMessage, avatarData, profileData, bankStatus, KYCStatus, bonusData, updateEmailStatus, verifyEmailStatus, clubInfo, agentStatus, agentMsg }).current
  const location = useLocation();
  const inputRef = useRef(null);

  useEffect(() => {
    if (editable === false) {
      inputRef.current.disabled = true
    }
  }, [editable]);

  useEffect(() => {
    dispatch(getSettings)
    // dispatch(getClubInfo(token))
    // dispatch(getBonus(10, 0, token))
    // setLoading(true)
    if (location?.state) {
      setActiveTab(location.state)
    }
    document.title = "RUMMY RANI | My Account"

    const obj = qs.parse(location.search)
    obj?.myProfile && setActiveTab(obj.myProfile)
  }, [])


  useEffect(() => {
    if (previousProps.resMessage !== resMessage) {
      if (resMessage?.length && resMessage !== 'Invalid credentials') {
        dispatch(getSettings)
        dispatch(getProfile(token))
        setShowEdit(false)
        setEditable(false)
        setShowSubmit(false)
        setLoading(false)
        toast(
          <div>
            <div>{resMessage}</div>
          </div>
          , { autoClose: 5000 });
      }
      // if (resMessage?.length && resMessage == 'Invalid credentials') {
      //   dispatch(getSettings)
      //   dispatch(getProfile(token))
      //   toast(
      //     <div>
      //       <div>{resMessage}</div>
      //     </div>
      //     , { autoClose: 5000 });
      // }
    }
    return () => {
      previousProps.resMessage = resMessage
    }
  }, [resMessage])

  useEffect(() => {
    if (previousProps.agentMsg !== agentMsg) {
      if (agentMsg?.length) {
        toast(
          <div>
            <div>
              {agentMsg == "success." ? "Agent joined successfully." : agentMsg}
            </div>
          </div>
        );
      }
    }
    return () => {
      previousProps.agentMsg = agentMsg
    }
  }, [agentMsg])


  useEffect(() => {
    if (previousProps.bankStatus !== bankStatus) {
      if (bankStatus === false) {
        setBankName('')
        setIFSCCode('')
        setHolderName('')
        setAccountNumber('')
        setConfirmAccountNumber('')
      }
    }
    return () => {
      previousProps.bankStatus = bankStatus
    }
  }, [bankStatus])



  useEffect(() => {
    if (previousProps.profileData !== profileData) {
      setProfile(profileData)
      setPracticeChips(profileData?.nPracticeChips || 0)
    }
    return () => {
      previousProps.profileData = profileData
    }
  }, [profileData])

  useEffect(() => {
    if (previousProps.clubInfo !== clubInfo) {
      setClubDetails(clubInfo)
    }
    return () => {
      previousProps.clubInfo = clubInfo
    }
  }, [clubInfo])

  useEffect(() => {
    if (profile) {
      setLoading(false)
      setUsername(profile?.sUserName || '')
      setFullName(profile?.sFullName || '')
      setDOB(profile?.dDob || '')
      setEmail(profile?.sEmail || '')
      setMobileNumber(profile?.sMobile?.substring(3) || '')
      setAddress1(profile?.oAddress?.sAddressLine1 || '')
      setAddress2(profile?.oAddress?.sAddressLine2 || '')
      setLandmark(profile?.oAddress?.sLandMark || '')
      setCity(profile?.oAddress?.sCity || '')
      setState(profile?.oAddress?.sState || '')
      setPincode(profile?.oAddress?.nPinCode || '')
      setWithdrawableCash(profile?.nWithdrawable?.toFixed(2) || 0)
      setCurrentAvatar(profile?.sAvatar || '')
      setUpdatedAvatar(profile?.sAvatar || '')
      setIsEmailVerified(profile?.isEmailVerified || false)
      setIsMobileVerified(profile?.isMobileVerified || false)
      setValue(profile?.dDob ? moment(profile?.dDob).toDate() : '')
      setPANNumber(profile?.oKYC?.sPanNumber || '')
      setPANImage(profile?.oKYC?.sPanCardLink || null)
      setKYCDocumentType(profile?.oKYC?.sDocumentName || 'AdhaarCard')
      setKYCFrontImage(profile?.oKYC?.aDocuments[0] || null)
      setKYCBackImage(profile?.oKYC?.aDocuments[1] || null)
      setBankName(profile?.oBanking?.sBankName || '')
      setIFSCCode(profile?.oBanking?.sIFSC || '')
      setHolderName(profile?.oBanking?.sAccountHolderName || '')
      setAccountNumber(profile?.oBanking?.sAccountNo || '')
      setConfirmAccountNumber(profile?.oBanking?.sAccountNo || '')
      setStatusKYC(profile?.oKYC?.eState || '')
      setDeposit(profile?.nChips && profile?.nChips?.toFixed(2) || 0)
      setDeposit(profile?.nChips && profile?.nChips?.toFixed(2) || 0)
      setWithdraw(profile?.nWithdrawable && profile?.nWithdrawable?.toFixed(2) || 0)
      setTds(profile?.nTds && profile?.nTds?.toFixed(2) || 0)
      // setTotalCash(profile?.nChips?.toFixed(2) || 0)
      setAddressStatus(profile?.oAddress?.eState)
      setOldEmail(profile?.sEmail)
      setInstantPending(profile?.oBonus?.nTotalIB)
      setLockedPending(profile?.oBonus?.nTotalLB)
      setInstantReleased(profile?.oBonus?.nUsedIB)
      setLockedReleased(profile?.oBonus?.nUsedLB)


      if (profile?.oKYC?.eState === 'rejected') {
        setRejectReason(profile?.oKYC?.sRejectReason)
      }
      if (profile?.oBanking?.sBankName && profile?.oBanking?.sAccountNo) {
        setBankFormInput(true)
      }
    }
  }, [profile])
  function formatSliderValue(nBalance) {
    if (nBalance < 0) {
      if (nBalance > -100000 && nBalance < -999) {
        return (nBalance / 1000).toFixed(2) / 1 + 'K'
      }
      else {
        return nBalance.toFixed(2) / 1
      }
    } else {
      if (nBalance > 999 && nBalance < 1000000) {
        return (nBalance / 1000).toFixed(2) / 1 + 'K'
      } else {
        // return nBalance.toFixed(2) / 1
      }
    }
  };


  useEffect(() => {
    if (previousProps.bonusData !== bonusData) {
      if (bonusData) {
        setBonus(bonusData?.bonusTotal[0]?.nTotal - bonusData?.bonusTotal[0]?.nUsed || 0)
      }
    }
    return () => {
      previousProps.bonusData = bonusData
    }
  }, [bonusData])

  useEffect(() => {
    if (previousProps.KYCStatus !== KYCStatus) {
      if (KYCStatus === true) {
        setLoading(false)
        setEditKYC(false)
      }
      else if (KYCStatus === false) {
        setLoading(false)
        setEditKYC(false)
      }
    }
    return () => {
      previousProps.KYCStatus = KYCStatus
    }
  }, [KYCStatus])

  useEffect(() => {
    if (previousProps.updateEmailStatus !== updateEmailStatus) {
      if (updateEmailStatus === true) {
        setLoading(false)
        setEmailUpdatePopup(false)
        setEyeIcon(false)
        setPasswordVerifyEmail('')
      }
      if (updateEmailStatus === false) {
        setLoading(false)
        setPasswordVerifyEmail('')
        setEyeIcon(false)
        setUpdateEmailDisable(false)
      }
    }
    return () => {
      previousProps.updateEmailStatus = updateEmailStatus
    }
  }, [updateEmailStatus])

  useEffect(() => {
    if (previousProps.verifyEmailStatus !== verifyEmailStatus) {
      if (verifyEmailStatus === true) {
        setLoading(false)
      }
      if (verifyEmailStatus === false) {
        setLoading(false)
      }
    }
    return () => {
      previousProps.verifyEmailStatus = verifyEmailStatus
    }
  }, [verifyEmailStatus])

  useEffect(() => {
    if (previousProps.refreshChipsStatus !== refreshChipsStatus) {
      if (refreshChipsStatus === true) {
        setLoading(false)
        dispatch(getProfile(token))
        dispatch(getSettings)
        dispatch(getClubInfo(token))
      } else if (refreshChipsStatus === false) {
        setLoading(false)
        dispatch(getProfile(token))
        dispatch(getSettings)
        dispatch(getClubInfo(token))
      }
    }
    return () => {
      previousProps.refreshChipsStatus = refreshChipsStatus
    }
  }, [refreshChipsStatus])


  useEffect(() => {
    if (previousProps.agentStatus !== agentStatus) {
      if (agentStatus === true) {
        setLoading(false)
        dispatch(getProfile(token))
        dispatch(getSettings)
        dispatch(getClubInfo(token))
        setjointAgentModal(false)
        setCode('')
      } else if (agentStatus === false) {
        dispatch(getProfile(token))
        dispatch(getSettings)
        dispatch(getClubInfo(token))
        setLoading(false)
        dispatch(getProfile(token))
        dispatch(getSettings)
        dispatch(getClubInfo(token))
      }
    }
    return () => {
      previousProps.agentStatus = agentStatus
    }
  }, [agentStatus])

  useEffect(() => {
    if (PANImage) {
      setPANInputDisable(true)
    }
  }, [PANImage])

  useEffect(() => {
    if (KYCFrontImage) {
      setKYCFrontInputDisable(true)
    }
  }, [KYCFrontImage])

  useEffect(() => {
    if (KYCBackImage) {
      setKYCBackInputDisable(true)
    }
  }, [KYCBackImage])

  // useEffect(() => {
  //   if (errPANNumber?.length) {
  //     setDisableKYC(false)
  //   } else {
  //     if (PANNumber?.length === 0) {
  //       setDisableKYC(false)
  //     } else {
  //       if (PANImage === null || KYCFrontImage === null || KYCBackImage === null) {
  //         setDisableKYC(false)
  //       }
  //       else {
  //         setDisableKYC(true)
  //       }
  //     }
  //   }
  // }, [errPANNumber])

  useEffect(() => {
    if (PANImage && KYCFrontImage && KYCBackImage && PANNumber) {
      console.log(PANImage, KYCFrontImage, KYCBackImage, PANNumber);
      setDisableKYC(false)
    } else {
      console.log(PANImage, KYCFrontImage, KYCBackImage, PANNumber);
      setDisableKYC(true)
    }
  }, [PANImage, KYCFrontImage, KYCBackImage, PANNumber])

  useEffect(() => {
    if (email?.length && passwordVerifyEmail?.length && !errPassword && !errEmail && email != oldEmail) {
      setUpdateEmailDisable(true)
    } else {
      setUpdateEmailDisable(false)
    }

  }, [email, passwordVerifyEmail])



  const handleChangeDate = (date) => {
    setValue(date)
    if (moment(date).format('MM/DD/YYYY') === profile?.dDob) {
      setDisableInfoEdit(false)
    } else {
      setDisableInfoEdit(true)
    }
  }

  function handleEdit() {
    setShowEdit(true)
    setEditable(true)
    setShowSubmit(true)
    setDisableInfoEdit(false)
    if (inputRef.current) {
      inputRef.current.disabled = false
      inputRef.current.focus()
    }
  }

  function handleCross() {
    setShowEdit(false)
    setEditable(false)
    setShowSubmit(false)
    setUsername(profile?.sUserName || '')
    setFullName(profile?.sFullName || '')
    setDOB(profile?.dDob || '')
    setValue(profile?.dDob ? moment(profile?.dDob).toDate() : '')
    setEmail(profile?.sEmail || '')
    setMobileNumber(profile?.sMobile?.substring(3) || '')
    setAddress1(profile?.oAddress?.sAddressLine1 || '')
    setAddress2(profile?.oAddress?.sAddressLine2 || '')
    setLandmark(profile?.oAddress?.sLandMark || '')
    setCity(profile?.oAddress?.sCity || '')
    setState(profile?.oAddress?.sState || '')
    setPincode(profile?.oAddress?.nPinCode || '')
    setWithdrawableCash(profile?.nWithdrawable?.toFixed(2) || 0)
    setCurrentAvatar(profile?.sAvatar || '')
    setUpdatedAvatar(profile?.sAvatar || '')
    setErrUsername('')
    setErrFullName('')
    setErrAddress1('')
    setErrAddress2('')
    setErrLandmark('')
    setErrCity('')
    setErrPincode('')
    setErrEmail('')
    setErrMobileNumber('')
    setDisableInfoEdit(false)
  }

  function handleEditKYC(e) {
    e.preventDefault()
    setEditKYC(true)
  }
  function handleHideKYC(e) {
    e.preventDefault()
    setEditKYC(false)
    setPANNumber(profile?.oKYC?.sPanNumber || '')
    setPANImage(profile?.oKYC?.sPanCardLink || null)
    setKYCDocumentType(profile?.oKYC?.sDocumentName || 'AdhaarCard')
    setKYCFrontImage(profile?.oKYC?.aDocuments[0] || null)
    setKYCBackImage(profile?.oKYC?.aDocuments[1] || null)
    setErrPANNumber('')
    setErrPANImg('')
    setErrKYCFrontImg('')
    setErrKYCBackImg('')
  }

  function handleChange(event, type) {
    const re = /^[a-zA-Z\s]*$/
    switch (type) {
      case 'Username':
        if (event.target.value.length <= 15) {
          if (verifyLength(event.target.value, 1)) {
            setErrUsername('')
          } else if (!verifyLength(event.target.value, 1)) {
            setErrUsername('Username should not be empty')
          }
          if (event.target.value === profile?.sUserName) {
            setDisableInfoEdit(false)
          } else {
            setDisableInfoEdit(true)
          }
          setUsername(event.target.value)
        }
        break
      case 'FullName':
        if (verifyLength(event.target.value, 1)) {
          setErrFullName('')
        } else if (!verifyLength(event.target.value, 1)) {
          setErrFullName('Full Name should not be empty')
        }
        if (event.target.value === profile?.sUserName) {
          setDisableInfoEdit(false)
        }
        setFullName(event.target.value)
        break
      case 'Email':
        if (verifyLength(event.target.value, 1) && verifyEmail(event.target.value)) {
          setErrEmail('')
        } else if (!verifyLength(event.target.value, 1)) {
          setErrEmail('Please enter your Email')
        } else {
          setErrEmail('Please enter valid Email')
        }
        setEmail(event.target.value)
        break
      case 'MobileNumber':
        if (verifyLength(event.target.value, 1) && verifyMobileNumber(event.target.value)) {
          setErrMobileNumber('')
        } else if (!verifyLength(event.target.value, 1)) {
          setErrMobileNumber('Please enter Mobile Number')
        } else {
          setErrMobileNumber('Please enter valid Mobile Number')
        }
        setMobileNumber(event.target.value)
        setDisableInfoEdit(true)
        break
      case 'Pincode':
        if (verifyPincode(event.target.value)) {
          setErrPincode('')
        } else if (!verifyPincode(event.target.value)) {
          setErrPincode('enter valid pincode')
        } else {
          setErrPincode('please enter valid pincode')
        }
        if (event.target.value === profile?.oAddress?.sPinCode) {
          setDisableInfoEdit(false)
        } else {
          setDisableInfoEdit(true)
        }
        setPincode(event.target.value)
        break
      case 'BankName':
        if (verifyLength(event.target.value, 1)) {
          setErrBankName('')
        } else if (!verifyLength(event.target.value, 1)) {
          setErrBankName('Please enter the bank name')
        }
        if (event.target.value === "" || re.test(event.target.value)) {
          setBankName(event.target.value)
        }
        setDisableBankSubmit(true)
        break
      case 'IFSC':
        if (verifyIFSCcode(event.target.value)) {
          setErrIFSCCode('')
        } else if (!verifyIFSCcode(event.target.value)) {
          setErrIFSCCode('Please enter the valid IFSC code')
        } else {
          setErrIFSCCode('Please enter the IFSC code')
        }
        setIFSCCode(event.target.value.toUpperCase())
        setDisableBankSubmit(true)
        break
      case 'HolderName':
        if (verifyLength(event.target.value, 1)) {
          setErrHolderName('')
        } else if (!verifyLength(event.target.value, 1)) {
          setErrHolderName("Please enter the bank account holder's name")
        }
        if (event.target.value === "" || re.test(event.target.value)) {
          setHolderName(event.target.value)
        }
        setDisableBankSubmit(true)
        break
      case 'AccountNumber':
        if (verifyAccountNumber(event.target.value)) {
          setErrAccountNumber('')
        }
        else if (!verifyAccountNumber(event.target.value)) {
          setErrAccountNumber('Please enter the valid account number')
        } else if (!verifyLength(event.target.value, 1)) {
          setErrAccountNumber('Please enter the account number')
        }
        if (event.target.value == confirmAccountNumber) {
          setErrConfirmAccountNumber('')
        }
        setAccountNumber(event.target.value)
        setDisableBankSubmit(true)
        break

      // if (verifyLength(event.target.value, 1)) {
      //   setErrAccountNumber('')
      // } else if (!verifyLength(event.target.value, 1)) {
      //   setErrAccountNumber('Please enter your bank account number')
      // }
      // if (event.target.value == confirmAccountNumber) {
      //   setErrConfirmAccountNumber('')
      // }
      // setAccountNumber(event.target.value)
      // setDisableBankSubmit(true)
      // break
      case 'ConfimAccountNumber':
        if (verifyLength(event.target.value, 1) && accountNumber == event.target.value) {
          setErrConfirmAccountNumber('')
        }
        else if (accountNumber != event.target.value) {
          setErrConfirmAccountNumber("Account Number don't match")
        }
        setConfirmAccountNumber(event.target.value)
        setDisableBankSubmit(true)
        break
      case 'PANNumber':
        if (verifyLength(event.target.value.toUpperCase(), 1) && verifyPAN(event.target.value.toUpperCase())) {
          setErrPANNumber('')
        } else if (!verifyLength(event.target.value.toUpperCase(), 1)) {
          setErrPANNumber('Please enter PAN number')
        } else {
          setErrPANNumber('Invalid PAN Number')
        }
        setPANNumber(event.target.value.toUpperCase())
        break
      case 'Document':
        setKYCDocumentType(event.target.value)
        break
      case 'PANImage':
        if (event?.target?.files && event.target.files[0]?.size < 1017241) {
          setPANImage(event.target.files[0]);
          setErrPANImg('')
        } else {
          setErrPANImg('Image size must be less than 1 MB')
        }
        break
      case 'KYCFrontImage':
        if (event?.target?.files && event.target.files[0]?.size < 1017241) {
          setKYCFrontImage(event.target.files[0]);
          setErrKYCFrontImg('')
        } else {
          setErrKYCFrontImg('Image size must be less than 1 MB')
        }
        break
      case 'KYCBackImage':
        if (event?.target?.files && event.target.files[0]?.size < 1017241) {
          setKYCBackImage(event.target.files[0]);
          setErrKYCBackImg('')
        } else {
          setErrKYCBackImg('Image size must be less than 1 MB')
        }
        break
      case 'EmailVerifyPassword':
        if (verifyPassword(event.target.value)) {
          setErrPassword('')
        } else if (!verifyLength(event.target.value, 1)) {
          setErrPassword('Required field')
        }
        else {
          setErrPassword('Must contain minimum 6 characters')
        }
        setPasswordVerifyEmail(event.target.value)
        break
      case 'Address1':
        if (verifyLength(event.target.value, 1)) {
          setErrAddress1('')
        } else if (!verifyLength(event.target.value, 1)) {
          setErrAddress1('Address should not be empty')
        }
        if (event.target.value === profile?.oAddress?.sAddressLine1) {
          setDisableInfoEdit(false)
        } else {
          setDisableInfoEdit(true)
        }
        setAddress1(event.target.value)
        break
      case 'Address2':
        if (verifyLength(event.target.value, 1)) {
          setErrAddress2('')
        } else if (!verifyLength(event.target.value, 1)) {
          setErrAddress2('Address should not be empty')
        }
        if (event.target.value === profile?.oAddress?.sAddressLine2) {
          setDisableInfoEdit(false)
        } else {
          setDisableInfoEdit(true)
        }
        setAddress2(event.target.value)
        break
      case 'Landmark':
        if (verifyLength(event.target.value, 1)) {
          setErrLandmark('')
        } else if (!verifyLength(event.target.value, 1)) {
          setErrLandmark('Landmark should not be empty')
        }
        if (event.target.value === profile?.oAddress?.sLandMark) {
          setDisableInfoEdit(false)
        } else {
          setDisableInfoEdit(true)
        }
        setLandmark(event.target.value)
        break
      case 'City':
        if (verifyLength(event.target.value, 1)) {
          setErrCity('')
        } else if (!verifyLength(event.target.value, 1)) {
          setErrCity('City should not be empty')
        }
        if (event.target.value === profile?.oAddress?.sCity) {
          setDisableInfoEdit(false)
        } else {
          setDisableInfoEdit(true)
        }
        setCity(event.target.value)
        break

      default:
        break
    }
  }

  useEffect(() => {
    if (!errUsername && !errFullName && !errPincode) {
      setDisableInfoEdit(true)
    } else {
      setDisableInfoEdit(false)
    }
  }, [username, fullName, pincode, errUsername, errFullName, errPincode]);


  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }
  function handleState(e) {
    setState(e.value)
    if (e.value === profile?.oAddress?.sState) {
      setDisableInfoEdit(false)
    } else {
      setDisableInfoEdit(true)
    }
  }

  function handleChangeKYCDocType(e) {
    setKYCDocumentType(e.value)
  }


  function handlePasswordVisibility() {
    setEyeIcon(eyeIcon => !eyeIcon)
    setPasswordVisibility(passwordVisibility => !passwordVisibility)
  }

  function handleRemovePANImage() {
    setPANImage(null)
    setPANInputDisable(false)
  }

  function handleRemoveKYCFrontImage() {
    setKYCFrontImage(null)
    setKYCFrontInputDisable(false)
  }

  function handleRemoveKYCBackImage() {
    setKYCBackImage(null)
    setKYCBackInputDisable(false)
  }

  function handleEmailUpdate() {
    setLoading(true)
    dispatch(updateEmail(email, passwordVerifyEmail, token))
  }

  function handleEmailVerify() {
    setLoading(true)
    dispatch(verifyEmailRequest(email))
  }

  // function handleEditSubmit() {
  //   const date = (dateOfBirth) => { return (moment(dateOfBirth).format('MM-DD-YYYY')) }
  //   if (currentAvatar != updatedAvatar) {
  //     dispatch(updateProfile({ sAvatar: updatedAvatar, token }))
  //     setLoading(true)
  //   }
  //   if (profile?.sUserName != username && !errUsername) {
  //     dispatch(updateProfile({ sUserName: username, token }))
  //     setLoading(true)
  //   }
  //   if (profile?.sMobile.substring(3) != mobileNumber && !errMobileNumber) {
  //     dispatch(updateMobileNumber(mobileNumber, token))
  //     setLoading(true)
  //   }
  //   if (date(DOB) != date(value)) {
  //     dispatch(updateProfile({ dDob: date(value), token }))
  //     setLoading(true)
  //   }
  //   if ((profile?.oAddress?.sState != state || profile?.oAddress?.sPinCode != pincode) && !errPincode) {
  //     dispatch(updateProfile({ sState: state, sPinCode: pincode, token }))
  //     setLoading(true)
  //   }
  // }


  function handleEditSubmit() {
    const date = (dateOfBirth) => { return (moment(dateOfBirth).format('MM-DD-YYYY')) }

    if ((profile?.sUserName !== username && username?.length && !errUsername) || (profile?.sFullName !== fullName && fullName?.length && !errFullName) || (date(DOB) != date(value)) || (profile?.oAddress?.sAddressLine1 !== address1 && address1?.length) || (profile?.oAddress?.sAddressLine2 !== address2 && address2?.length) || (profile?.oAddress?.sLandMark !== landmark && landmark?.length) || (profile?.oAddress?.sCity !== city && city?.length) || (profile?.oAddress?.sState !== state && state?.length) || (profile?.oAddress?.nPinCode !== pincode) || (pincode?.length && !errPincode)) {
      setLoading(true)
      dispatch(updateProfile({ sEmail: email, sUserName: username, sFullName: fullName, dDob: value && date(value), sAddressLine1: address1, sAddressLine2: address2, sLandMark: landmark, sCity: city, sState: state, nPinCode: pincode, token }))
      handleErrors()
    }

    // if (profile?.sUserName !== username && username?.length && !errUsername) {
    //   setLoading(true)
    //   dispatch(updateProfile({ sUserName: username, token }))
    //   handleErrors()
    // }
    // if (profile?.sFullName !== fullName && fullName?.length && !errFullName) {
    //   setLoading(true)
    //   dispatch(updateProfile({ sFullName: fullName, token }))
    //   handleErrors()
    // }
    // if (date(DOB) != date(value)) {
    //   dispatch(updateProfile({ dDob: date(value), token }))
    //   setLoading(true)
    // }
    // if (profile?.oAddress?.sAddressLine1 !== address1 && address1?.length) {
    //   setLoading(true)
    //   dispatch(updateProfile({ sAddressLine1: address1, token }))
    //   handleErrors()
    // }
    // if (profile?.oAddress?.sAddressLine2 !== address2 && address2?.length) {
    //   setLoading(true)
    //   dispatch(updateProfile({ sAddressLine2: address2, token }))
    //   handleErrors()
    // }
    // if (profile?.oAddress?.sLandMark !== landmark && landmark?.length) {
    //   setLoading(true)
    //   dispatch(updateProfile({ sLandMark: landmark, token }))
    //   handleErrors()
    // }
    // if (profile?.oAddress?.sCity !== city && city?.length) {
    //   setLoading(true)
    //   dispatch(updateProfile({ sCity: city, token }))
    //   handleErrors()
    // }
    // if (profile?.oAddress?.sState !== state && state?.length) {
    //   setLoading(true)
    //   dispatch(updateProfile({ sState: state, token }))
    //   handleErrors()
    // }
    // if (profile?.oAddress?.nPinCode !== pincode && pincode?.length && !errPincode) {
    //   setLoading(true)
    //   dispatch(updateProfile({ nPinCode: pincode, token }))
    //   handleErrors()
    // }
  }

  function handleErrors() {
    setErrUsername('')
    setErrFullName('')
    // setErrAddress1('')
    // setErrAddress2('')
    // setErrLandmark('')
    // setErrCity('')
    setErrPincode('')
  }




  function handleBankDetails(e) {
    e.preventDefault()
    if (bankName?.length && IFSCCode.length && holderName?.length && accountNumber?.length && confirmAccountNumber?.length && !errBankName && !errIFSCCode && !errHolderName && !errAccountNumber && !errConfirmAccountNumber) {
      dispatch(addBank(bankName, accountNumber, IFSCCode, holderName, token))
      setLoading(true)
    } else {
      if (!verifyLength(bankName, 1)) {
        setErrBankName('Please enter the bank name')
      }
      if (!verifyLength(IFSCCode, 1)) {
        setErrIFSCCode("Please enter your bank's IFSC code")
      }
      if (!verifyLength(holderName, 1)) {
        setErrHolderName("Please enter the bank account holder's name")
      }
      if (!verifyLength(accountNumber, 1)) {
        setErrAccountNumber('Please enter your bank account number')
      }
      if (!verifyLength(confirmAccountNumber, 1)) {
        setErrConfirmAccountNumber('Please re-enter your bank account number')
      }
    }
  }

  async function handleKYCSubmit(e) {
    e.preventDefault()
    const formdata = new FormData()
    if (typeof PANImage === 'string') {
      let PANblob = await fetch(PANImage).then(r => r.blob()).catch(error => console.log(error))
      formdata.append('sPancard', PANblob)
    } else {
      formdata.append('sPancard', PANImage)
    }

    if (typeof KYCFrontImage === 'string') {
      let KYCFrontblob = await fetch(KYCFrontImage).then(r => r.blob()).catch(error => console.log(error))
      formdata.append('aDocuments', KYCFrontblob)
    } else {
      formdata.append('aDocuments', KYCFrontImage)
    }

    if (typeof KYCBackImage === 'string') {
      let KYCBackblob = await fetch(KYCBackImage).then(r => r.blob()).catch(error => console.log(error))
      formdata.append('aDocuments', KYCBackblob)
    } else {
      formdata.append('aDocuments', KYCBackImage)
    }

    formdata.append('sPanNumber', PANNumber)
    formdata.append('sDocumentName', KYCDocumentType)
    dispatch(uploadKYC(formdata, token))

    setLoading(true)


  }

  const optionsForKYC = [
    { value: 'Aadhaar Card', label: 'Aadhaar Card' },
    { value: 'Voter Id', label: 'Voter Id' },
    { value: 'Driving Licence', label: 'Driving License' },
    { value: 'Passport', label: 'Passport' }
  ];

  function handleRefreshChips() {
    setLoading(true)
    dispatch(refreshPracticeChips(token))
    toast(
      <div>
        <div>Refill practice chips if they fall below 5,000.</div>
      </div>
      , { autoClose: 3000 });
  }

  function handleEnterCode(e) {
    setCode(e.target.value)
  }

  function handleJoinAgent() {
    setLoading(true)
    dispatch(updateAgentId(code, token))
  }

  function checkRequiredFields() {
    return (
      username.trim() !== '' &&
      fullName.trim() !== '' &&
      value !== null && // DOB
      address1.trim() !== '' &&
      landmark.trim() !== '' &&
      email.trim() !== '' &&
      city.trim() !== '' &&
      state !== null
    );
  }

  useEffect(() => {
    setRequiredFieldsFilled(checkRequiredFields());
  }, [username, fullName, value, address1, landmark, city, pincode, state, email]);

  console.log(requiredFieldsFilled, disableInfoEdit);

  return (
    <>
      {loading && <Loading />}
      <div className="account-cnt ">
        <div className="my-profile personal-detail">
          {/* <h3 className="text-center account-title">My Account</h3> */}
          <div className="table-heading">My Profile</div>
          <div className="point-table">
            <div className="table-cnt p-0">
              <Nav tabs className="justify-content-center d-flex">
                <NavItem>
                  <NavLink className={classnames({ active: activeTab === "1" })} onClick={() => { toggle("1"); setActiveProfiletab('1') }} >
                    Basic Info
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className={classnames({ active: activeTab === "2" })} onClick={() => { toggle("2"); setActiveProfiletab('2') }} >
                    KYC
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className={classnames({ active: activeTab === "3" })} onClick={() => { toggle("3"); setActiveProfiletab('3') }} >
                    Bank Account
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <div className="user-dtl user-dtl-box table-cnt-box position-relative">
                    <div className="row">
                      <div className=" col-md-10">
                        <div className="row">
                          <div className="col-lg-6 col-xl-4 col-xxl-3">
                            <p className="medium">Username</p>
                            <input type="text" maxLength={10} ref={inputRef} onFocus={(e) => e.persist()} placeholder="Enter Username" value={username} className={editable ? "editable" : ""} onChange={(e) => handleChange(e, "Username")} />
                            <p className='error-text error-red'>{errUsername}</p>
                          </div>
                          <div className="col-lg-6 col-xl-4 col-xxl-3">
                            <p className="medium ">Full name</p>
                            <input type="text" placeholder="Enter Full name" maxLength={100} value={fullName} className={editable ? "editable" : ""} disabled={!editable} onChange={(e) => handleChange(e, "FullName")} />
                            <p className='error-text error-red'>{errFullName}</p>
                          </div>
                          <div className="col-lg-6 col-xl-4 col-xxl-3">
                            <div className='d-flex justify-content-start verified-box'>
                              <p className="medium">Email</p>
                              {profile?.isEmailVerified && <img src={verified} alt="tick_mark" />}
                            </div>
                            {/* <p className="medium ">Email</p> */}
                            {/* <input type="email" placeholder="Enter Email" value={email} readOnly className='text-wrap' /> */}
                            <input type="email" placeholder="Enter Email" maxLength={364} value={email} className={editable ? "editable" : ""} disabled={!editable} onChange={(e) => handleChange(e, "Email")} />

                            <p className='error-text error-red'>{errEmail}</p>
                          </div>
                          {/* <div className="col-lg-6 col-xl-4 col-xxl-3">
                            <div className='d-flex justify-content-start verified-box'>
                              <p className="medium">Email</p>
                              {profile?.isEmailVerified && <img src={verified} alt="tick_mark" />}
                            </div>
                            <input type="email" placeholder="Enter Email" value={email} readOnly className='text-wrap' />
                          </div> */}
                          <div className="col-lg-6 col-xl-4 col-xxl-3">
                            <div className='d-flex justify-content-start verified-box'>
                              <p className="medium">Mobile Number</p>
                              {profile?.isMobileVerified && <img src={verified} alt="tick_mark" />}
                            </div>
                            <input type="text" required placeholder="Enter your Mobile Number" value={mobileNumber} className={editable && !isMobileVerified ? "editable" : ""} disabled={isMobileVerified} onChange={(e) => handleChange(e, "MobileNumber")} />
                          </div>

                          {/* <div className="col-lg-6 col-xl-4 col-xxl-3">
                            <p className="medium">Gender</p>
                            <input type="text" value={profileData?.eGender === 'male' ? "Male" : "Female"} />
                          </div> */}

                          <div className="col-lg-6 col-xl-4 col-xxl-3">
                            <p className="medium">DOB</p>
                            <DatePicker
                              onChange={handleChangeDate}
                              value={value}
                              disabled={!editable}
                              calendarIcon={null}
                              clearIcon={null}
                              className="datePicker"
                              maxDate={moment().subtract(18, "years")._d}
                            />

                            {/* <input type="text" value="30/10/1995" disabled /> */}
                          </div>

                          <div className="col-lg-6 col-xl-4 col-xxl-3">
                            <p className="medium">Address Line 1</p>
                            <input type="text" placeholder="Enter Address Line 1" maxLength={100} value={address1} className={editable ? "editable" : ""} disabled={!editable} onChange={(e) => handleChange(e, "Address1")} />
                          </div>
                          <div className="col-lg-6 col-xl-4 col-xxl-3">
                            <p className="medium">Address Line 2</p>
                            <input type="text" placeholder="Enter Address Line 2"  maxLength={100} value={address2} className={editable ? "editable" : ""} disabled={!editable} onChange={(e) => handleChange(e, "Address2")} />
                          </div>
                          <div className="col-lg-6 col-xl-4 col-xxl-3">
                            <p className="medium">Landmark</p>
                            <input type="text" maxLength={15} placeholder="Enter Landmark" value={landmark} className={editable ? "editable" : ""} disabled={!editable} onChange={(e) => handleChange(e, "Landmark")} />
                          </div>
                          <div className="col-lg-6 col-xl-4 col-xxl-3">
                            <p className="medium">City</p>
                            <input type="text" placeholder="Enter City" value={city} maxLength={100} className={editable ? "editable" : ""} disabled={!editable} onChange={(e) => handleChange(e, "City")} />
                          </div>
                          <div className="col-lg-6 col-xl-4 col-xxl-3">
                            <p className="medium">Postal Code</p>
                            <input type="number" placeholder="Enter Postal Code"  value={pincode} className={editable ? "editable" : ""} disabled={!editable} onChange={(e) => handleChange(e, "Pincode")} />
                            <p className='error-text error-red'>{errPincode}</p>
                          </div>
                          <div className="col-lg-6 col-xl-4 col-xxl-3">
                            <p className="medium">State</p>
                            <Select
                              isDisabled={!editable}
                              options={optionsState}
                              defaultValue={{
                                label:
                                  optionsState.filter(function (option) {
                                    return option.label === state
                                  })
                              }}
                              onChange={(e) => handleState(e)}
                              className="state-options"
                              value={optionsState.filter(function (option) {
                                return option.value === state;
                              })}
                              placeholder="Select a State"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <button onClick={handleEdit} style={{ "backgroundColor": "rgb(133 84 230)" }} className={!showEdit ? "edit-user-dtl" : "d-none"}>
                      <img src={EditIcon} alt="edit" />
                    </button>
                  </div>

                  <div className="row">
                    <div className="col-xl-6">
                      <div className="user-dtl acc-dtl table-cnt-box position-relative">
                        <div className="account-inner-box">
                          <p className="inner-title">Cash Account</p>
                          <div className="row">
                            <div className="col-md-9">
                              <input type="text" value="Deposite Balance" className="editable" disabled />
                            </div>
                            <div className="col-md-3">
                              <p className='basic-txt editable'>&#8377;{formatSliderValue(deposit) || '0'}</p>
                              {/* <input type="text" value={deposit} className="editable" disabled /> */}
                            </div>
                            <div className="col-md-9">
                              <input type="text" value="Withdrawable Balance" className="editable" disabled />
                            </div>
                            <div className="col-md-3">
                              <p className='basic-txt editable'>&#8377;{withdraw}</p>

                              {/* <input type="text" value={withdraw} className="editable" disabled /> */}
                            </div>
                            <div className="col-md-9">
                              <input type="text" value="TDS (FY: 2022-2023)" className="editable" disabled />
                            </div>
                            <div className="col-md-3">
                              <p className='basic-txt editable'>&#8377;{tds}</p>

                              {/* <input type="text" value={tds} className="editable" disabled /> */}
                            </div>
                            <div className="col-md-9">
                              <input type="text" value="Total Cash Balance" className="editable" disabled />
                            </div>
                            <div className="col-md-3">
                              <p className='basic-txt editable'>&#8377; {formatSliderValue(((deposit - tds) + (instantPending + instantReleased))) || '0'}</p>

                              {/* <input type="text" value={deposit + withdraw - tds} className="editable" disabled /> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="user-dtl acc-dtl table-cnt-box position-relative">
                        <div className="account-inner-box">

                          {/* <p className="inner-title">Bonus Account</p> */}
                          <div className="row">
                            <div className="col-md-6">
                              <p className="inner-title" style={{ "color": "white" }}>Bonus Account</p>

                            </div>
                            <div className='col-md-3'>
                              <p className='' style={{ "color": "white" }}>Instant</p>

                            </div>
                            <div className='col-md-3'>
                              <p className='' style={{ "color": "white" }}>Lock</p>

                            </div>
                            <div className="col-md-6">
                              <input
                                type="text"
                                value="Pending Bonus"
                                className="editable"
                                disabled
                              />
                            </div>
                            <div className='col-md-3'>
                              <p className='basic-txt editable'>&#8377;{instantPending}</p>
                            </div>
                            <div className="col-md-3">
                              <p className='basic-txt editable'>&#8377;{instantReleased}</p>

                              {/* <input
                                type="text"
                                value={instantPending}
                                className="editable"
                                disabled
                              /> */}

                            </div>
                            <div className="col-md-6">
                              <input
                                type="text"
                                value="Released Bonus"
                                className="editable"
                                disabled
                              />
                            </div>
                            <div className='col-md-3'>
                              <p className='basic-txt editable'>&#8377;{lockedPending}</p>

                            </div>
                            <div className="col-md-3">
                              {/* <input
                                type="text"
                                value={instantReleased}
                                className="editable"
                                disabled
                              /> */}
                              <p className='basic-txt editable'>&#8377;{lockedReleased}</p>
                            </div>
                            <div className="col-md-6">
                              <input
                                type="text"
                                value="Total"
                                className="editable"
                                disabled
                              />
                            </div>
                            <div className='col-md-3'>
                              <p className='basic-txt editable'>&#8377;{instantPending + instantReleased}</p>
                            </div>
                            <div className="col-md-3">
                              <p className='basic-txt editable'>&#8377;{lockedPending + lockedReleased}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-xl-6">
                      <div className="user-dtl acc-dtl table-cnt-box position-relative">
                        <div className="account-inner-box">
                          <p className="inner-title">Club Account</p>
                          <div className="row">
                            {!!clubDetails?.sName?.length &&
                              <>
                                <div className="col-md-9">
                                  <input
                                    type="text"
                                    value="Active Club"
                                    className="editable"
                                    disabled
                                  />
                                </div>
                                <div className='col-md-3'>
                                  <p className='basic-txt editable'>{clubDetails?.sName}</p>
                                </div>
                              </>
                            }
                            
                            <div className="col-md-9">
                              <input
                                type="text"
                                value="Loyalty Points"
                                className="editable"
                                disabled
                              />
                            </div>
                            <div className='col-md-3'>
                              <p className='basic-txt editable'>{clubDetails?.nLP || 0}</p>
                            </div>
                           
                            <div className="col-md-9">
                              <input
                                type="text"
                                value="Used Club Bonus"
                                className="editable"
                                disabled
                              />
                            </div>
                            <div className='col-md-3'>
                              <p className='basic-txt editable'>&#8377;{clubDetails?.nUsedBonus || "0"}</p>
                            </div>
                            <div className="col-md-9">
                              <input
                                type="text"
                                value="Total Club Balance"
                                className="editable"
                                disabled
                              />
                            </div>
                            <div className='col-md-3'>
                              <p className='basic-txt editable'>&#8377;{clubDetails?.nBonus || "0"}</p>
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div> */}

                  </div>

                  {/* <div className="text-center">
                    <button className="common-btn yellow-btn">Submit</button>
                  </div> */}
                  <div className={showSubmit ? "text-center side-btn tournament-tab-wrap" : "d-none"}>
                    <button
                      type="button"
                      disabled={!requiredFieldsFilled}
                      style={{ border: 'none' }}
                      className="common-btn"
                      onClick={handleEditSubmit}
                    >
                      Submit
                    </button>
                    <button className="common-btn" style={{ border: 'none' }} onClick={handleCross}>
                      Cancel
                    </button>
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <div className="kyc-detail">
                    <div className="row ">
                      <div className=" w-100">
                        <div className='kyc-status'>
                          {/* {statusKYC && (
                            <p>
                              Status:
                              <span className={`text-capitalize ${statusKYC === 'approved' ? 'color-green' : statusKYC === 'rejected' ? 'color-red' : 'color-orange'}`}>
                                {statusKYC}
                              </span>
                            </p>
                          )} */}
                          {statusKYC === 'rejected' && rejectReason && (

                            <>
                              <div className='text-light kyc-med-text'>
                                {statusKYC === 'pending' ? 'Waiting for admin to Approve your KYC' : statusKYC === 'approved' ? 'Your KYC is Approved' : statusKYC === 'rejected' ? 'Your KYC is Rejected' : ''}
                                <p>
                                  Reason:
                                  <span className='text-capitalize color-red'>
                                    {rejectReason}
                                  </span>
                                </p>
                              </div>

                              <form onSubmit={handleKYCSubmit}>
                                <div className="kyc-box">
                                  <div className="kyc-header d-flex justify-content-between w-100">
                                    <h4 className="white-text">PAN Card</h4>
                                    <button onClick={handleEditKYC} className={(!editKYC && statusKYC !== 'approved') ? "border-0 bg-transparent edit-profile-btn" : "d-none"}>
                                      <img src={EditBlueIcon} alt="edit-icon" />
                                    </button>
                                    <button onClick={handleHideKYC} className={editKYC ? "border-0 bg-transparent edit-profile-btn" : "d-none"}>
                                      <img src={cross} alt="remove" />
                                    </button>
                                  </div>
                                  <div className="table-cnt-box kyc-body backgroundbtnimageKYC">
                                    <div className="row align-items-center justify-content-between">
                                      <div className="col-md-6 col-lg-6 col-xxl-7">
                                        <div className="form-group">
                                          <p>Enter Pan Number</p>
                                          <input type="text" style={{ "textTransform": "uppercase" }} maxLength="10" value={PANNumber} onChange={(e) => handleChange(e, "PANNumber")} disabled={!editKYC} />
                                          <p className="error-text error-red" >{errPANNumber}</p>
                                          {/* error text can be orange just add error-orange class */}
                                        </div>
                                      </div>
                                      <div className="col-md-6 col-lg-6 justify-content-end ml-auto col-xxl-4 text">
                                        <div className='position-relative err-msg-box'>
                                          <div className="custom-file grey-box ">
                                            {editKYC &&
                                              <input type="file"
                                                id="file-upload-box1"
                                                accept="image/*"
                                                onChange={(event) => handleChange(event, 'PANImage')}
                                                disabled={PANInputDisable || !editKYC}
                                              />}

                                            {PANImage ? (
                                              <img id="PANimg" alt="not found" width={"250px"} src={typeof PANImage === 'string' ? PANImage : URL.createObjectURL(PANImage)} />
                                            ) : editKYC ?
                                              <>
                                                <img src={UploadIcon} alt="upload-icon" className='uploadicon-img' />
                                                <p className="small-text">Upload Front View</p>
                                                <span className="select-file">SELECT FILE</span>
                                              </> :
                                              <>
                                                <img src={UploadIcon} alt="upload-icon" className='uploadicon-img' />
                                                <p className="small-text">Upload Front View</p>
                                              </>
                                            }
                                            {PANImage &&
                                              (
                                                <button onClick={handleRemovePANImage} disabled={!editKYC} className={statusKYC !== 'approved' ? "p-0 border-0 bg-transparent remove-img-btn" : 'd-none'}>
                                                  <img src={BlackCross} alt="removeImage" />
                                                </button>
                                              )}
                                          </div>

                                          <p className="error-text error-red">{errPANImg}</p>
                                        </div>
                                        {/* <input
                                      type="file"
                                      id="file-upload-box1"
                                    />
                                    <img src={UploadIcon} alt="upload-icon" className='uploadicon-img' />
                                    <p className="small-text">
                                      Upload Front View
                                    </p> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="kyc-box other-kyc">
                                  <div className="kyc-header d-flex justify-content-between w-100">
                                    <h4 className="white-text">
                                      Other KYC Document
                                    </h4>
                                    {/* <img src={EditBlueIcon} alt="edit-icon" /> */}
                                  </div>
                                  <div className="table-cnt-box kyc-body backgroundbtnimageKYC">
                                    <div className="row">
                                      <div className="col-md-12 col-lg-12 ">
                                        <div className="row">

                                          <div className="col-md-12 d-flex justify-content-between kyc-document-box">
                                            <div className="kyc-dropdown-box">
                                              {/* <MyAccountDropdown/> */}

                                              <div className="d-flex justify-content-center p-3 flex-column">
                                                <p>Select Document</p>
                                                <div className="cust-dropdown-box">
                                                  <Select
                                                    isDisabled={!editKYC}
                                                    options={optionsForKYC}
                                                    defaultValue={{
                                                      label:
                                                        optionsForKYC.filter(function (option) {
                                                          return option.value === KYCDocumentType
                                                        })
                                                    }}
                                                    onChange={(e) => handleChangeKYCDocType(e)}
                                                    className="dropdown-kyc-box "
                                                    value={optionsForKYC.filter(function (option) {
                                                      return option.value === KYCDocumentType;
                                                    })}
                                                    placeholder="Select a Document"
                                                  />

                                                  {/* <Select
                                              defaultValue={options[0]}
                                              options={options}
                                              className="dropdown-kyc-box "
                                              classNamePrefix={'inner-dropdown'}
                                              direction="down"
                                              // menuIsOpen={true}
                                              menuPlacement={'auto'}
                                            /> */}

                                                  {/* <Input
                                                id="exampleSelect"
                                                name="select"
                                                type="select"
                                              >
                                                <option>
                                                  Aadhar Card
                                                </option>
                                                <option>
                                                  Voter ID
                                                </option>
                                                <option>
                                                  Driving License
                                                </option>
                                                <option>
                                                  Passport
                                                </option>
                                              </Input>
                                              <img src={DropDownArrow} className="cust-down-aroow" alt="Down arrow" /> */}
                                                </div>

                                              </div>
                                            </div>
                                            <div className='position-relative err-msg-box'>
                                              <div className="custom-file grey-box">
                                                {editKYC &&
                                                  <input type="file"
                                                    accept="image/*"
                                                    onChange={(event) => handleChange(event, 'KYCFrontImage')}
                                                    disabled={KYCFrontInputDisable || !editKYC}
                                                  />
                                                }
                                                {KYCFrontImage ? (
                                                  <img id="KYCFront" alt="not found" width={"250px"} src={typeof KYCFrontImage === 'string' ? KYCFrontImage : URL.createObjectURL(KYCFrontImage)} />
                                                ) : editKYC ?
                                                  <>
                                                    <img src={UploadIcon} alt="upload-icon" className='uploadicon-img' />
                                                    <p className="small-text">Upload Front View</p>
                                                    <span className="select-file">SELECT FILE</span>
                                                  </> :
                                                  <>
                                                    <img src={UploadIcon} alt="upload-icon" className='uploadicon-img' />
                                                    <p className="small-text">Upload Front View</p>
                                                  </>
                                                }
                                                {KYCFrontImage &&
                                                  (
                                                    <button onClick={handleRemoveKYCFrontImage} disabled={!editKYC} className={statusKYC !== 'approved' ? "p-0 border-0 bg-transparent remove-img-btn" : 'd-none'}>
                                                      <img src={BlackCross} alt="removeImage" />
                                                    </button>
                                                  )}
                                              </div>
                                              <p className="error-text error-red">{errKYCFrontImg}</p>
                                            </div>

                                            {/* <input
                                          type="file"
                                          id="file-upload-box1"
                                        />
                                        <img
                                          src={UploadIcon}
                                          alt="upload-icon" className='uploadicon-img'
                                        />
                                        <p className="small-text">
                                          Upload Front View
                                        </p> */}
                                            <div className='position-relative err-msg-box'>
                                              <div className="custom-file grey-box">
                                                {editKYC &&
                                                  <input type="file"
                                                    accept="image/*"
                                                    onChange={(event) => handleChange(event, 'KYCBackImage')}
                                                    disabled={KYCBackInputDisable || !editKYC}
                                                  />
                                                }
                                                {KYCBackImage ? (
                                                  <img id="KYCBack" alt="not found" width={"250px"} src={typeof KYCBackImage === 'string' ? KYCBackImage : URL.createObjectURL(KYCBackImage)} />
                                                ) : editKYC ?
                                                  <>
                                                    <img src={UploadIcon} alt="upload-icon" className='uploadicon-img' />
                                                    <p className="small-text">Upload Back View</p>
                                                    <span className="select-file">SELECT FILE</span>
                                                  </>
                                                  :
                                                  <>
                                                    <img src={UploadIcon} alt="upload-icon" className='uploadicon-img' />
                                                    <p className="small-text">Upload Back View</p>
                                                  </>
                                                }
                                                {KYCBackImage &&
                                                  (
                                                    <button onClick={handleRemoveKYCBackImage} disabled={!editKYC} className={statusKYC !== 'approved' ? "p-0 border-0 bg-transparent remove-img-btn" : 'd-none'}>
                                                      <img src={BlackCross} alt="removeImage" />
                                                    </button>
                                                  )}
                                              </div>
                                              <p className="error-text error-red">{errKYCBackImg}</p>
                                            </div>
                                            {/* <input
                                          type="file"
                                          id="file-upload-box1"
                                        />
                                        <img
                                          src={UploadIcon}
                                          alt="upload-icon" className='uploadicon-img'
                                        />
                                        <p className="small-text">
                                          Upload Front View
                                        </p> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="text-center kyc-doc-btns">
                                  <p className="small-text">You may also email us your KYC Documents to <a href="mailto:support@rummyrani.co.in" className="common-link">support@rummyrani.co.in</a></p>
                                  <button type="submit" disabled={disableKYC} style={{ "border": "none", "padding": "28px 20px" }} className={editKYC ? " common-btn backgroundbtn " : "d-none"}>UPLOAD & SAVE</button>
                                </div>
                              </form>
                            </>
                          )}
                        </div>
                        {(statusKYC === 'pending' || statusKYC === 'approved') &&
                          <>
                            <div className='text-light kyc-med-text'>
                              {statusKYC === 'pending' ? 'Waiting for admin to Approve your KYC' : statusKYC === 'approved' ? <>
                                <div>
                                  <span className='verify-header'>Your KYC is Verified</span>
                                  <div className='kyc-documents'>
                                    <div><span>KYC Documents:</span> {KYCDocumentType}</div>
                                    <div><span>PAN Card:</span> {PANNumber}</div>
                                  </div>
                                </div>
                              </> : ''}
                            </div>
                          </>
                        }
                        {statusKYC === '' &&
                          <form onSubmit={handleKYCSubmit}>
                            <div className="kyc-box">
                              <div className="kyc-header d-flex justify-content-between w-100">
                                <h4 className="white-text">PAN Card</h4>
                                {/* <button onClick={handleEditKYC} className={(!editKYC && statusKYC !== 'approved') ? "border-0 bg-transparent edit-profile-btn" : "d-none"}>
                                  <img src={EditBlueIcon} alt="edit-icon" />
                                </button>
                                <button onClick={handleHideKYC} className={editKYC ? "border-0 bg-transparent edit-profile-btn" : "d-none"}>
                                  <img src={cross} alt="remove" />
                                </button> */}
                              </div>
                              <div className="table-cnt-box kyc-body backgroundbtnimageKYC">
                                <div className="row align-items-center justify-content-between">
                                  <div className="col-md-6 col-lg-6 col-xxl-7">
                                    <div className="form-group">
                                      <p>Enter Pan Number</p>
                                      <input type="text" style={{ "textTransform": "uppercase" }} maxLength="10" value={PANNumber} onChange={(e) => handleChange(e, "PANNumber")} disabled={!editKYC} />
                                      <p className="error-text error-red" >{errPANNumber}</p>
                                      {/* error text can be orange just add error-orange class */}
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-lg-6 justify-content-end ml-auto col-xxl-4 text">
                                    <div className='position-relative err-msg-box'>
                                      <div className="custom-file grey-box ">
                                        {editKYC &&
                                          <input type="file"
                                            id="file-upload-box1"
                                            accept="image/*"
                                            onChange={(event) => handleChange(event, 'PANImage')}
                                            disabled={PANInputDisable || !editKYC}
                                          />}

                                        {PANImage ? (
                                          <img id="PANimg" alt="not found" width={"250px"} src={typeof PANImage === 'string' ? PANImage : URL.createObjectURL(PANImage)} />
                                        ) : editKYC ?
                                          <>
                                            <img src={UploadIcon} alt="upload-icon" className='uploadicon-img' />
                                            <p className="small-text">Upload Front View</p>
                                            <span className="select-file">SELECT FILE</span>
                                          </> :
                                          <>
                                            <img src={UploadIcon} alt="upload-icon" className='uploadicon-img' />
                                            <p className="small-text">Upload Front View</p>
                                          </>
                                        }
                                        {PANImage &&
                                          (
                                            <button onClick={handleRemovePANImage} disabled={!editKYC} className={statusKYC !== 'approved' ? "p-0 border-0 bg-transparent remove-img-btn" : 'd-none'}>
                                              <img src={BlackCross} alt="removeImage" />
                                            </button>
                                          )}
                                      </div>

                                      <p className="error-text error-red">{errPANImg}</p>
                                    </div>
                                    {/* <input
                                  type="file"
                                  id="file-upload-box1"
                                />
                                <img src={UploadIcon} alt="upload-icon" className='uploadicon-img' />
                                <p className="small-text">
                                  Upload Front View
                                </p> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="kyc-box other-kyc">
                              <div className="kyc-header d-flex justify-content-between w-100">
                                <h4 className="white-text">
                                  Other KYC Document
                                </h4>
                                {/* <img src={EditBlueIcon} alt="edit-icon" /> */}
                              </div>
                              <div className="table-cnt-box kyc-body backgroundbtnimageKYC">
                                <div className="row">
                                  <div className="col-md-12 col-lg-12 ">
                                    <div className="row">

                                      <div className="col-md-12 d-flex justify-content-between kyc-document-box">
                                        <div className="kyc-dropdown-box">
                                          {/* <MyAccountDropdown/> */}

                                          <div className="d-flex justify-content-center p-3 flex-column">
                                            <p>Select Document</p>
                                            <div className="cust-dropdown-box">
                                              <Select
                                                isDisabled={!editKYC}
                                                options={optionsForKYC}
                                                defaultValue={{
                                                  label:
                                                    optionsForKYC.filter(function (option) {
                                                      return option.value === KYCDocumentType
                                                    })
                                                }}
                                                onChange={(e) => handleChangeKYCDocType(e)}
                                                className="dropdown-kyc-box "
                                                value={optionsForKYC.filter(function (option) {
                                                  return option.value === KYCDocumentType;
                                                })}
                                                placeholder="Select a Document"
                                              />

                                              {/* <Select
                                          defaultValue={options[0]}
                                          options={options}
                                          className="dropdown-kyc-box "
                                          classNamePrefix={'inner-dropdown'}
                                          direction="down"
                                          // menuIsOpen={true}
                                          menuPlacement={'auto'}
                                        /> */}

                                              {/* <Input
                                            id="exampleSelect"
                                            name="select"
                                            type="select"
                                          >
                                            <option>
                                              Aadhar Card
                                            </option>
                                            <option>
                                              Voter ID
                                            </option>
                                            <option>
                                              Driving License
                                            </option>
                                            <option>
                                              Passport
                                            </option>
                                          </Input>
                                          <img src={DropDownArrow} className="cust-down-aroow" alt="Down arrow" /> */}
                                            </div>

                                          </div>
                                        </div>
                                        <div className='position-relative err-msg-box'>
                                          <div className="custom-file grey-box">
                                            {editKYC &&
                                              <input type="file"
                                                accept="image/*"
                                                onChange={(event) => handleChange(event, 'KYCFrontImage')}
                                                disabled={KYCFrontInputDisable || !editKYC}
                                              />
                                            }
                                            {KYCFrontImage ? (
                                              <img id="KYCFront" alt="not found" width={"250px"} src={typeof KYCFrontImage === 'string' ? KYCFrontImage : URL.createObjectURL(KYCFrontImage)} />
                                            ) : editKYC ?
                                              <>
                                                <img src={UploadIcon} alt="upload-icon" className='uploadicon-img' />
                                                <p className="small-text">Upload Front View</p>
                                                <span className="select-file">SELECT FILE</span>
                                              </> :
                                              <>
                                                <img src={UploadIcon} alt="upload-icon" className='uploadicon-img' />
                                                <p className="small-text">Upload Front View</p>
                                              </>
                                            }
                                            {KYCFrontImage &&
                                              (
                                                <button onClick={handleRemoveKYCFrontImage} disabled={!editKYC} className={statusKYC !== 'approved' ? "p-0 border-0 bg-transparent remove-img-btn" : 'd-none'}>
                                                  <img src={BlackCross} alt="removeImage" />
                                                </button>
                                              )}
                                          </div>
                                          <p className="error-text error-red">{errKYCFrontImg}</p>
                                        </div>

                                        {/* <input
                                      type="file"
                                      id="file-upload-box1"
                                    />
                                    <img
                                      src={UploadIcon}
                                      alt="upload-icon" className='uploadicon-img'
                                    />
                                    <p className="small-text">
                                      Upload Front View
                                    </p> */}
                                        <div className='position-relative err-msg-box'>
                                          <div className="custom-file grey-box">
                                            {editKYC &&
                                              <input type="file"
                                                accept="image/*"
                                                onChange={(event) => handleChange(event, 'KYCBackImage')}
                                                disabled={KYCBackInputDisable || !editKYC}
                                              />
                                            }
                                            {KYCBackImage ? (
                                              <img id="KYCBack" alt="not found" width={"250px"} src={typeof KYCBackImage === 'string' ? KYCBackImage : URL.createObjectURL(KYCBackImage)} />
                                            ) : editKYC ?
                                              <>
                                                <img src={UploadIcon} alt="upload-icon" className='uploadicon-img' />
                                                <p className="small-text">Upload Back View</p>
                                                <span className="select-file">SELECT FILE</span>
                                              </>
                                              :
                                              <>
                                                <img src={UploadIcon} alt="upload-icon" className='uploadicon-img' />
                                                <p className="small-text">Upload Back View</p>
                                              </>
                                            }
                                            {KYCBackImage &&
                                              (
                                                <button onClick={handleRemoveKYCBackImage} disabled={!editKYC} className={statusKYC !== 'approved' ? "p-0 border-0 bg-transparent remove-img-btn" : 'd-none'}>
                                                  <img src={BlackCross} alt="removeImage" />
                                                </button>
                                              )}
                                          </div>
                                          <p className="error-text error-red">{errKYCBackImg}</p>
                                        </div>
                                        {/* <input
                                      type="file"
                                      id="file-upload-box1"
                                    />
                                    <img
                                      src={UploadIcon}
                                      alt="upload-icon" className='uploadicon-img'
                                    />
                                    <p className="small-text">
                                      Upload Front View
                                    </p> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="text-center kyc-doc-btns">
                              <p className="small-text">You may also email us your KYC Documents to <a href="mailto:support@rummyrani.co.in" className="common-link">support@rummyrani.co.in</a></p>
                              <button type="submit" disabled={disableKYC} style={{ "border": "none", "padding": "28px 20px" }} className={editKYC ? " common-btn backgroundbtn " : "d-none"}>UPLOAD & SAVE</button>
                            </div>
                          </form>}
                        {/* <div className="text-center">
                          <p className="small-text">You may also email us your KYC Documents to <Link to="mailto:support@rummy24.com" className="common-link">support@rummy24.com</Link></p>
                            <button type="submit" className="common-btn yellow-btn">UPLOAD & SAVE</button>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="3">
                  <div className="bank-details bank-details-wrap">
                    <form>
                      <div className="form-group">
                        <label className="label">Bank Name</label>
                        <input disabled={bankFormInput} type="text" value={bankName} onChange={(e) => handleChange(e, "BankName")} />
                        <p className="error-text error-red">{errBankName}</p>
                      </div>
                      <div className="form-group">
                        <label className="label">IFSC Code</label>
                        <input disabled={bankFormInput} type="text" maxLength="11" value={IFSCCode} onChange={(e) => handleChange(e, "IFSC")} />
                        <p className="error-text error-red">{errIFSCCode}</p>
                      </div>
                      <div className="form-group">
                        <label className="label">Holder Name</label>
                        <input disabled={bankFormInput} type="text" value={holderName} onChange={(e) => handleChange(e, "HolderName")} />
                        <p className="error-text error-red">{errHolderName}</p>
                      </div>
                      <div className="form-group">
                        <label className="label">Account Number</label>
                        <input disabled={bankFormInput} type="number" value={accountNumber} onChange={(e) => handleChange(e, "AccountNumber")} />
                        <p className="error-text error-red">{errAccountNumber}</p>
                      </div>
                      <div className="form-group">
                        <label className="label">
                          Confirm Account Number
                        </label>
                        <input disabled={bankFormInput} autoComplete="new-password" type="password" value={confirmAccountNumber} onChange={(e) => handleChange(e, "ConfimAccountNumber")} />
                        <p className="error-text error-red">{errConfirmAccountNumber}</p>
                      </div>
                      <div className={bankFormInput ? "d-none" : "text-center"}>
                        <Button
                          type="submit"
                          disabled={!bankName || !IFSCCode || !holderName || !accountNumber || !confirmAccountNumber}
                          onClick={handleBankDetails}
                          className="common-btn submit-btn"
                        >
                          submit
                        </Button>                      </div>
                      {/* <div className="text-center ">
                        <button
                          type="submit"
                          className="yellow-btn "
                        >
                          <span> submit </span>
                        </button>
                      </div> */}
                    </form>
                  </div>
                </TabPane>
              </TabContent>
            </div>
          </div>
        </div>
        <Prompt
          when={showEdit && activeTab === "1"}
          message={(location) => {
            return location.pathname.startsWith("/my-account")
              ? true
              : `Data will be lost if you leave the page, are you sure?`
          }}
        />
        <Prompt
          when={editKYC && activeTab === "2"}
          message={(location) => {
            return location.pathname.startsWith("/my-account")
              ? true
              : `Data will be lost if you leave the page, are you sure?`
          }}
        />
        <Prompt
          when={(bankName?.length || IFSCCode?.length || holderName?.length || accountNumber?.length) && !bankFormInput}
          message={(location) => {
            return location.pathname.startsWith("/my-account")
              ? true
              : `Data will be lost if you leave the page, are you sure?`
          }}
        />
        <Modal
          isOpen={jointAgentModal}
          toggle={joinAgentModalTable}
          className="modal-dialog-centered modal-private-modal-wrap joinTableModal agentModal-wrap"
        >
          <ModalHeader toggle={joinAgentModalTable}>
            <h3
              className="modal-title text-center"
              id="agentModalTitle1"
            >
              Join Agent
            </h3>
          </ModalHeader>

          <ModalBody>
            {/* <div className="text-center no-tournment">
              <p>Agent not found.</p>
              <p>Agent joined successfully</p>
            </div> */}

            <div className="tab-pane fade show active" id="join-rummy" role="tabpanel" aria-labelledby="join-rummy-tab" >
              <div className="join-table-content">
                <div className="form-group">
                  <input type="text" onInput={(e) => e.target.value = e.target.value.slice(0, 6)} maxLength="6" onChange={handleEnterCode} value={code} className="form-control number" id="room-no1" placeholder="Enter agent id" />
                </div>
                <div className=" text-center">
                  <button type="button" disabled={code?.length !== 6} onClick={handleJoinAgent} className="btn theme-orange-btn">
                    <span>Join Table</span>
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
}

export default connect()(MyAccount);
