import constants from '../constants'

export default (state = {}, action) => {
    switch (action.type) {
        case constants.REPORT:
            return {
                ...state,
                resStatus: action.payload.resStatus,
                resMessage: action.payload.resMessage,
            }
        case constants.REPORTED_ISSUES:
            return {
                ...state,
                issuesData: action.payload.data,
                resStatus: action.payload.resStatus,
                resMessage: action.payload.resMessage,
            }
        case constants.TICKET_DATA:
            return {
                ...state,
                ticketData: action.payload.data,
                resStatus: action.payload.resStatus,
                resMessage: action.payload.resMessage,
            }
        case constants.REMOVE_TICKET:
            return {
                ...state,
                removeTicketResStatus: action.payload.resStatus,
                removeTicketResMessage: action.payload.resMessage,
            }

        case constants.CLEAR_REPORT:
            return {
                resMessage: '',
                resStatus: null,
                issuesData: null,
                ticketData: null,
                removeTicketResStatus: null,
                removeTicketResMessage: '',
            }
        default:
            return state
    }
}