import axios from '../axios'

import { history } from '../App'
import constants from '../constants'
import {
    REGISTER_USER,
    LOGIN_USER,
    VERIFY_OTP,
    LOGOUT,
    CLEAR_AUTH_MESSAGE,
    SEND_OTP,
    CLEAR_VERIFICATION_TOKEN,
    TOKEN_LOGIN,
    LOGIN_WITH_OTP
} from "../constantsNew";
import { catchError, verifyMobileNumber } from '../helper'


const errMsg = 'Server is unavailable.'

export const Login = (sMobile, sPassword) => (dispatch) => {
    dispatch({ type: CLEAR_AUTH_MESSAGE })
    dispatch({ type: CLEAR_VERIFICATION_TOKEN })
    axios.post('/auth/login/simple', { sPassword, sMobile: verifyMobileNumber(sMobile) ? `+91${sMobile}` : sMobile }).then((response) => {
        if (response.headers.verification) {
            dispatch({
                type: LOGIN_USER,
                payload: {
                    resMessage: response.data.message,
                    resStatus: true,
                    verificationToken: response.headers.verification
                }
            })
        } else {
            if (response.headers.authorization) {
                localStorage.setItem('Token', response.headers.authorization)
                dispatch({
                    type: TOKEN_LOGIN,
                    payload: {
                        token: response.headers.authorization
                    }
                })
                dispatch({
                    type: LOGIN_USER,
                    payload: {
                        token: response.headers.authorization,
                        resMessage: response.data.message,
                        resStatus: true
                    }
                })
            }
        }
    }).catch((error) => {
        // if (error.response?.data?.message === 'Mobile number not verified.') {
        //     error.response.data.message = `${error.response.data.data?.sMobile}${error.response.data.message}`
        // }
        dispatch(catchError(LOGIN_USER, error))
    })
}
// Dhruvil
export const LoginWithOtp = (sMobile) => (dispatch) => {
    dispatch({ type: CLEAR_AUTH_MESSAGE })
    dispatch({ type: CLEAR_VERIFICATION_TOKEN })
    axios.post('/auth/login', { sMobile: verifyMobileNumber(sMobile) ? `+91${sMobile}` : sMobile }).then((response) => {
        if (response.headers.verification) {
            dispatch({
                type: LOGIN_WITH_OTP,
                payload: {
                    resMessage: response.data.message,
                    resStatus: true,
                    verificationToken: response.headers.verification
                }
            })
        } else {
            if (response.headers.authorization) {
                localStorage.setItem('Token', response.headers.authorization)
                dispatch({
                    type: TOKEN_LOGIN,
                    payload: {
                        token: response.headers.authorization
                    }
                })
                dispatch({
                    type: LOGIN_WITH_OTP,
                    payload: {
                        token: response.headers.authorization,
                        resMessage: response.data.message,
                        resStatus: true
                    }
                })
            }
        }
    }).catch((error) => {
        dispatch(catchError(LOGIN_WITH_OTP, error))
    })
}

export const referralVerify = (sReferralCode) => (dispatch) => {
    dispatch({ type: constants.CLEAR_RESPONSE })
    axios.post('auth/verify/referral', {
        sReferralCode
    }).then((response) => {
        console.log('response', response)
        dispatch({
            type: constants.REFERRAL,
            payload: {
                data: response.data.data.iReferredBy,
                resStatus: true,
                resMessage: response.data.message
            }
        })
    }).catch((error) => {
        dispatch({
            type: constants.REFERRAL,
            payload: {
                resStatus: false,
                resMessage: error.response ? error.response.data.message : errMsg
            }
        })
    })
}


export const Registration = (sEmail, sPassword, sMobile, sUserName, eGender, iReferredBy, accessToken) => (dispatch) => {
    dispatch({ type: CLEAR_AUTH_MESSAGE })
    dispatch({ type: CLEAR_VERIFICATION_TOKEN })
    axios.post(`/auth/register`, {
        sEmail,
        sPassword,
        sMobile: `+91${sMobile}`,
        sUserName,
        eGender,
        iReferredBy
    }, accessToken?.length && {
        headers: {
            'accessToken': accessToken
        },
    }).then(async (response) => {
        dispatch({
            type: REGISTER_USER,
            payload: {
                resMessage: response.data.message,
                resStatus: true,
                registerSuccess: true,
                verificationToken: response.headers.verification
            }
        })
        // }
    }).catch((error) => {
        dispatch({
            type: REGISTER_USER,
            payload: {
                resStatus: false,
                resMessage: error.response ? error.response.data.message : errMsg,
                registerSuccess: false
            }
        })
    })
}

export const SendOtp = (sMobile, sEmail) => (dispatch) => {
    dispatch({ type: CLEAR_AUTH_MESSAGE })
    axios.post('/auth/resendOtp', {
        ...(sMobile?.length ? { sMobile: "+91" + sMobile } : {}),
        ...(sEmail?.length ? { sEmail } : {})
    })
        .then((response) => {
            console.log("resendOtp", response);
            localStorage.setItem('verificationCode',response.data?.data.verification)

            dispatch({
                type: SEND_OTP,
                payload: {
                    resMessage: response.data.message,
                    resStatus: true,
                    isSended: true,
                    verificationId: response.headers.verification,
                    resendOtpTimer: response.data?.data?.nExpiresIn
                }
            })
        }).catch((error) => {
            dispatch(catchError(SEND_OTP, error))
        })
}

export const verifyEmailRequest = (sEmail) => (dispatch) => {
    dispatch({ type: CLEAR_AUTH_MESSAGE })
    axios.post('/auth/email/resend', {
        sEmail,
    })
        .then((response) => {
            dispatch({
                type: constants.VERIFY_EMAIL,
                payload: {
                    resStatus: true,
                    resMessage: response.data.message
                }
            })
        }).catch((error) => {
            dispatch({
                type: constants.VERIFY_EMAIL,
                payload: {
                    resStatus: false,
                    resMessage: error.response ? error.response.data.message : errMsg
                }
            })
        })
}

export const VerifyOtp = (verification, nOtp, mobile) => (dispatch) => {
    dispatch({ type: CLEAR_AUTH_MESSAGE })
    axios.post('/auth/otp/verify', { code: nOtp, sMobile: `+91${mobile}` }, { headers: { verification: verification } }).then((response) => {
        if (response.headers.authorization) {
            localStorage.setItem('Token', response.headers.authorization)
            localStorage.removeItem("verificationCode");
            // dispatch({
            //     type: TOKEN_LOGIN,
            //     payload: {
            //         token: response.headers.authorization
            //     }
            // })
            dispatch({
                type: VERIFY_OTP,
                payload: {
                    token: response.headers.authorization,
                    resMessage: response.data.message,
                    resStatus: true,
                    isVerify: true
                }
            })
        } else {
            dispatch({
                type: VERIFY_OTP,
                payload: {
                    resMessage: response.data.message,
                    resStatus: true,
                    isVerify: false
                }
            })
        }
    }).catch((error) => {
        dispatch(catchError(VERIFY_OTP, error))
    })
}
export const VerifyLoginOtp = (verification, nOtp, mobile) => (dispatch) => {
    dispatch({ type: CLEAR_AUTH_MESSAGE })
    axios.post('/auth/otpVerify', { code: nOtp, sMobile: `+91${mobile}` }, { headers: { verification: verification } }).then((response) => {
        if (response.headers.authorization) {
            localStorage.setItem('Token', response.headers.authorization)
            // dispatch({
            //     type: TOKEN_LOGIN,
            //     payload: {
            //         token: response.headers.authorization
            //     }
            // })
            dispatch({
                type: VERIFY_OTP,
                payload: {
                    token: response.headers.authorization,
                    resMessage: response.data.message,
                    resStatus: true,
                    isVerify: true
                }
            })
        } else {
            dispatch({
                type: VERIFY_OTP,
                payload: {
                    resMessage: response.data.message,
                    resStatus: true,
                    isVerify: false
                }
            })
        }
    }).catch((error) => {
        dispatch(catchError(VERIFY_OTP, error))
    })
}

export const forgotPassword = (sEmail, sMobile) => (dispatch) => {
    dispatch({ type: CLEAR_AUTH_MESSAGE })
    axios.post('/auth/password/forgot', {
        ...(sMobile?.length ? { sEmail: "+91" + sMobile } : {}),
        ...(sEmail?.length ? { sEmail: sEmail } : {})
    }).then((response) => {
        dispatch({
            type: constants.FORGOTPASSWORD,
            payload: {
                data: response.headers.verification,
                resStatus: true,
                resMessage: response.data.message
            }
        })
    }).catch((error) => {
        dispatch({
            type: constants.FORGOTPASSWORD,
            payload: {
                resStatus: false,
                resMessage: error.response ? error.response.data.message : errMsg
            }
        })
    })
}

export const resetPasswordVerify = (nOTP, sPassword, iForgotPasswordId) => (dispatch) => {
    dispatch({ type: CLEAR_AUTH_MESSAGE })
    axios.post('/auth/password/reset', {
        sPassword: sPassword,
        nOTP: nOTP
    }, {
        headers: {
            'verification': iForgotPasswordId
        },

    }).then((response) => {
        dispatch({
            type: constants.RESETPASSWORD,
            payload: {
                resStatusRP: true,
                resMessage: response.data.message
            }
        })
    }).catch((error) => {
        dispatch({
            type: constants.RESETPASSWORD,
            payload: {
                resStatusRP: false,
                resMessage: error.response ? error.response.data.message : errMsg
            }
        })
    })
}

export const socialLogin = (type, idToken, facebookId) => (dispatch) => {
    dispatch({ type: constants.CLEAR_RESPONSE })

    let postData = {}
    if (type === 'Google') {
        postData = { idToken }
    } else {
        postData = { accessToken: idToken, facebookId }
    }

    axios.post('/auth/login/social', postData).then((response) => {
        if (response?.data?.data?.operation !== "registration") {
            localStorage.setItem('Token', response.headers.authorization)
            history.push('/lobby')
        }
        dispatch({
            type: constants.SOCIAL_LOGIN,
            payload: {
                token: response?.data?.data?.operation !== "registration" ? response.headers.authorization : null,
                data: response?.data?.data?.operation === "registration" ? response.data.data : null,
                accessToken: response?.data?.data?.operation === "registration" ? response.headers.accesstoken : null,
                resStatus: true,
                resMessage: response.data.message
            }
        })
    }).catch((error) => {
        dispatch({
            type: constants.SOCIAL_LOGIN,
            payload: {
                mobileNumber: error.response.data.data.sMobile,
                resStatus: false,
                resMessage: error.response ? error.response.data.message : errMsg
            }
        })
    })
}

export const logout = token => (dispatch) => {
    axios.get('/profile/logout', { headers: { Authorization: token } }).then((response) => {
        localStorage.removeItem('Token')
        localStorage.removeItem('userData')
        localStorage.removeItem('userEmail')
        dispatch({
            type: LOGOUT,
            payload: {
                resStatus: true,
                resMessage: response.data.message
            }
        })
    }).catch((error) => {
        dispatch(catchError(LOGOUT, error))
    })
}

export const logoutDuetoPasswordChange = () => (dispatch) => {
    dispatch({ type: constants.LOGOUT_PASSWORD_CHANGE })
}

