const stag = {
    // API_URL: 'https://rummyone-backend.lc.webdevprojects.cloud/api/v1',
    API_URL: "http://13.202.36.170:3001/api/v1",
    SOCKET_URL: 'http://13.202.36.170:3001/',
    GAME_URL: 'http://rummyrani-game-stag.s3-website.ap-south-1.amazonaws.com',
    PAYMENT_KEY: 'rzp_test_1oBrjU3HkzdkS5',
}
const dev = {
    // API_URL: 'https://rummyone-backend.lc.webdevprojects.cloud/api/v1',
    API_URL: "http://192.168.11.56:3001/api/v1",
    SOCKET_URL: 'http://192.168.11.56:3001/',
    GAME_URL: 'http://192.168.11.76:5500',
    PAYMENT_KEY: 'rzp_test_1oBrjU3HkzdkS5',
}


const prod = {
    API_URL: 'https://api.rummyrani.co.in/api/v1',
    SOCKET_URL: 'https://api.rummyrani.co.in/',
    GAME_URL: "https://game.rummyrani.co.in/",
    // GAME_URL: 'https://game.rummy24.com',
    PAYMENT_KEY: 'rzp_live_ZKhR7eRsYo7fcp',
}


export default prod
