import React from 'react';
import { NavLink } from 'react-router-dom';

function Sidebar() {
  return (
    <nav className="main-nav">
      <ul className="nav-items">
        <li>
          <NavLink to="/my-account" activeClassName="active">My Profile</NavLink>
        </li>
        <li>
          <NavLink to="/game-transaction" activeClassName="active">Game Transaction</NavLink>
        </li>
        <li>
          <NavLink to="/passbook" activeClassName="active">Passbook</NavLink>
        </li>
        <li>
          <NavLink to="/withdraw" activeClassName="active">Withdraw</NavLink>
        </li>
        <li>
          <NavLink to="/bonus" activeClassName="active">Bonus</NavLink>
        </li>
        <li>
          <NavLink to="/invite" activeClassName="active">Invite</NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default Sidebar;