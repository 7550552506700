/* eslint-disable react/prop-types */
import React, { useEffect, useState, Fragment, useRef } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, Nav, NavItem, NavLink, TabContent, TabPane, Table, Button, UncontrolledTooltip, List, Modal, ModalBody, ModalHeader } from 'reactstrap';
import classnames from 'classnames';
import { connect, useDispatch, useSelector } from 'react-redux'
import info from '../../../assets/images/icons/info.png';
import filter from '../../../assets/images/icons/filter.png';
import TicketIcon from '../../../assets/images/icons/ticket-icon.png';
import Loading from '../../../components/Loading';
import ResetIcon from "../../../assets/images/icons/reset.png";
import TwoPlayer from "../../../assets/images/player-2.png";
import SixPlayer from "../../../assets/images/player-6.png";
import refresh from '../../../assets/images/refresh-icon.svg';
import upArrow from '../../../assets/images/up-arrow.svg';
import downArrow from '../../../assets/images/down-arrow.svg';
import PlayerJoinedIcon from '../../../assets/images/icons/player-joined-icon.png';
import TimingIcon from '../../../assets/images/icons/timing-icon.png';
import CupIcon from '../../../assets/images/icons/Cup.png';
import trofy from '../../../assets/images/trophy.svg';
import FeeTicketIcon from '../../../assets/images/icons/fee-ticket-icon.png';
import { getLobbyTableList, joinTable } from '../../../actions/lobby';
import withdraw from '../../../reducers/withdraw';
import { getTournamentDetails, getTournamentList, joinTournament, withdrawTournament } from '../../../actions/tournament';
import moment from 'moment';
import config from '../../../config'
import { getProfile } from '../../../actions/account';



function Tournament(props) {

    const [subTab, setSubTab] = useState('13');
    const [tableData, setTableData] = useState([]);
    const [filterToggle, setFilterToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [maxPlayerFilter, setMaxPlayerFilter] = useState(true);
    const [RegisteringChecked, setRegisteringChecked] = useState(false);
    const [UpcomingChecked, setUpcomingChecked] = useState(false);
    const [RegisteredChecked, setRegisteredChecked] = useState(false);
    const [RunningChecked, setRunningChecked] = useState(false);
    const [tournamentList, setTournamentList] = useState([]);
    const [TournamentDetailModal, setTournamentDetailModal] = useState(false)
    const [withdrawTableModal, setWithdrawModal] = useState(false);
    const [registerModal, setRegisterModal] = useState(false);
    const [registrationStartDate, setRegistrationStartDate] = useState('');
    const [registrationEndDate, setRegistrationEndDate] = useState('');
    const [tournamentStartDate, setTournamentStartDate] = useState('');
    const [entryFee, setEntryFee] = useState(0);
    const [playersJoined, setPlayersJoined] = useState(0);
    const [totaSeat, setTotaSeat] = useState(0);
    const [tournamentName, setTournamentName] = useState('');
    const [roundsList, setRoundsList] = useState([]);
    const [prizesList, setPrizesList] = useState([]);
    const [canJoin, setCanJoin] = useState(false);
    const [eState, setEState] = useState('');
    const [tourId, setTourId] = useState('');
    const [timeoutSeconds, setTimeoutSeconds] = useState(0);
    const [joinTournamentId, setJoinTournamentId] = useState('');
    const [withdrawTournamentId, setWithdrawTournamentId] = useState();
    const [totalPrize, setTotalPrize] = useState(0);
    const [tournamentId, setTournamentId] = useState('');
    const [ticket, setTicket] = useState(0);
    const [myTickets, setMyTickets] = useState();
    const [playing, setPlaying] = useState(false);
    const [socketTableId, setSocketTableId] = useState('');
    const [chips, setChips] = useState(0);
    const [participantData, setParticipantData] = useState();
    const [tourDeatailId, setTourDeatailId] = useState();
    const [waitlist, setWaitlist] = useState(false);
    const [participantPosition, setParticipantPosition] = useState();
    const [playingTournamentId, setPlayingTournamentId] = useState();
    const [showWarning, setShowWarning] = useState(false);


    const [myTicketModal, setmyTicketModal] = useState(false)
    const myTicketModalTable = () => setmyTicketModal(!myTicketModal);


    const dispatch = useDispatch()
    const tournamentDetailsData = useSelector(state => state.tournament.tournamentDetailsData)
    const token = useSelector(state => state.auth.token)
    const tournamentListData = useSelector(state => state.tournament.tournamentListData)
    const joinTournamentStatus = useSelector(state => state.tournament.joinResStatus)
    const withdrawTournamentStatus = useSelector(state => state.tournament.withdrawResStatus)
    const profileData = useSelector(state => state.account.dataProfile)
    const takeSeatData = useSelector(state => state.tournament.takeSeatData)



    const previousProps = useRef({ joinTournamentStatus, withdrawTournamentStatus, takeSeatData }).current
    const mainTab = props?.mainTab
    const maintainance = props?.maintainance
    const hour = props?.hour
    const min = props?.min
    const sec = props?.sec

    useEffect(() => {
        if (mainTab === '3' && subTab === '13') {
            setLoading(true)
            dispatch(getTournamentList(token))
            // setFilterToggle(false)
        }
        if (mainTab === '3' && subTab === '14') {
            setLoading(true)
            dispatch(getTournamentList(token))
            // setFilterToggle(false)
        }
        if (mainTab === '3' && subTab === '15') {
            setLoading(true)
            dispatch(getTournamentList(token))
            // setFilterToggle(false)
        }
        if (mainTab === '3' && subTab === '16') {
            setLoading(true)
            dispatch(getTournamentList(token, 'registered'))
            // setFilterToggle(false)
        }

    }, [mainTab, subTab])

    useEffect(() => {
        if (tournamentListData) {
            setLoading(false)
            if (mainTab === '3' && subTab === '13') {
                setTournamentList(tournamentListData)
            }
            if (mainTab === '3' && subTab === '14') {
                const cashFilter = tournamentListData?.filter(item => {
                    return item?.oEntryFee?.nAmount > 0;
                });
                setTournamentList(cashFilter)
            }
            if (mainTab === '3' && subTab === '15') {
                const freeRollFilter = tournamentListData?.filter(item => {
                    return item?.oEntryFee?.nAmount === 0;
                });
                setTournamentList(freeRollFilter)
            }
            if (mainTab === '3' && subTab === '16') {
                setTournamentList(tournamentListData)
            }
        }
    }, [tournamentListData])

    useEffect(() => {
        if (profileData) {
            setMyTickets(profileData?.userTickets)
            setChips(profileData?.nChips?.toFixed(2) || 0)
        }
    }, [profileData])

    useEffect(() => {
        if (previousProps.takeSeatData !== takeSeatData) {
            if (takeSeatData) {
                setPlaying(true)
                setSocketTableId(takeSeatData?.iTableId)
                setPlayingTournamentId(takeSeatData?.iTournamentId)
            }
        }
        return () => {
            previousProps.takeSeatData = takeSeatData
        }
    }, [takeSeatData])


    function handleFilter(e, type) {
        setFilterToggle(false)
        setMaxPlayerFilter(!maxPlayerFilter)
        switch (type) {
            case 'Upcoming':
                setUpcomingChecked(!UpcomingChecked)
                if (!UpcomingChecked) {
                    dispatch(getTournamentList(token, 'upcoming'))
                }
                break
            case 'Registering':
                setRegisteringChecked(!RegisteringChecked)
                if (!RegisteringChecked) {
                    dispatch(getTournamentList(token, 'registrationStarted'))
                }
                break
            case 'Registered':
                setRegisteredChecked(!RegisteredChecked)
                if (!RegisteredChecked) {
                    dispatch(getTournamentList(token, 'registered'))
                }
                break
            case 'Running':
                setRunningChecked(!RunningChecked)
                if (!RunningChecked) {
                    dispatch(getTournamentList(token, 'running'))
                }
                break
        }
        if (maxPlayerFilter) {
            return null
        }
        else {
            dispatch(getTournamentList(token))
        }
    }


    function registerModalToggle(id, waitlistFlag) {
        setRegisterModal(!registerModal)
        setWaitlist(waitlistFlag ? true : false)
        if (registerModal === false) {
            setJoinTournamentId(id)
        }
    }
    function withdrawModalToggle(id) {
        setWithdrawModal(!withdrawTableModal)
        if (withdrawTableModal === false) {
            setWithdrawTournamentId(id)
        }
    }


    function tournamentDetailModalTable(id, name, ticket, ParticipantData, eState, participantPosition) {
        if (TournamentDetailModal === false) {
            setLoading(true)
            dispatch(getTournamentDetails(id, token))
            setTournamentName(name)
            setTournamentId(id?.substr(id?.length - 8))
            setTourDeatailId(id)
            setTicket(ticket)
            setParticipantData(ParticipantData)
            setEState(eState)
            setParticipantPosition(participantPosition)
        } else if (TournamentDetailModal === true) {
            setTourDeatailId(null)
        }
        setTournamentDetailModal(!TournamentDetailModal);
    }



    useEffect(() => {
        if (previousProps.joinTournamentStatus !== joinTournamentStatus) {
            if (joinTournamentStatus === true) {
                setLoading(false)
                setJoinTournamentId('')
                setRegisterModal(false)
                dispatch(getTournamentList(token))
                dispatch(getProfile(token))
                if (TournamentDetailModal === true) {
                    dispatch(getTournamentDetails(tourDeatailId, token))
                }
            } else if (joinTournamentStatus === false) {
                setLoading(false)
                setJoinTournamentId('')
                setRegisterModal(false)
                dispatch(getTournamentList(token))
                if (TournamentDetailModal === true) {
                    dispatch(getTournamentDetails(tourDeatailId, token))
                }

            }
        }
        return () => {
            previousProps.joinTournamentStatus = joinTournamentStatus
        }
    }, [joinTournamentStatus])


    useEffect(() => {
        if (previousProps.withdrawTournamentStatus !== withdrawTournamentStatus) {
            if (withdrawTournamentStatus === true) {
                setLoading(false)
                setWithdrawTournamentId('')
                setWithdrawModal(false)
                dispatch(getProfile(token))
                if (TournamentDetailModal === true) {
                    dispatch(getTournamentDetails(tourDeatailId, token))
                }
                if (subTab === '16') {
                    dispatch(getTournamentList(token, 'registered'))
                } else {
                    dispatch(getTournamentList(token))
                }
            }
            else if (withdrawTournamentStatus === false) {
                setLoading(false)
                setWithdrawTournamentId('')
                setWithdrawModal(false)
                dispatch(getTournamentList(token))
                if (TournamentDetailModal === true) {
                    dispatch(getTournamentDetails(tourDeatailId, token))
                }
            }
        }
        return () => {
            previousProps.withdrawTournamentStatus = withdrawTournamentStatus
        }
    }, [withdrawTournamentStatus])


    useEffect(() => {
        if (tournamentDetailsData) {
            setLoading(false)
            const data = tournamentDetailsData
            setRegistrationStartDate(data?.dJoiningStartAt)
            setRegistrationEndDate(data?.dJoiningEndAt)
            setTournamentStartDate(data?.dTournamentStartAt)
            setEntryFee(data?.oEntryFee?.nAmount)
            setPlayersJoined(data?.nJoined)
            setTotaSeat(data?.nTotalSeat)
            const seconds = Math.ceil(((data?.nStrategicTime) / 1000))
            setTimeoutSeconds(seconds)
            setCanJoin(data?.canJoin)
            setTourId(data?._id)
            setTotalPrize(data?.nTotalWinning)

            let roundArray = data?.aRoundInfo?.reverse()
            let roundPrizeArray = []
            let prizeArray = []

            const result = roundArray.map(item => {
                if (item?.aWinning[0]?.nAmount < 1) {
                    return { ...item, nPrize: 'No Prizes' }
                }
                return { ...item }
            })

            for (let i = 0; i < result?.length; i++) {
                let count = 0;
                for (let j = 0; j < result[i].aWinning.length; j++) {
                    let p = result[i].aWinning[j];
                    if (p.nAmount < 1) continue;
                    count++;
                    prizeArray.push({ nFrom: p?.nFrom, nTo: p?.nTo, nTotal: (p?.nTo - p?.nFrom) + 1, nAmount: p?.nAmount })
                }
                roundPrizeArray.push({ ...result[i], prizeCount: count })
            }
            setRoundsList(roundPrizeArray)
            setPrizesList(prizeArray)

        }
    }, [tournamentDetailsData])


    const toggleSubTab = tab => {
        if (subTab !== tab) setSubTab(tab);
    }


    function resetFilter() {
        setFilterToggle(false)
        setMaxPlayerFilter(true)
        setUpcomingChecked(false)
        setRegisteringChecked(false)
        setRegisteredChecked(false)
        setRunningChecked(false)
        dispatch(getTournamentList(token))
    }

    function handleJoinTournament() {
        setLoading(true)
        dispatch(joinTournament(joinTournamentId, token))
    }

    function handleWithdrawTournament() {
        setLoading(true)
        dispatch(withdrawTournament(withdrawTournamentId, token))
    }

    function dateFormat(date) {
        return moment(date).format("hh:mm A | DD MMMM")
    }

    function dateForTicket(date) {
        return moment(date).format("hh:mm A | DD MMM YYYY")
    }

    function kFormatter(num) {
        return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(2)) + 'k' : num?.toFixed(2)
    }

    function confirmSeat(tableId) {
        if (maintainance == false) {
            const top = window.top.outerHeight / 2 + window.top.screenY - (590 / 2)
            const left = window.top.outerWidth / 2 + window.top.screenX - (1079 / 2)
            window.open(`${config?.GAME_URL}?sAuthToken=${token}&sTableId=${socketTableId || tableId}&nChips=${chips}`, `${tableId}`, `popup=1, toolbar=0,status=0,width=1318,height=725, top=${top}, left=${left}`);
        } else if (maintainance == true) {
            setShowWarning(true)
        }

    }


    return (
        <div>
            {loading && <Loading />}

            <div className="tab-inner-header tournament-main-wrap">
                <Nav tabs className="">
                    <NavItem className={classnames({ active: subTab === '13' }, 'common-btn btn')} onClick={() => { toggleSubTab('13'); }}><span>All</span>
                    </NavItem>
                    <NavItem
                        className={classnames({ active: subTab === '14' }, 'common-btn btn')} onClick={() => { toggleSubTab('14'); }}><span>Cash</span>
                    </NavItem>
                    <NavItem
                        className={classnames({ active: subTab === '15' }, 'common-btn btn')} onClick={() => { toggleSubTab('15'); }}><span>Free roll</span>
                    </NavItem>
                    <NavItem
                        className={classnames({ active: subTab === '16' }, 'common-btn btn')} onClick={() => { toggleSubTab('16'); }}><span>Registered</span>
                    </NavItem>

                </Nav>

                <List type="unstyled" className="actions">
                    <li >
                        <button type="button" className='common-btn btn yellow-btn' onClick={myTicketModalTable}>
                            <span>My Tickets</span>
                        </button>
                    </li>
                    <li>
                        <a
                            onClick={() => setFilterToggle(filterToggle => !filterToggle)}
                        // className={filterToggle ? "common-btn yellow-btn" : "common-btn"}
                        >
                            <img src={filter} alt="filter" /></a>
                    </li>
                </List>

            </div>
            <div className={filterToggle ? " player-filter show" : " player-filter hide"}>

                {/* <div className="d-flex align-items-center"> */}
                <ul className="card card-body d-flex align-items-center">

                    <li>
                        <div className=" ">
                            <UncontrolledDropdown className=" ">
                                <DropdownToggle caret>
                                    Filter
                                </DropdownToggle>
                                <DropdownMenu>
                                    <div>
                                        <label htmlFor="chkboxUpcoming" className="d-flex align-items-center custom-checkbox ">
                                            <input readOnly id="chkboxUpcoming" checked={UpcomingChecked} type="checkbox" value="Upcoming" onClick={(e) => handleFilter(e, 'Upcoming')}
                                            />
                                            <span className="custom-control-label"> Upcoming</span>

                                        </label>
                                    </div>
                                    <div>
                                        <label htmlFor="chkboxRegistering" className="d-flex align-items-center custom-checkbox">

                                            <input readOnly id="chkboxRegistering" checked={RegisteringChecked} type="checkbox" value="Registering"
                                                onClick={(e) => handleFilter(e, 'Registering')} />
                                            <span className="custom-control-label">Registering</span>

                                        </label>

                                    </div>
                                    <div>
                                        <label htmlFor="chkboxRegistered" className="d-flex align-items-center custom-checkbox">
                                            <input readOnly id="chkboxRegistered" checked={RegisteredChecked} type="checkbox" value="Registered"
                                                onClick={(e) => handleFilter(e, 'Registered')} />
                                            <span className="custom-control-label">Registered</span>
                                        </label>
                                    </div>
                                    <div>
                                        <label htmlFor="chkboxRunning" className="d-flex align-items-center custom-checkbox">
                                            <input readOnly id="chkboxRunning" checked={RunningChecked} type="checkbox" value="Running"
                                                onClick={(e) => handleFilter(e, 'Running')} />
                                            <span className="custom-control-label">Running</span>
                                        </label>
                                    </div>

                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    </li>
                    <li>
                        <button type="button" className='reset' onClick={resetFilter}>
                            {/* <img src={refresh} alt="refresh" /> Reset */}
                            <img src={ResetIcon} alt="reset" />
                        </button>
                    </li>
                </ul>
                {/* </div> */}
            </div>
            <TabContent activeTab={subTab} className="tournament-tab-wrap">
                <TabPane tabId="13">
                    <Table responsive className={`common-table ${tournamentList?.length == 0 && 'empty-list'}`}>
                        <thead>
                            <tr>
                                <th width="20%" className="text-center text-capitalize">name</th>
                                <th width="25%%" className="text-center text-capitalize">Entry fees</th>
                                <th width="9%%" className="text-center text-capitalize"> Entries</th>
                                <th width="16%%" className="text-center text-capitalize"> Prize</th>
                                <th width="25%%" className="text-center text-capitalize"> Start</th>
                                <th width="5%%" className="text-center text-capitalize"> Status</th>
                                {/* <th width="20%" className="text-capitalize">
                                    <div className="th-actions">
                                        <span> max players </span>
                                        <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending("nMaxPlayer")} ><img src={upArrow} alt="up-arrow" /></button>
                                        <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending("nMaxPlayer")} ><img src={downArrow} alt="down-arrow" /></button>

                                    </div>
                                </th>
                                <th width="20%" className="text-capitalize">
                                    <div className="th-actions">
                                        <span>Point</span>
                                        <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending("nTablePoint")} ><img src={upArrow} alt="up-arrow" /></button>
                                        <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending("nTablePoint")} ><img src={downArrow} alt="down-arrow" /></button>

                                    </div>
                                </th> */}

                                {/* <th width="20%" className="text-capitalize">
                                    <div className="th-actions">
                                        <span> min entry</span>
                                        <button type="button" className="border-0 bg-transparent" onClick={() => handleAscending("nTableFee")} ><img src={upArrow} alt="up-arrow" /></button>
                                        <button type="button" className="border-0 bg-transparent" onClick={() => handleDescending("nTableFee")} ><img src={downArrow} alt="down-arrow" /></button>

                                    </div>
                                </th> */}
                                {/* <th width="15%" className="text-capitalize"></th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {tournamentList?.length ? tournamentList.map((tl, i) => (
                                <tr key={i}>
                                    <td width="16%" className='text-capitalize' onClick={() => tournamentDetailModalTable(tl?._id, tl?.sName, tl?.nWinTicketCount, tl?.oParticipantData, tl?.eState, tl?.nParticipantPosition)} style={{ "cursor": "pointer" }}>
                                        {tl?.sName}
                                    </td>
                                    <td width="20%">
                                        {tl?.oEntryFee?.nAmount === 0 ? 'Free' : `₹${tl?.oEntryFee?.nAmount}`}
                                    </td>
                                    <td width="18%">
                                        {tl?.nJoined > tl?.nTotalSeat ? `${tl?.nTotalSeat}/${tl?.nTotalSeat}` : `${tl?.nJoined}/${tl?.nTotalSeat}`}
                                        {tl?.nJoined > tl?.nTotalSeat && <p className='wait-list'>Waitlist: {tl?.nJoined - tl?.nTotalSeat}</p>}
                                        {/* {tl?.nJoined}/{tl?.nTotalSeat} */}
                                    </td>
                                    <td width="16%">
                                        &#8377;{tl?.nTotalWinning && kFormatter(tl?.nTotalWinning)}&nbsp;&nbsp;
                                        {tl?.nWinTicketCount > 0 &&
                                            <span>
                                                + {tl?.nWinTicketCount}
                                                &nbsp;
                                                <img
                                                    src={TicketIcon}
                                                    alt="ticket-icon"
                                                />
                                            </span>
                                        }
                                    </td>
                                    <td width="25%">
                                        {dateFormat(tl?.dTournamentStartAt)}
                                    </td>
                                    {tl?.canJoin && (tl?.nJoined < tl?.nTotalSeat) ?
                                        <td width="5%">
                                            <button type="button" className="yellow-btn" onClick={() => registerModalToggle(tl?._id)}>
                                                <span className="main-layer">
                                                    Register
                                                </span>
                                            </button>
                                        </td>
                                        :
                                        tl?.canJoin && (tl?.nJoined >= tl?.nTotalSeat) ?
                                            <td width="5%">
                                                <button type="button" className="yellow-btn" onClick={() => registerModalToggle(tl?._id, true)}>
                                                    <span className="main-layer">
                                                        Waitlist
                                                    </span>
                                                </button>
                                            </td>
                                            :
                                            tl?.canJoin === false && (tl?.eState === 'scheduled' || tl?.eState === "upcoming") ?
                                                <td width="5%" className=''>
                                                    <button type="button" className="upcoming-btn">
                                                        <span>Scheduled</span>
                                                    </button>
                                                </td>
                                                :
                                                (playing && playingTournamentId === tl?._id) || (tl?.oParticipantData?.eStatus == 'playing' && tl?.nParticipantPosition !== 0) ?
                                                    <td width="5%">
                                                        <button type="button" className="yellow-btn" onClick={() => confirmSeat(tl?.oParticipantData?.iTableId)}>
                                                            <span className="main-layer">
                                                                Take Seat
                                                            </span>
                                                        </button>
                                                    </td>
                                                    :
                                                    // tl?.canJoin === false && tl?.oParticipantData?.eStatus == 'finished' ?
                                                    //     <td width="5%" className=''>
                                                    //         <button type="button" className="upcoming-btn">
                                                    //             <span>Finished</span>
                                                    //         </button>
                                                    //     </td>
                                                    //     :
                                                    tl?.canJoin === false && tl?.eState === 'running' ?
                                                        <td width="5%" className=''>
                                                            <button type="button" className="upcoming-btn">
                                                                <span>Running</span>
                                                            </button>
                                                        </td>
                                                        :
                                                        <td width="5%">
                                                            <button type="button" className="yellow-btn" onClick={() => withdrawModalToggle(tl?._id)}>
                                                                <span className="main-layer">
                                                                    Withdraw
                                                                </span>
                                                            </button>
                                                        </td>
                                    }
                                </tr>
                            )) :

                                <tr className='no-data-wrap'>
                                    <td colSpan="6" className="text-center no-data">
                                        <img src={trofy} alt="trofy" />
                                        <h4> Currently, No tournaments available in this section </h4>
                                    </td>
                                </tr>}

                            {/* <tr>
                                <td
                                    width="20%"
                                    // data-toggle="modal"
                                    onClick={tournamentDetailModalTable}

                                >
                                    Tournament Name 1
                                </td>
                                <td
                                    width="25%"
                                    // data-toggle="modal"
                                    onClick={tournamentDetailModalTable}

                                >
                                    ₹ 60/ 10{" "}
                                    <span>
                                        <img
                                            src={TicketIcon}
                                            alt="ticket-icon"
                                        />
                                    </span>
                                </td>
                                <td
                                    width="20%"
                                    // data-toggle="modal"
                                    onClick={tournamentDetailModalTable}

                                >
                                    50/100
                                </td>
                                <td
                                    width="5%"
                                    // data-toggle="modal"
                                    onClick={tournamentDetailModalTable}

                                >
                                    0.63
                                </td>
                                <td
                                    width="25%"
                                    // data-toggle="modal"
                                    onClick={tournamentDetailModalTable}

                                >
                                    Today @ 5:00 pm
                                </td>
                                <td width="5%">
                                    <button
                                        type="button"
                                        className="common-outline-btn"
                                        // data-toggle="modal"
                                        onClick={tournamentDetailModalTable}

                                    >
                                        <span className="bg-layer">
                                            <span className="bg-layer-inner"></span>
                                        </span>
                                        <span className="main-layer">
                                            Registered
                                        </span>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td
                                    width="20%"
                                // data-toggle="modal"

                                >
                                    Tournament Name 1
                                </td>
                                <td
                                    width="25%"
                                // data-toggle="modal"

                                >
                                    Free
                                </td>
                                <td
                                    width="20%"
                                // data-toggle="modal"

                                >
                                    50/100
                                </td>
                                <td
                                    width="5%"
                                // data-toggle="modal"

                                >
                                    0.63
                                </td>
                                <td
                                    width="25%"
                                // data-toggle="modal"

                                >
                                    Today @ 5:00 pm
                                </td>
                                <td
                                    width="5%"
                                // data-toggle="modal"

                                >
                                    <button
                                        type="button"
                                        className="yellow-btn"
                                    >
                                        <span className="main-layer">
                                            Register
                                        </span>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td width="20%">Tournament Name 1</td>
                                <td width="25%">
                                    ₹ 60/ 10{" "}
                                    <span>
                                        <img
                                            src={TicketIcon}
                                            alt="ticket-icon"
                                        />
                                    </span>
                                </td>
                                <td width="20%">50/100</td>
                                <td width="5%">0.63</td>
                                <td width="25%">Today @ 5:00 pm</td>
                                <td width="5%">
                                    <button
                                        type="button"
                                        className="upcoming-btn"
                                    >
                                        <span>Upcoming</span>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td width="20%">Tournament Name 1</td>
                                <td width="25%">
                                    ₹ 60/ 10{" "}
                                    <span>
                                        <img
                                            src={TicketIcon}
                                            alt="ticket-icon"
                                        />
                                    </span>
                                </td>
                                <td width="20%">50/100</td>
                                <td width="5%">0.63</td>
                                <td width="25%">Today @ 5:00 pm</td>
                                <td width="5%">
                                    <button
                                        type="button"
                                        className="common-outline-btn"
                                    >
                                        <span className="bg-layer">
                                            <span className="bg-layer-inner"></span>
                                        </span>
                                        <span className="main-layer">
                                            Registered
                                        </span>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td width="20%">Tournament Name 1</td>
                                <td width="25%">
                                    ₹ 60/ 10{" "}
                                    <span>
                                        <img
                                            src={TicketIcon}
                                            alt="ticket-icon"
                                        />
                                    </span>
                                </td>
                                <td width="20%">50/100</td>
                                <td width="5%">0.63</td>
                                <td width="25%">Today @ 5:00 pm</td>
                                <td width="5%">
                                    <button
                                        type="button"
                                        className="common-outline-btn"
                                    >
                                        <span className="bg-layer">
                                            <span className="bg-layer-inner"></span>
                                        </span>
                                        <span className="main-layer">
                                            Registered
                                        </span>
                                    </button>
                                </td>
                            </tr> */}
                        </tbody>
                    </Table>
                </TabPane>
                <TabPane tabId="14">
                    <Table responsive className={`common-table ${tournamentList?.length == 0 && 'empty-list'}`}>
                        <thead>
                            <tr>
                                <th width="20%" className="text-center text-capitalize">name</th>
                                <th width="25%%" className="text-center text-capitalize">Entry fees</th>
                                <th width="9%%" className="text-center text-capitalize"> Entries</th>
                                <th width="16%%" className="text-center text-capitalize"> Prize</th>
                                <th width="25%%" className="text-center text-capitalize"> Start</th>
                                <th width="5%%" className="text-center text-capitalize"> Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tournamentList?.length ? tournamentList.map((tl, i) => (
                                <tr key={i}>
                                    <td width="16%" className='text-capitalize' onClick={() => tournamentDetailModalTable(tl?._id, tl?.sName, tl?.nWinTicketCount, tl?.oParticipantData, tl?.eState, tl?.nParticipantPosition)} style={{ "cursor": "pointer" }}>
                                        {tl?.sName}
                                    </td>
                                    <td width="20%" className='text-capitalize'>
                                        {tl?.oEntryFee?.nAmount === 0 ? 'Free' : `₹${tl?.oEntryFee?.nAmount}`}
                                    </td>
                                    <td width="18%" className='text-capitalize'>
                                        {tl?.nJoined > tl?.nTotalSeat ? `${tl?.nTotalSeat}/${tl?.nTotalSeat}` : `${tl?.nJoined}/${tl?.nTotalSeat}`}
                                        {tl?.nJoined > tl?.nTotalSeat && <p className='wait-list'>Waitlist: {tl?.nJoined - tl?.nTotalSeat}</p>}
                                        {/* {tl?.nJoined}/{tl?.nTotalSeat} */}
                                    </td>
                                    <td width="16%" className='text-capitalize'>
                                        &#8377;{tl?.nTotalWinning && kFormatter(tl?.nTotalWinning)}&nbsp;&nbsp;
                                        {tl?.nWinTicketCount > 0 &&
                                            <span>
                                                + {tl?.nWinTicketCount}
                                                &nbsp;
                                                <img
                                                    src={TicketIcon}
                                                    alt="ticket-icon"
                                                />
                                            </span>
                                        }
                                    </td>
                                    <td width="25%" className='text-capitalize'>
                                        {dateFormat(tl?.dTournamentStartAt)}
                                    </td>
                                    {tl?.canJoin && (tl?.nJoined < tl?.nTotalSeat) ?
                                        <td width="5%">
                                            <button type="button" className="yellow-btn" onClick={() => registerModalToggle(tl?._id)}>
                                                <span className="main-layer">
                                                    Register
                                                </span>
                                            </button>
                                        </td>
                                        :
                                        tl?.canJoin && (tl?.nJoined >= tl?.nTotalSeat) ?
                                            <td width="5%">
                                                <button type="button" className="yellow-btn" onClick={() => registerModalToggle(tl?._id, true)}>
                                                    <span className="main-layer">
                                                        Waitlist
                                                    </span>
                                                </button>
                                            </td>
                                            :
                                            tl?.canJoin === false && (tl?.eState === 'scheduled' || tl?.eState === "upcoming") ?
                                                <td width="5%" className=''>
                                                    <button type="button" className="upcoming-btn">
                                                        <span>Scheduled</span>
                                                    </button>
                                                </td>
                                                :
                                                (playing && playingTournamentId === tl?._id) || (tl?.oParticipantData?.eStatus == 'playing' && tl?.nParticipantPosition !== 0) ?
                                                    <td width="5%">
                                                        <button type="button" className="yellow-btn" onClick={() => confirmSeat(tl?.oParticipantData?.iTableId)}>
                                                            <span className="main-layer">
                                                                Take Seat
                                                            </span>
                                                        </button>
                                                    </td>
                                                    :
                                                    // tl?.canJoin === false && tl?.oParticipantData?.eStatus == 'finished' ?
                                                    //     <td width="5%" className=''>
                                                    //         <button type="button" className="upcoming-btn">
                                                    //             <span>Finished</span>
                                                    //         </button>
                                                    //     </td>
                                                    //     :
                                                    tl?.canJoin === false && tl?.eState === 'running' ?
                                                        <td width="5%" className=''>
                                                            <button type="button" className="upcoming-btn">
                                                                <span>Running</span>
                                                            </button>
                                                        </td>
                                                        :
                                                        <td width="5%">
                                                            <button type="button" className="yellow-btn" onClick={() => withdrawModalToggle(tl?._id)}>
                                                                <span className="main-layer">
                                                                    Withdraw
                                                                </span>
                                                            </button>
                                                        </td>
                                    }
                                </tr>
                            )) :
                                <tr className='no-data-wrap'>
                                    <td colSpan="6" className="text-center no-data">
                                        <img src={trofy} alt="trofy" />
                                        <h4> Currently, No tournaments available in this section </h4>
                                    </td>
                                </tr>}
                        </tbody>
                    </Table>
                </TabPane>
                <TabPane tabId="15">
                    <Table responsive className={`common-table ${tournamentList?.length == 0 && 'empty-list'}`}>
                        <thead>
                            <tr>
                                <th width="20%" className="text-center text-capitalize">name</th>
                                <th width="25%" className="text-center text-capitalize">Entry fees</th>
                                <th width="9%" className="text-center text-capitalize"> Entries</th>
                                <th width="16%" className="text-center text-capitalize"> Prize</th>
                                <th width="25%" className="text-center text-capitalize"> Start</th>
                                <th width="5%" className="text-center text-capitalize"> Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tournamentList?.length ? tournamentList.map((tl, i) => (
                                <tr key={i}>
                                    <td width="16%" className='text-capitalize' onClick={() => tournamentDetailModalTable(tl?._id, tl?.sName, tl?.nWinTicketCount, tl?.oParticipantData, tl?.eState, tl?.nParticipantPosition)} style={{ "cursor": "pointer" }}>
                                        {tl?.sName}
                                    </td>
                                    <td width="20%" className='text-capitalize'>
                                        {tl?.oEntryFee?.nAmount === 0 ? 'Free' : `₹${tl?.oEntryFee?.nAmount}`}
                                    </td>
                                    <td width="18%" className='text-capitalize'>
                                        {tl?.nJoined > tl?.nTotalSeat ? `${tl?.nTotalSeat}/${tl?.nTotalSeat}` : `${tl?.nJoined}/${tl?.nTotalSeat}`}
                                        {tl?.nJoined > tl?.nTotalSeat && <p className='wait-list'>Waitlist: {tl?.nJoined - tl?.nTotalSeat}</p>}
                                        {/* {tl?.nJoined}/{tl?.nTotalSeat} */}
                                    </td>
                                    <td width="16%" className='text-capitalize'>
                                        &#8377;{tl?.nTotalWinning && kFormatter(tl?.nTotalWinning)}
                                        {tl?.nWinTicketCount > 0 &&
                                            <span>
                                                + {tl?.nWinTicketCount}
                                                &nbsp;
                                                <img
                                                    src={TicketIcon}
                                                    alt="ticket-icon"
                                                />
                                            </span>
                                        }
                                    </td>
                                    <td width="25%" className='text-capitalize'>
                                        {dateFormat(tl?.dTournamentStartAt)}
                                    </td>
                                    {tl?.canJoin && (tl?.nJoined < tl?.nTotalSeat) ?
                                        <td width="5%">
                                            <button type="button" className="yellow-btn" onClick={() => registerModalToggle(tl?._id)}>
                                                <span className="main-layer">
                                                    Register
                                                </span>
                                            </button>
                                        </td>
                                        :
                                        tl?.canJoin && (tl?.nJoined >= tl?.nTotalSeat) ?
                                            <td width="5%">
                                                <button type="button" className="yellow-btn" onClick={() => registerModalToggle(tl?._id, true)}>
                                                    <span className="main-layer">
                                                        Waitlist
                                                    </span>
                                                </button>
                                            </td>
                                            :
                                            tl?.canJoin === false && (tl?.eState === 'scheduled' || tl?.eState === "upcoming") ?
                                                <td width="5%" className=''>
                                                    <button type="button" className="upcoming-btn">
                                                        <span>Scheduled</span>
                                                    </button>
                                                </td>
                                                :
                                                (playing && playingTournamentId === tl?._id) || (tl?.oParticipantData?.eStatus == 'playing' && tl?.nParticipantPosition !== 0) ?
                                                    <td width="5%">
                                                        <button type="button" className="yellow-btn" onClick={() => confirmSeat(tl?.oParticipantData?.iTableId)}>
                                                            <span className="main-layer">
                                                                Take Seat
                                                            </span>
                                                        </button>
                                                    </td>
                                                    :
                                                    // tl?.canJoin === false && tl?.oParticipantData?.eStatus == 'finished' ?
                                                    //     <td width="5%" className=''>
                                                    //         <button type="button" className="upcoming-btn">
                                                    //             <span>Finished</span>
                                                    //         </button>
                                                    //     </td>
                                                    //     :
                                                    tl?.canJoin === false && tl?.eState === 'running' ?
                                                        <td width="5%" className=''>
                                                            <button type="button" className="upcoming-btn">
                                                                <span>Running</span>
                                                            </button>
                                                        </td>
                                                        :
                                                        <td width="5%">
                                                            <button type="button" className="yellow-btn" onClick={() => withdrawModalToggle(tl?._id)}>
                                                                <span className="main-layer">
                                                                    Withdraw
                                                                </span>
                                                            </button>
                                                        </td>
                                    }
                                </tr>
                            )) :
                                <tr className='no-data-wrap'>
                                    <td colSpan="6" className="text-center no-data">
                                        <img src={trofy} alt="trofy" />
                                        <h4> Currently, No tournaments available in this section </h4>
                                    </td>
                                </tr>}
                        </tbody>
                    </Table>
                </TabPane>
                <TabPane tabId="16">
                    <Table responsive className={`common-table ${tournamentList?.length == 0 && 'empty-list'}`}>
                        <thead>
                            <tr>
                                <th width="20%" className="text-center text-capitalize">name</th>
                                <th width="25%" className="text-center text-capitalize">Entry fees</th>
                                <th width="9%" className="text-center text-capitalize"> Entries</th>
                                <th width="16%" className="text-center text-capitalize"> Prize</th>
                                <th width="25%" className="text-center text-capitalize"> Start</th>
                                <th width="5%" className="text-center text-capitalize"> Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tournamentList?.length ? tournamentList.map((tl, i) => (
                                <tr key={i}>
                                    <td width="16%" className='text-capitalize' onClick={() => tournamentDetailModalTable(tl?._id, tl?.sName, tl?.nWinTicketCount, tl?.oParticipantData, tl?.eState, tl?.nParticipantPosition)} style={{ "cursor": "pointer" }}>
                                        {tl?.sName}
                                    </td>
                                    <td width="20%" className='text-capitalize'>
                                        {tl?.oEntryFee?.nAmount === 0 ? 'Free' : `₹${tl?.oEntryFee?.nAmount}`}
                                    </td>
                                    <td width="18%" className='text-capitalize'>
                                        {tl?.nJoined > tl?.nTotalSeat ? `${tl?.nTotalSeat}/${tl?.nTotalSeat}` : `${tl?.nJoined}/${tl?.nTotalSeat}`}
                                        {tl?.nJoined > tl?.nTotalSeat && <p className='wait-list'>Waitlist: {tl?.nJoined - tl?.nTotalSeat}</p>}
                                        {/* {tl?.nJoined}/{tl?.nTotalSeat} */}
                                    </td>
                                    <td width="16%" className='text-capitalize'>
                                        &#8377;{tl?.nTotalWinning && kFormatter(tl?.nTotalWinning)}&nbsp;&nbsp;
                                        {tl?.nWinTicketCount > 0 &&
                                            <span>
                                                + {tl?.nWinTicketCount}
                                                &nbsp;
                                                <img
                                                    src={TicketIcon}
                                                    alt="ticket-icon"
                                                />
                                            </span>
                                        }
                                    </td>
                                    <td width="25%" className='text-capitalize'>
                                        {dateFormat(tl?.dTournamentStartAt)}
                                    </td>
                                    {(playing && playingTournamentId === tl?._id) || (tl?.oParticipantData?.eStatus == 'playing' && tl?.nParticipantPosition !== 0) ?
                                        <td width="5%">
                                            <button type="button" className="yellow-btn" onClick={() => confirmSeat(tl?.oParticipantData?.iTableId)}>
                                                <span className="main-layer">
                                                    Take Seat
                                                </span>
                                            </button>
                                        </td>
                                        :
                                        // tl?.oParticipantData?.eStatus == 'finished' ?
                                        //     <td width="5%" className=''>
                                        //         <button type="button" className="upcoming-btn">
                                        //             <span>Finished</span>
                                        //         </button>
                                        //     </td>
                                        //     :
                                        tl?.canJoin === false && tl?.eState === 'running' ?
                                            <td width="5%" className=''>
                                                <button type="button" className="upcoming-btn">
                                                    <span>Running</span>
                                                </button>
                                            </td>
                                            :
                                            <td width="5%">
                                                <button type="button" className="yellow-btn" onClick={() => withdrawModalToggle(tl?._id)}>
                                                    <span className="main-layer">
                                                        Withdraw
                                                    </span>
                                                </button>
                                            </td>
                                    }



                                    {/* {tl?.eState === 'registered' ?
                                        <td width="5%">
                                            <button type="button" className="yellow-btn" onClick={() => withdrawModalToggle(tl?._id)}>
                                                <span className="main-layer">
                                                    Withdraw
                                                </span>
                                            </button>
                                        </td>
                                        :
                                        playing || tl?.oParticipantData?.eStatus == 'playing' ?
                                            <td width="5%">
                                                <button type="button" className="yellow-btn" onClick={() => confirmSeat(tl?.oParticipantData?.iTableId)}>
                                                    <span className="main-layer">
                                                        Take A Seat
                                                    </span>
                                                </button>
                                            </td>
                                            :
                                            tl?.oParticipantData?.eStatus == 'finished' ?
                                                <td width="5%" className=''>
                                                    <button type="button" className="upcoming-btn">
                                                        <span>Finished</span>
                                                    </button>
                                                </td>
                                                :
                                                tl?.canJoin === false && tl?.eState === 'running' ?
                                                    <td width="5%" className=''>
                                                        <button type="button" className="upcoming-btn">
                                                            <span>Running</span>
                                                        </button>
                                                    </td>
                                                    :
                                                    <td width="5%">
                                                        <button type="button" className="upcoming-btn">
                                                            <span>Scheduled</span>
                                                        </button>
                                                    </td>
                                                    } */}
                                </tr>
                            )) :
                                <tr className='no-data-wrap'>
                                    <td colSpan="6" className="text-center no-data">
                                        <img src={trofy} alt="trofy" />
                                        <h4> Currently, No tournaments available in this section </h4>
                                    </td>
                                </tr>}
                        </tbody>
                    </Table>
                </TabPane>
            </TabContent>

            <Modal isOpen={TournamentDetailModal} toggle={tournamentDetailModalTable} className='modal-dialog-centered registerModal tournamentModal'>
                <ModalHeader toggle={tournamentDetailModalTable} className="">
                    <div className="d-flex align-items-center justify-content-center">
                        <h3 className="modal-title text-center text-uppercase " id="exampleModalLongTitle12">
                            {tournamentName}
                            {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}

                        </h3>
                        <span className='modal-id'>ID: #{tournamentId}</span>
                    </div>

                </ModalHeader>


                <ModalBody className="" >
                    <div className="tournament-outer-box">
                        <div className="tournament-inner-box">
                            <div className="register-heading-box">
                                <div className="row">
                                    <div className="col-xl-5">
                                        <div className="timing-wrap d-flex align-items-start">
                                            <div className="img-box">
                                                <img src={TimingIcon} alt="icon" />
                                            </div>
                                            <div>
                                                <h3>Tournament Timings</h3>
                                                <div>
                                                    <p>Reg. Start @ {dateFormat(registrationStartDate)} </p>
                                                    <p>Reg. End @ {dateFormat(registrationEndDate)} </p>
                                                    <p>Starts @ {dateFormat(tournamentStartDate)} </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3">
                                        <div className="timing-wrap d-flex align-items-start justify-content-left">
                                            <div className="img-box">
                                                <img src={FeeTicketIcon} alt="icon" />
                                            </div>
                                            <div>
                                                <h3>Entry Fee</h3>
                                                <div className="d-flex flex-wrap">
                                                    <p>{entryFee === 0 ? 'Free' : `₹${entryFee}`}</p>
                                                    {/* <img src={TicketIcon} alt="ticket" /> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4">
                                        <div className="timing-wrap d-flex align-items-start">
                                            <div className="img-box">
                                                <img src={PlayerJoinedIcon} alt="icon" />
                                            </div>
                                            <div>
                                                <h3>Player Joined</h3>
                                                <div>
                                                    <p>{playersJoined > totaSeat ? `${totaSeat}/${totaSeat}` : `${playersJoined}/${totaSeat}`}</p>
                                                    {playersJoined > totaSeat && <p>Waitlist: {playersJoined - totaSeat}</p>}
                                                    {/* <p>{playersJoined}/{totaSeat}</p> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Register heading box ends --> */}
                            <div className="game-detail-wrap">
                                <div className="game-title-wrap d-flex">
                                    <div className="game-detail-left-box text-left">
                                        <h3>Game Rounds</h3>
                                    </div>
                                    <div className="game-detail-right-box text-left">
                                        <h3>Prizes</h3>
                                    </div>
                                </div>
                                <div className="game-detail-box d-flex align-items-top">
                                    <div className="game-detail-left-box text-left ">
                                        {!!roundsList?.length && roundsList?.map((round, i) => {
                                            return (
                                                <div className="game-detail-blue-bg custom-shape-box d-flex align-items-center justify-content-between" key={i}>
                                                    <div className="outer-box">
                                                        <div className="inner-box">
                                                            <div className="inner-blue-bg ">
                                                                <p className="yellow-txt text-center">R{round?.nRound}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex game-round-inner-box align-items-center">
                                                        {/* <p>5</p> <img src={CupIcon} alt="trofy-icon" /> */}
                                                        {round?.nPrize ?
                                                            <p>{round?.nPrize}</p> :
                                                            <p>{round?.prizeCount} Prizes</p>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        {/* <div className="game-detail-blue-bg custom-shape-box d-flex align-items-center justify-content-between">
                                            <div className="outer-box">
                                                <div className="inner-box">
                                                    <div className="inner-blue-bg ">
                                                        <p className="yellow-txt text-center">3</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex game-round-inner-box align-items-center">
                                                <p>5</p> <img src={CupIcon} alt="trofy-icon" />
                                            </div>
                                        </div>
                                        <div className="game-detail-blue-bg custom-shape-box d-flex align-items-center justify-content-between">
                                            <div className="outer-box">
                                                <div className="inner-box">
                                                    <div className="inner-blue-bg ">
                                                        <p className="yellow-txt text-center">3</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex game-round-inner-box align-items-center">
                                                <p>5</p> <img src={CupIcon} alt="trofy-icon" />
                                            </div>
                                        </div>
                                        <div className="game-detail-blue-bg custom-shape-box d-flex align-items-center justify-content-between">
                                            <div className="outer-box">
                                                <div className="inner-box">
                                                    <div className="inner-blue-bg ">
                                                        <p className="yellow-txt text-center">3</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex game-round-inner-box align-items-center">
                                                <p>5</p> <img src={CupIcon} alt="trofy-icon" />
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="game-detail-right-box text-left">
                                        {!!prizesList?.length && prizesList?.map((prize, i) => (

                                            <div className="prize-row" key={i}>
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        {" "}
                                                        <div className="">
                                                            {" "}
                                                            <p>{prize?.nFrom == 1 ? prize?.nFrom + "st" : prize?.nFrom == 2 ? prize?.nFrom + "nd" : prize?.nFrom == 3 ? prize?.nFrom + "rd" : prize?.nFrom + "th"}</p>{" "}
                                                        </div>{" "}
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <img src={CupIcon} alt="trofy" />
                                                            <p className="yellow-txt">{prize?.nTotal} Player(s) ({prize?.nFrom}-{prize?.nTo})</p>{" "}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="d-flex text-left align-items-center justify-content-end">
                                                            <p>₹{prize?.nAmount && kFormatter(prize?.nAmount)}</p>{" "}
                                                            {/* <img src={TicketIcon} alt="ticket" /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                        {/* <div className="prize-row">
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    {" "}
                                                    <div className="">
                                                        {" "}
                                                        <p>1st</p>{" "}
                                                    </div>{" "}
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <p className="yellow-txt">1</p>{" "}
                                                        <img src={CupIcon} alt="trofy" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="d-flex text-left align-items-center justify-content-end">
                                                        <p>₹ 200 + 2 </p>{" "}
                                                        <img src={TicketIcon} alt="ticket" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="prize-row">
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    {" "}
                                                    <div className="">
                                                        {" "}
                                                        <p>2nd</p>{" "}
                                                    </div>{" "}
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <p className="yellow-txt">1</p>{" "}
                                                        <img src={CupIcon} alt="trofy" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="d-flex text-left align-items-center justify-content-end">
                                                        <p>₹ 100 + 1 </p>{" "}
                                                        <img src={TicketIcon} alt="ticket" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="prize-row">
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    {" "}
                                                    <div className="">
                                                        {" "}
                                                        <p>3rd</p>{" "}
                                                    </div>{" "}
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <p className="yellow-txt">1</p>{" "}
                                                        <img src={CupIcon} alt="trofy" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="d-flex text-left align-items-center justify-content-end">
                                                        <p>₹ 80 + 1 </p>{" "}
                                                        <img src={TicketIcon} alt="ticket" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="prize-row">
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    {" "}
                                                    <div className="">
                                                        {" "}
                                                        <p>4th</p>{" "}
                                                    </div>{" "}
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <p className="yellow-txt">1</p>{" "}
                                                        <img src={CupIcon} alt="trofy" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="d-flex text-left align-items-center justify-content-end">
                                                        <p>₹ 80 + 1 </p>{" "}
                                                        <img src={TicketIcon} alt="ticket" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="prize-row">
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    {" "}
                                                    <div className="">
                                                        {" "}
                                                        <p>4th</p>{" "}
                                                    </div>{" "}
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <p className="yellow-txt">1</p>{" "}
                                                        <img src={CupIcon} alt="trofy" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="d-flex text-left align-items-center justify-content-end">
                                                        <p>₹ 80 + 1 </p>{" "}
                                                        <img src={TicketIcon} alt="ticket" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="prize-row">
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    {" "}
                                                    <div className="">
                                                        {" "}
                                                        <p>4th</p>{" "}
                                                    </div>{" "}
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <p className="yellow-txt">1</p>{" "}
                                                        <img src={CupIcon} alt="trofy" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="d-flex text-left align-items-center justify-content-end">
                                                        <p>₹ 80 + 1 </p>{" "}
                                                        <img src={TicketIcon} alt="ticket" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="tournament-btn-wrap d-flex align-items-center justify-content-end">
                                    <div className="blue-bg d-flex flex-wrap">
                                        <h3>Total Prize : ₹{totalPrize && kFormatter(totalPrize)}</h3>{" "}&nbsp;&nbsp;
                                        {ticket > 0 &&
                                            <h3>
                                                + {ticket}
                                                &nbsp;
                                                <img src={TicketIcon} alt="ticket-icon" />
                                            </h3>
                                        }

                                        {/* <img src={TicketIcon} alt="ticket" /> */}
                                    </div>

                                    <div className="new-create-box text-center">
                                        {/* {canJoin && (playersJoined < totaSeat) ?
                                            <button type="button" onClick={() => registerModalToggle(tourId)} className="btn theme-orange-btn">
                                                <span>Register</span>
                                            </button>
                                            :
                                            canJoin && (playersJoined > totaSeat) ?
                                                    <button type="button" onClick={() => registerModalToggle(tourId)} className="btn theme-orange-btn">
                                                        <span>Waitlist</span>
                                                    </button>
                                                :
                                                canJoin === false && (eState === 'scheduled' || eState === "upcoming") ?
                                                    <div className="tournament-tab-wrap">
                                                        <button type="button" className="upcoming-btn">
                                                            <span className="main-layer">
                                                                Scheduled
                                                            </span>
                                                        </button>
                                                    </div>
                                                    :
                                                    canJoin === false && eState === 'running' ?
                                                        <div className="tournament-tab-wrap">
                                                            <button type="button" className="upcoming-btn">
                                                                <span className="main-layer">
                                                                    Running
                                                                </span>
                                                            </button>
                                                        </div>
                                                        :
                                                        <button type="button" onClick={() => withdrawModalToggle(tourId)} className="btn theme-orange-btn">
                                                            <span>Withdraw</span>
                                                        </button>
                                        } */}



                                        {canJoin && (playersJoined < totaSeat) ?
                                            <button type="button" onClick={() => registerModalToggle(tourId)} className="btn theme-orange-btn">
                                                <span>Register</span>
                                            </button>
                                            :
                                            canJoin && (playersJoined >= totaSeat) ?
                                                <button type="button" onClick={() => registerModalToggle(tourId, true)} className="btn theme-orange-btn">
                                                    <span>Waitlist</span>
                                                </button>
                                                :
                                                canJoin === false && (eState === 'scheduled' || eState === "upcoming") ?
                                                    <div className="tournament-tab-wrap">
                                                        <button type="button" className="upcoming-btn">
                                                            <span className="main-layer">
                                                                Scheduled
                                                            </span>
                                                        </button>
                                                    </div>
                                                    :
                                                    playing || (participantData?.eStatus == 'playing' && participantPosition !== 0) ?
                                                        <button type="button" className="yellow-btn" onClick={() => confirmSeat(participantData?.iTableId)}>
                                                            <span className="main-layer">
                                                                Take Seat
                                                            </span>
                                                        </button>
                                                        :
                                                        // canJoin === false && participantData?.eStatus == 'finished' ?
                                                        //     <div className="tournament-tab-wrap">
                                                        //         <button type="button" className="upcoming-btn">
                                                        //             <span className="main-layer">
                                                        //                 Finished
                                                        //             </span>
                                                        //         </button>
                                                        //     </div>
                                                        //     :
                                                        canJoin === false && eState === 'running' ?
                                                            <div className="tournament-tab-wrap">
                                                                <button type="button" className="upcoming-btn">
                                                                    <span className="main-layer">
                                                                        Running
                                                                    </span>
                                                                </button>
                                                            </div>
                                                            :
                                                            <button type="button" onClick={() => withdrawModalToggle(tourId)} className="btn theme-orange-btn">
                                                                <span>Withdraw</span>
                                                            </button>
                                        }
                                    </div>
                                </div>
                                <div className="tournament-inner-footer">
                                    <p className="text-center">There will be {timeoutSeconds} second(s) break between each tournament round. </p>
                                    <p className="text-center">
                                        Disclaimer : The number of prizes and rounds will change
                                        depending on number of players joining the tournament
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            {/* withdraw confirmation modal */}
            <Modal
                isOpen={withdrawTableModal}
                toggle={withdrawModalToggle}
                className="logout-modal modal-dialog-centered"
            >

                <div className="modal-body ">
                    <div className="txt-msg-box">
                        <p>Are you sure you want to withdraw from this tournament?</p>
                    </div>
                    <div className="new-create-box text-center">
                        <button
                            type="button"
                            className="btn theme-orange-btn"
                            onClick={handleWithdrawTournament}
                        >
                            <span>Yes</span>
                        </button>
                        <button
                            type="button"
                            className="btn theme-orange-btn close"
                            onClick={() => { withdrawModalToggle(); setWithdrawTournamentId('') }}
                        >
                            <span>No</span>
                        </button>
                    </div>
                </div>
            </Modal>

            {/* Register confirmation modal */}
            <Modal
                isOpen={registerModal}
                toggle={registerModalToggle}
                className="logout-modal modal-dialog-centered"
            >

                <div className="modal-body ">
                    <div className="txt-msg-box">
                        <p>Are you sure you want to register in this tournament {waitlist ? "in waitlist position" : ""} ?</p>
                    </div>
                    <div className="new-create-box text-center">
                        <button
                            type="button"
                            className="btn theme-orange-btn"
                            onClick={handleJoinTournament}
                        >
                            <span>Yes</span>
                        </button>
                        <button
                            type="button"
                            className="btn theme-orange-btn close"
                            onClick={() => { registerModalToggle(); setJoinTournamentId('') }}
                        >
                            <span>No</span>
                        </button>
                    </div>
                </div>
            </Modal>

            {/* my ticket modal starts */}
            <Modal isOpen={myTicketModal} toggle={myTicketModalTable} className='modal-dialog-centered modal-private-modal-wrap promo-offer-modal my-ticket-modal'>

                <ModalHeader toggle={myTicketModalTable} className="">
                    <div className="d-flex align-items-center justify-content-center">
                        <h3 className="modal-title text-center text-capitalize " id="exampleModalLongTitle12">My &nbsp;Tickets </h3>
                    </div>

                </ModalHeader>
                {/* <div className='modal-header' toggle={myTicketModalTable}>
                    <h3 className="modal-title text-center" id="exampleModalLongTitle">Private Table</h3>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true"><img src="../images/Close.png" alt="close" onClick={() => setmyTicketModal(!myTicketModal)} /></span>
                    </button>
                </div> */}

                <ModalBody >
                    <div className="panel with-nav-tabs panel-info my-ticket-wrap">
                        <div className="promo-wrap">
                            <div className="">
                                {myTickets?.length ?
                                    <Table responsive className="common-table">
                                        <thead>
                                            <tr>
                                                <th width="25%" className="text-center text-capitalize">Name</th>
                                                <th width="25%" className="text-center text-capitalize">Status</th>
                                                <th width="25%" className="text-center text-capitalize">Value</th>
                                                <th width="25%" className="text-center text-capitalize">Validity</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {myTickets.map((ticket, i) => (
                                                <tr key={i}>
                                                    <td width="25%">
                                                        <p className="text-center text-capitalize">{ticket?.sName} </p>
                                                    </td>
                                                    <td width="25%">
                                                        <p className="text-center text-capitalize">{ticket?.eState}</p>
                                                    </td>
                                                    <td width="25%">
                                                        <p className="text-center text-capitalize">₹{ticket?.nValue}</p>
                                                    </td>
                                                    <td width="25%">
                                                        <p className="text-center text-capitalize">{ticket.dExpireDate ? dateForTicket(ticket?.dExpireDate) : "Not Available"}</p>
                                                    </td>
                                                </tr>
                                            ))}

                                            {/* <tr>
                                            <td width="25%">
                                                <p>Extra discount </p>
                                            </td>
                                            <td width="25%">
                                                <p>FTD</p>
                                            </td>
                                            <td width="25%">
                                                <p>dummy1</p>
                                            </td>
                                            <td width="25%">
                                                <p>dummy1</p>
                                            </td>
                                        </tr> */}
                                            {/* tr completed */}
                                            {/* <tr>
                                            <td width="25%">
                                                <p>Extra discount </p>
                                            </td>
                                            <td width="25%">
                                                <p>FTD</p>
                                            </td>
                                            <td width="25%">
                                                <p>dummy1</p>
                                            </td>
                                            <td width="25%">
                                                <p>dummy1</p>
                                            </td>
                                        </tr> */}
                                            {/* tr completed */}
                                            {/* <tr>
                                            <td width="25%">
                                                <p>Extra discount </p>
                                            </td>
                                            <td width="25%">
                                                <p>FTD</p>
                                            </td>
                                            <td width="25%">
                                                <p>dummy1</p>
                                            </td>
                                            <td width="25%">
                                                <p>dummy1</p>
                                            </td>
                                        </tr> */}
                                            {/* tr completed */}
                                            {/* <tr>
                                            <td width="25%">
                                                <p>Extra discount </p>
                                            </td>
                                            <td width="25%">
                                                <p>FTD</p>
                                            </td>
                                            <td width="25%">
                                                <p>dummy1</p>
                                            </td>
                                            <td width="25%">
                                                <p>dummy1</p>
                                            </td>
                                        </tr> */}
                                            {/* tr completed */}
                                        </tbody>
                                    </Table>
                                    :
                                    <Table responsive className="common-table empty-list">
                                        <thead>
                                            <tr>
                                                <th width="25%" className="text-center text-capitalize">Name</th>
                                                <th width="25%" className="text-center text-capitalize">Status</th>
                                                <th width="25%" className="text-center text-capitalize">Value</th>
                                                <th width="25%" className="text-center text-capitalize">Validity</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td colSpan="6" className="text-center">
                                                    No tickets are found in your account!
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                }
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            <Modal isOpen={showWarning} className="logout-modal modal-dialog-centered" >
                <div className="modal-body logoutbackground maintainace-bg  ">
                    <div className="txt-msg-box">
                        <p><strong>The game is currently under maintenance.</strong> <br /> <br />It will be back in {hour > 0 && ("0" + hour).slice(-2)}{hour > 0 && ":"}{("0" + min).slice(-2)}:{("0" + sec).slice(-2)}.
                            {/* {endMaintainanace}. */}
                        </p>
                    </div>
                    <div className="new-create-box text-center">
                        <button
                            type="button"
                            className="btn theme-orange-btn"
                            onClick={() => setShowWarning(false)}
                        >
                            <span>okay</span>
                        </button>
                    </div>
                </div>
            </Modal>

        </div >
    );
}

export default connect()(Tournament);
