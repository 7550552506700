import React from 'react'
// import Home
import Footer from '../auth/Footer'
import Header from '../auth/Header';


const Privacy = () => {
    return (
        <div>
            {/* <!--***** Start Body *****-->
            <!--***** Header *****--> */}
            {/* <Header /> */}
            <Header />
            {/* <!--***** //Header *****--> */}
            {/* <!--***** Middle Section *****--> */}
            <section className="site-content about-page-section cmn-banner-bg cmn-pad">
                <div className="container">
                    <div className="text-center">
                        <h3 className="text-center page-title">Privacy Policy</h3>
                    </div>
                    <div className="about-us-content desclaimer">
                        <div className='about-details-sec'>
                            <p>This Privacy Policy is an integral component of our Terms of Service. By accepting both the Terms of Service and this Privacy Policy during registration, you agree to provide sensitive personal data and acknowledge the purpose behind its collection. This policy governs your use of Rummyrani (referred to as “Rummyrani,” “we,” “us,” or “our”) website and services. Please review this Privacy Policy thoroughly.</p>
                            <p> registering with us and engaging with our services, you consent to the practices outlined in this Privacy Policy. Completion of the registration process requires filling out non-optional fields. Additional personal information may be requested by Rummyrani to access any Cash Games we offer.</p>
                            <h3>1. Objective of the Policy</h3>
                            <p>We prioritize your privacy and commit to protecting any information you share with Rummyrani in accordance with this Policy and relevant laws. To use the services available on <a href='www.Rummyrani.co.in'>www.Rummyrani.co.in</a> and associated applications (collectively referred to as the "Platform" or individually as "Platform"), you may be required to provide certain details. This Policy describes how Rummyrani collects, uses, stores, discloses, and manages your personal data. The data we collect is used for purposes such as verifying user accounts, maintaining user profiles, processing transactions, analyzing user behavior, and other related needs.</p>
                            <h3>2. Types of Information We Collect</h3>
                            <p>We gather or receive information that you provide when interacting with our Platform, including during registration, account access, communications, survey responses, content uploads, or other service uses. The information we collect includes:</p>
                            <h5>Sensitive Personal Data:</h5>
                            <ul>
                                <li>Account passwords</li>
                                <li>Financial details, such as bank account or card information</li>
                            </ul>
                            <h5>Other Personal Information:</h5>
                            <ul>
                                <li>Full name</li>
                                <li>Date of birth</li>
                                <li>Contact number</li>
                                <li>Postal address</li>
                                <li>PAN number (if applicable)</li>
                                <li>IP address, browser type, and language</li>
                                <li>Date and time of access</li>
                                <li>Referral website address</li>
                                <li>Photographs used for testimonials or promotions</li>
                                <li>Other information classified as sensitive personal data under applicable laws</li>
                            </ul>
                            <h3>3. How We Utilize Your Information</h3>
                            <p>We use your information for various purposes, including:</p>
                            <h5>a. Service Provision:</h5>
                            <ul>
                                <li>We use Sensitive Personal Data and Other Personal Information to verify and maintain user accounts, process transactions, analyze behavior, and meet related requirements.</li>
                            </ul>
                            <h3>4. b. Enhancement and Development:</h3>
                            <ul>
                                <li>We employ software for traffic analysis, statistics collection, advertising, and
                                    evaluating Rummyrani's effectiveness and popularity.</li>
                            </ul>
                            <h3>5. c. Marketing:</h3>
                            <ul>
                                <li>Testimonials and promotional photographs provided by you are used for marketing purposes. We retain the right to decide whether to continue using them, adhering to the User Control of Personal Data clause.</li>
                                <li>Rummyrani engages in advertising and remarketing through Google Analytics, allowing third-party vendors, including Google, to display ads across the internet and use cookies for targeting. Visitors can opt out of Google Analytics for display advertising and adjust Google display network ads through the ads preference manager.</li>
                            </ul>
                            <h3>6. d. Data Sharing:</h3>
                            <ul>
                                <li>We may share your information with employees, contractors, agents, and
                                    regulatory authorities on a need-to-know basis. This may also include sharing with advertisers and sponsors. Additionally, we may disclose your name, photo, and location when announcing contest results, which are governed by additional terms available on the Platforms. Rummyrani may share registration information with market research and advertising firms for research and marketing purposes and use non-personal information for analysis and auditing.</li>
                            </ul>
                            <h3>7. e. Cookies:</h3>
                            <ul>
                                <li>Cookies are small files that identify your browser and save your preferences. By
                                    using our Platform(s), you agree to the use of cookies for personalization, authentication, game management, data analysis, and security. Advertisers may also use cookies, which are managed by them, not Rummyrani.</li>
                            </ul>
                            <h3>8. f. Email Communication:</h3>
                            <ul>
                                <li>By providing your email address, you consent to receive communications from
                                    Rummyrani, authorized entities, and other users. Additionally, if you use our referral program, Rummyrani will send emails on your behalf, with your email address in the headers.</li>
                            </ul>
                            <h3>9. Data Security</h3>
                            <p>We employ various security measures to protect against unauthorized access, alteration, or destruction of data, including internal reviews, encryption, and physical security. Despite our efforts, absolute security cannot be guaranteed due to inherent risks in online data transmission. Users are responsible for protecting their login credentials and must notify Rummyrani immediately of any security breaches.
                                Our Platform may contain links to other websites with their own privacy policies. Rummyrani is not responsible for the privacy practices of external sites. Users should review the privacy policies of any third-party sites accessed through our Platform.</p>
                            <h3>10. User Control Over Personal Data</h3>
                            <h5>a. Updating Information:</h5>
                            <ul>
                                <li>You may update your information by contacting us at support@Rummyrani.co,in. Updates are subject to verification.</li>
                            </ul>
                            <h3>11. b. Withdrawing Consent:</h3>
                            <ul>
                                <li>To withdraw consent for using Sensitive Personal Data or Other Personal
                                    Information, email <a href='#'>support@Rummyrani.co.in</a>. Withdrawal of consent may lead to
                                    immediate termination of services.</li>
                            </ul>
                            <h3>12. c. Data Deletion:</h3>
                            <ul>
                                <li>
                                    Requests for deletion of personal data can be made via the My Account section after logging in. Rummyrani reserves the right to discontinue services related to the deleted data and may retain data as required by law.
                                </li>
                            </ul>
                            <h3>
                                13. Data Retention
                            </h3>
                            <p>Rummyrani retains user information for the period necessary to fulfill the purposes outlined in this Privacy Policy, subject to longer retention periods required by law.</p>
                            <h3>14. Exceptions</h3>
                            <p>Rummyrani may share Sensitive Personal Data and Other Personal Information when necessary to:</p>
                            <ul>
                                <li>Comply with legal requirements</li>
                                <li>Enforce Terms of Service and this Privacy Policy</li>
                                <li>Prevent fraud and address security issues</li>
                                <li>Protect the rights of Rummyrani, users, and the public</li>
                            </ul>
                            <h3>15. Limitation of Liability</h3>
                            <p>This Privacy Policy outlines our operations concerning user information but does not create legal rights. Rummyrani reserves the right to amend this Policy without prior notice. Our liability is limited to removing Sensitive Personal Data and personally identifiable information. The total liability for claims, including those related to this Policy, is limited to the amount specified in the Terms of Service.</p>
                            <h3>16. Grievance Officer</h3>
                            <p>Complaints regarding personal data should be directed to <a href='#'>grievanceofficer@Rummyrani.co.in</a></p>
                        </div>
                    </div>
                </div>
            </section>



            {/* <!--***** Footer *****--> */}
            <Footer />
        </div>
    )
}

export default Privacy