import constants from '../constants'
import {
    REGISTER_USER,
    TOKEN_LOGIN,
    LOGIN_USER,
    LOGIN_WITH_OTP,
    VERIFY_OTP,
    LOGOUT,
    SEND_OTP,
    CLEAR_AUTH_MESSAGE,
    CLEAR_VERIFICATION_TOKEN
} from "../constantsNew.js";


export default (state = {}, action) => {
    switch (action.type) {
        case REGISTER_USER:
            return {
                ...state,
                userData: action.payload.data,
                token: action.payload.token,
                registerSuccess: action.payload.registerSuccess,
                resStatus: action.payload.resStatus,
                resMessage: action.payload.resMessage,
                verificationToken: action.payload.verificationToken
            }
        case constants.REFERRAL:
            return {
                ...state,
                referralId: action.payload.data,
                resStatusRef: action.payload.resStatus,
                resMessageRef: action.payload.resMessage
            }
        case LOGIN_USER:
            return {
                ...state,
                token: action.payload.token,
                resStatus: action.payload.resStatus,
                resMessage: action.payload.resMessage,
                verificationToken: action.payload.verificationToken
            }
        case LOGIN_WITH_OTP:
            return {
                ...state,
                token: action.payload.token,
                resStatus: action.payload.resStatus,
                resMessage: action.payload.resMessage,
                verificationToken: action.payload.verificationToken
            }
        case TOKEN_LOGIN:
            return {
                ...state,
                token: action.payload.token,
                userData: action.payload.userData,
                resAuthMessage: action.payload.resAuthMessage
            }
        case VERIFY_OTP:
            return {
                ...state,
                token: action.payload.token,
                resStatus: action.payload.resStatus,
                resMessage: action.payload.resMessage,
                isVerify: action.payload.isVerify
            }
        case SEND_OTP:
            return {
                ...state,
                resStatus: action.payload.resStatus,
                resMessage: action.payload.resMessage,
                isSended: action.payload.isSended,
                forgotPasswordVerificationId: action.payload.verificationId,
                newTimer: action.payload.resendOtpTimer
            }
        case LOGOUT:
            return {
                ...state,
                token: undefined
            }
        case constants.FORGOTPASSWORD:
            return {
                ...state,
                forgotPasswordVerificationId: action.payload.data,
                resStatusFP: action.payload.resStatus,
                resMessage: action.payload.resMessage
            }
        case constants.RESETPASSWORD:
            return {
                ...state,
                resStatusRP: action.payload.resStatusRP,
                resMessage: action.payload.resMessage
            }
        case constants.TOKEN_LOGIN_UNAUTHORIZED: {
            const newState = {
                ...state,
                resStatusLogin: false,
                token: action.payload.token
            }
            return newState
        }
        case constants.LOGOUT_PASSWORD_CHANGE:
            return {
                token: undefined
            }
        case constants.SOCIAL_LOGIN:
            return {
                ...state,
                token: action.payload.token,
                socialRegistrationData: action.payload.data,
                accessToken: action.payload.accessToken,
                resStatusSocial: action.payload.resStatus,
                resMessage: action.payload.resMessage,
                mobileNumber: action.payload.mobileNumber
            }
        case constants.CLEAR_RESPONSE:
            return {
                resStatus: null,
                resMessage: '',
                socialRegistrationData: null,
                accessToken: null,
                resStatusSocial: null,
                mobileNumber: null,
                resendOTPVerificationId: null,
                resStatusResendOTP: null,
                resMessageResendOTP: '',
                resMessageLogin: ''

            }
        case constants.VERIFY_EMAIL:
            return {
                ...state,
                resStatus: action.payload.resStatus,
                resMessage: action.payload.resMessage
            }

        case CLEAR_VERIFICATION_TOKEN:
            return {
                ...state,
                verificationToken: null
            }
        case CLEAR_AUTH_MESSAGE:
            return {
                ...state,
                resMessage: '',
                isSended: null,
                resStatusFP: null,
                resStatusRP: null,
                resStatus: null,
                registerSuccess: null,
                isVerify: null,
                forgotPasswordVerificationId: null,
                socialRegistrationData: null,
                accessToken: null,
                resStatusSocial: null,
                mobileNumber: null,
                resStatusRef: null,
                referralId: null
            }
        default:
            return state

    }
}