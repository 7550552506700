/* eslint-disable react/prop-types */
import { React, useState, useEffect } from 'react';
import moment from 'moment';
import { connect, useDispatch, useSelector } from 'react-redux'
import { getPassbook } from '../../../actions/passbook'
import Loading from '../../../components/Loading'
import Paginate from '../../../components/Paginate';
import Select from 'react-select';


function PassbookAll(props) {

    const [passbook, setPassbook] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [totalData, setTotalData] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [firstPage, setFirstPage] = useState(false);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch()
    const token = useSelector(state => state.auth.token)
    const passbookData = useSelector(state => state.passbook.passbookData)
    const tab = props.tab

    useEffect(() => {
        document.title = "RUMMY RANI | My Account"
    }, [])

    useEffect(() => {
        if (tab === "2") {
            setLoading(true)
            dispatch(getPassbook(pageSize, currentPage, token, 'debit'))
        }
    }, [currentPage, pageSize, tab])


    useEffect(() => {
        if (passbookData?.transaction?.length) {
            setPassbook(passbookData?.transaction)
            setPageCount(Math.ceil((passbookData?.count?.totalData) / pageSize));
            setTotalData(passbookData?.count?.totalData / 2)
            setLoading(false)
        }
        else if (passbookData) {
            setLoading(false)
            setPassbook(null)
        }

    }, [passbookData])

    function dateFormat(date) {
        return moment(date).format("MMM DD yyyy hh:mm A")
    }


    const handlePageClick = async (data) => {
        setCurrentPage(data.selected)
    }


    function handlePageSize(e) {
        if (Number(e.value) > 10) {
            setCurrentPage(0)
            setFirstPage(true)
        }
        setPageSize(Number(e.value))
        setPageCount(Math.ceil(totalData / e.value))
    }

    const options = [{ value: '10', label: 'Show 10' }, { value: '25', label: 'Show 25' }, { value: '40', label: 'Show 40' }]

    return (
        <>
            {loading && <Loading />}
            <div className="game-transaction-section passbook-deposite-section">
                {passbook?.length ?
                    <>
                        <div className="table-cnt table-cnt-box br-6">
                            <div className="table-responsive">
                                <table className="common-table table">
                                    <thead>
                                        <tr>
                                            <th className="text-center" width="20%">Date & Time</th>
                                            <th className="text-center" width="20%">Transaction ID</th>
                                            <th className="text-center" width="15%">Transaction Type</th>
                                            <th className="text-center" width="15%">Amount</th>
                                            <th className="text-center" width="15%">Status</th>
                                            <th className="text-center" width="15%">Payment Mode</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {passbook.map((pass, i) => (
                                            <tr key={i} className="text-center">
                                                <td>
                                                    {pass.dCreatedDate ? dateFormat(pass.dCreatedDate) : "Not Available"}
                                                </td>
                                                <td className="text-center">
                                                    {pass._id}
                                                </td>
                                                <td className="text-center">
                                                    {pass.eType}
                                                </td>
                                                <td className="text-center">
                                                    &#8377;{pass.nAmount?.toFixed(2) || '0'}
                                                </td>
                                                <td className="text-center">
                                                    {pass.eStatus}
                                                </td>
                                                <td className="text-center">
                                                    {pass.eMode}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="transaction-btn-wrap d-flex align-items-center">
                            <div className="cust-dropdown-wrap">
                                <div className="cust-dropdown-box text-center">
                                    <Select
                                        value={options.filter(function (option) {
                                            return option.value == pageSize;
                                        })}
                                        onChange={e => handlePageSize(e)}
                                        options={options}
                                        className="dropdown-blue-box"
                                        classNamePrefix={'inner-dropdown'}
                                        menuPlacement={'auto'}
                                    />
                                </div>
                            </div>
                            <div className="pagination-wrap text-center">
                                <div>
                                    <Paginate pageCount={pageCount} handlePageClick={handlePageClick} firstPage={firstPage} />
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <div className="table-cnt table-cnt-box br-6">
                        <div className="table-responsive">
                            <table className="common-table table">
                                <thead>
                                    <tr>
                                        <th className="text-center" width="20%">Date & Time</th>
                                        <th className="text-center" width="20%">Transaction ID</th>
                                        <th className="text-center" width="15%">Transaction Type</th>
                                        <th className="text-center" width="15%">Amount</th>
                                        <th className="text-center" width="15%">Status</th>
                                        <th className="text-center" width="15%">Payment Mode</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan="6" className="text-center">
                                            Not Available
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default connect()(PassbookAll);
