import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Modal, ModalBody, ModalHeader, Table } from 'reactstrap';
import { connect, useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import { getProfile, getSettings } from '../../actions/account';
import { depositCash, depositRealCash, getBonusList } from '../../actions/deposit';
import Loading from '../../components/Loading';
import TicketIcon from '../../assets/images/icons/ticket-icon.png';
import info from '../../assets/images/icons/info.png';
import moment from 'moment';
import useRazorpay from "react-razorpay";
import config from '../../config'
import ReactDOM from 'react-dom';
import { QRCodeSVG } from 'qrcode.react';
import axios from '../../axios'
import upiimg from '../../assets/images/upi-img.jpg';


function DepositCash() {
    const [loading, setLoading] = useState(false);
    const [restrictedStates, setRestrictedStates] = useState([]);
    const [minAmount, setMinAmount] = useState(0);
    const [maxAmount, setMaxAmount] = useState(0);
    const [minDeposit, setMinDeposit] = useState(0);
    const [promocodeList, setPromocodeList] = useState([]);
    const [promoID, setPromoID] = useState('');
    const [switchToRemove, setSwitchToRemove] = useState(false);
    const [promoArray, setPromoArray] = useState({});
    const [amount, setAmount] = useState('');
    const [bonus, setBonus] = useState(0);
    const [promoModal, setPromoModal] = useState(false)
    const [bonusStart, setBonusStart] = useState();
    const [bonusEnd, setBonusEnd] = useState();
    const [aAddCashPack, setaAddCashPack] = useState();
    const [disableDeposit, setDisableDeposit] = useState(false);
    const [qrModal, setQrModal] = useState(false);
    const [showQR, setShowQR] = useState(true);


    const promoModalTable = () => setPromoModal(!promoModal);
    const toggleQrModal = () => setQrModal(!qrModal);

    const dispatch = useDispatch()
    const gameSettings = useSelector(state => state.account.data)
    const token = useSelector(state => state.auth.token)
    const promoList = useSelector(state => state.deposit.listData)
    const depositData = useSelector(state => state.deposit.depositData)

    const Razorpay = useRazorpay();


    const depositStatus = useSelector(state => state.deposit.resStatusDeposit)
    const depositMessage = useSelector(state => state.deposit.resMessageDeposit)
    const previousProps = useRef({ depositStatus, depositMessage }).current

    useEffect(() => {
        setLoading(true)
        dispatch(getSettings)
        dispatch(getBonusList(token))
        setAmount(null)
        setMinAmount(null)
        document.title = 'Rummy Rani | Deposit'
    }, [])

    // useEffect(() => {
    //     if (previousProps.depositMessage !== depositMessage) {
    //         if (depositMessage?.length && depositMessage === "success.") {
    //             toast(
    //                 <div>
    //                     <div>Your account is credited with amount {minAmount}</div>
    //                 </div>
    //                 , { autoClose: 3000 });
    //         } else if (depositMessage?.length && depositMessage !== "success.") {
    //             toast(
    //                 <div>
    //                     <div>{depositMessage}</div>
    //                 </div>
    //                 , { autoClose: 3000 });
    //         }
    //     }
    //     return () => {
    //         previousProps.depositMessage = depositMessage
    //     }
    // }, [depositMessage])

    useEffect(() => {
        if (gameSettings) {
            setLoading(false)
            setRestrictedStates(gameSettings?.aRestrictedState)
            setMinAmount(gameSettings?.oPurchaseRange?.nMinimum)
            setMaxAmount(gameSettings?.oPurchaseRange?.nMaximum)
            setMinDeposit(gameSettings?.oPurchaseRange?.nMinimum)
            setaAddCashPack(gameSettings?.aAddCashPack)
        }
    }, [gameSettings])

    // useEffect(() => {
    //     if (previousProps.depositData !== depositData) {
    //         if (depositData) {
    //             var options = {
    //                 "key": `${config?.PAYMENT_KEY}`,
    //                 "amount": `${depositData?.amount}`,
    //                 "currency": "INR",
    //                 "name": "RUMMY RANI",
    //                 "description": "",
    //                 "image": "https://www.tcv.com/wp-content/uploads/2016/11/Razorpay_blog_logo.png",
    //                 "order_id": `${depositData?.id}`,
    //                 "handler": function (response) {
    //                     toast(
    //                         <div>
    //                             <div>Payment successful!</div>
    //                         </div>
    //                         , { autoClose: 3000 });
    //                     dispatch(getProfile(token))
    //                     setAmount('')
    //                     setMinAmount(0)
    //                     setDisableDeposit(false)
    //                     setSwitchToRemove(false)

    //                     // alert(response.razorpay_payment_id);
    //                     // alert(response.razorpay_order_id);
    //                     // alert(response.razorpay_signature)
    //                 },
    //                 "notes": {},
    //                 "theme": {
    //                     "color": "#3399cc"
    //                 }
    //             };
    //             var rzp1 = new Razorpay(options);
    //             rzp1.open();
    //         }
    //     }
    //     return () => {
    //         previousProps.depositData = depositData
    //     }
    // }, [depositData])

    useEffect(() => {
        if (previousProps.depositStatus !== depositStatus) {
            if (depositStatus === true) {
                setLoading(false)
                dispatch(getProfile(token))
                setAmount('')
                setMinAmount(0)
                setDisableDeposit(false)
                setSwitchToRemove(false)
            }
            else if (depositStatus === false) {
                setLoading(false)
                setAmount('')
                setMinAmount(0)
                setDisableDeposit(false)
                setSwitchToRemove(false)
            }
        }
        return () => {
            previousProps.depositStatus = depositStatus
        }
    }, [depositStatus])

    useEffect(() => {
        if (promoList) {
            setPromocodeList(promoList)
            setBonusStart(promoList?.length && promoList[0]?.dStartDate)
            setBonusEnd(promoList?.length && promoList[0]?.dEndDate)

        }
    }, [promoList])

    function handleAmount(e) {
        const value = e.target.value.replace(/[^\d]/, '');
        const numValue = parseInt(value);
        if (numValue >= minAmount && numValue <= maxAmount) {
            setAmount(value);
            setMinAmount(value);
            setDisableDeposit(true);
        } else if (numValue > maxAmount) {
            setAmount(maxAmount.toString());
            setMinAmount(maxAmount);
            setDisableDeposit(true);
            toast.warning(`Maximum deposit amount is ₹${maxAmount}`);
        } else if (numValue < minAmount) {
            setAmount(value);
            setDisableDeposit(false);
        }
        else {
            setAmount('');
            setMinAmount(0);
            setDisableDeposit(false);
        }
    }

    useEffect(() => {
        if (minAmount > 0) {
            promocodeList?.map(p => {
                p?.aPriceRange?.map((range) => {
                    if (minAmount >= range?.nFrom && minAmount <= range?.nTo) {
                        setBonus((minAmount * range?.nPercentage) / 100)
                    }
                })
            })
        } else {
            setBonus(0)
        }
    }, [minAmount])

    function handleApplyPromocode(promo, range) {
        if (switchToRemove == false) {
            setAmount(range?.nFrom)
            setSwitchToRemove(false)
            setMinAmount(range?.nFrom)
            setDisableDeposit(true)
        }
        setPromoModal(false)
        setPromoArray(range)
        setPromoID(promo?._id)
        // setSwitchToRemove(!switchToRemove)
    }

    useEffect(() => {
        if (switchToRemove === false) {
            setPromoID('')
            setAmount('')
            setMinAmount('')
        }
    }, [switchToRemove])

    function handleDepositCash(e) {
        e.preventDefault()
        // ReactDOM.render(
        //     <QRCodeSVG value="https://reactjs.org/" />,
        //     document.getElementById('mountNode')
        // );
        if (minAmount > 0 && minAmount <= maxAmount) {
            setLoading(true)
            dispatch(depositCash(minAmount, promoID, token))
            setShowQR(true);
        } else if (minAmount > maxAmount) {
            toast.error(`Maximum deposit amount is ₹${maxAmount}`);
        }

    }

    function handleFixedAmount(amount) {
        setMinAmount(amount);
        setAmount(amount);
        setDisableDeposit(true)
        setSwitchToRemove(false)
    }

    function dateFormat(date) {
        return moment(date).format("hh:mm A | DD MMMM YYYY")
    }
    let intervalId;
    useEffect(() => {

        intervalId = setInterval(fetchData, 10000);

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [depositData?.CustRefNum, token]);

    const fetchData = () => {
        if (depositData?.CustRefNum) {
            axios.get(`transaction/buystatus/${depositData?.CustRefNum}`, {
                headers: {
                    'authorization': token
                }
            })
                .then((response) => {
                    console.log(response?.data?.data);
                    if (response?.data?.data?.transaction.eStatus == 'success') {
                        setShowQR(false);
                        clearInterval(intervalId);
                        window.location.reload();
                        toast(
                            <div>
                                <div>Your account is credited with amount {minAmount}</div>
                            </div>
                            , { autoClose: 3000 });
                    }
                    else if (response?.data?.data?.transaction.eStatus == 'failed') {
                        setShowQR(false);
                        clearInterval(intervalId);
                        toast(
                            <div>
                                <div>Payment Failed</div>
                            </div>
                            , { autoClose: 3000 });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };
    console.log(showQR);






    return (
        <>
            {loading && <Loading />}
            <div className="cmn-banner-bg">
                <section className="deposit-wrap">
                    <div className="container">
                        <div className="deposit-parent-box">
                            <div className="text-center">
                                <h2 className='deposite-header'>Deposit Cash</h2>
                            </div>
                            <div className="deposit-cash-box">
                                <h3 className="text-center">Deposit Range : &#8377;{minDeposit} - &#8377;{maxAmount}</h3>
                                <div className="deposit-cash-form-box d-flex">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            maxLength={6}
                                            className="form-control"
                                            id="room-no"
                                            placeholder="₹ Enter Amount"
                                            value={amount}
                                            onChange={handleAmount}
                                            max={maxAmount}
                                        />                                        <div className="yellow-buttons-box d-flex justify-content-between align-items-end mt-2 mb-2">
                                            {/* <a className="yellow-txt" onClick={promoModalTable}> View Offers </a> */}
                                            {bonus > 0 && <span className="yellow-txt">Bonus: ₹{bonus}</span>}
                                        </div>
                                    </div>

                                </div>

                                <div className="blue-bg text-center">
                                    <div className="scollbar-box d-flex">
                                        {
                                            aAddCashPack?.map(((pack, index) => {
                                                if (pack && index <= 4) {
                                                    return (<div className="bonus-btn-box">
                                                        <div className="bonus-box">
                                                            <button type="button" className="btn yellow-btn" onClick={() => handleFixedAmount(pack)}>
                                                                <span>₹</span> <span> {pack} </span>
                                                            </button>
                                                        </div>

                                                    </div>)

                                                }
                                            }))
                                        }




                                    </div>
                                    {!!promoList?.length &&
                                        <div className="prmotion-txt-box text-center">
                                            <p>
                                                All Promotions are valid from {dateFormat(bonusStart)} to {dateFormat(bonusEnd)}
                                            </p>
                                        </div>
                                    }
                                </div>
                                <div className='text-center'>
                                    <button
                                        type="button"
                                        className="btn add-cash-btn"
                                        onClick={handleDepositCash}
                                        disabled={!disableDeposit}
                                    >
                                        Add Cash
                                    </button>
                                    {(depositData?.qrString && showQR) &&
                                        <div id="mountNode">
                                            <div className='qr-code-sec'>
                                                <h2>Scan the QR code from your UPI app to add money in your wallet.</h2>
                                                <QRCodeSVG
                                                    value={depositData?.qrString}
                                                    size={200}
                                                    bgColor={"#ffffff"}
                                                    fgColor={"#000000"}
                                                    level={"L"}
                                                    includeMargin={false}
                                                    imageSettings={{
                                                        src: "https://game.yudiz.com/APKs/rummy_rani_logo.png",
                                                        x: undefined,
                                                        y: undefined,
                                                        height: 25,
                                                        width: 40,
                                                        excavate: false,
                                                    }}
                                                />
                                                <img src={upiimg} />
                                                <p>Note :- Do not refresh the page until the transaction is completed.</p>
                                                {/* <iframe className='payment-iframe' src={depositData?.qrString} width="320px" height="700px" frameBorder="0"></iframe> */}
                                            </div>

                                        </div>
                                    }
                                </div>
                                <div className="text-center">
                                    <p>
                                        You must be 18+ to play. We do not allow our players from to
                                        play cash Games.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Fragment>
                    <Modal isOpen={promoModal} toggle={promoModalTable} className='promo-offer-modal' centered>


                        <ModalBody >
                            <div className="panel with-nav-tabs panel-info">
                                <h3 className="modal-title text-center" id="exampleModalLongTitle">Promotional Oﬀers </h3>
                                <div className="panel-heading">
                                </div>
                                <div className="promo-wrap">
                                    <div className="">
                                        <Table responsive className="common-table">
                                            <thead>
                                                <tr>
                                                    <th width="25%" className="text-center text-capitalize">Name</th>
                                                    <th width="25%" className="text-center text-capitalize">Type</th>
                                                    <th width="15%">
                                                        <div className="th-actions">
                                                            <span> Price Range </span>
                                                        </div>
                                                    </th>
                                                    <th width="30%" className="text-capitalize ">
                                                        <div className=" th-actions">
                                                            <span> Bonus(%) </span>
                                                        </div>
                                                    </th>
                                                    <th width="30%" className="text-capitalize">
                                                        <div className="th-actions">
                                                            <span> Ticket </span>
                                                        </div>
                                                    </th>
                                                    <th width="30%" className="text-capitalize">
                                                        <div className="th-actions">
                                                            <span> Validity </span>
                                                        </div>
                                                    </th>
                                                    <th width="30%" className="text-capitalize">
                                                        <div className="th-actions">
                                                            <span> Action </span>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {promocodeList?.length ?
                                                    promocodeList?.map((promo) => (
                                                        <>
                                                            {
                                                                promo?.aPriceRange?.map((range, i) => (
                                                                    <tr key={i}>
                                                                        <td width="25%">
                                                                            <p >{promo?.sName}</p>
                                                                        </td>
                                                                        <td width="25%">
                                                                            <p >{promo?.eCategory?.toUpperCase()}</p>
                                                                        </td>
                                                                        <td width="30%">
                                                                            <p>&#8377;{range?.nFrom} to &#8377;{range?.nTo}</p>
                                                                        </td>
                                                                        <td width="15%">
                                                                            <p>Get {range?.nPercentage || 0}% bonus upto &#8377;{range?.nMaxBonus || 0}</p>
                                                                        </td>
                                                                        <td width="30%">
                                                                            <p>{range?.ticketSchedule?.length ? range?.ticketSchedule[0]?.sName : 'NA'}</p>
                                                                        </td>
                                                                        <td width="30%">
                                                                            <p>{promo?.nExpiresIn} Days</p>
                                                                        </td>
                                                                        <td width="30%" className="text-center">
                                                                            <div className=''>
                                                                                <button type="button" className="yellow-btn" onClick={() => handleApplyPromocode(promo, range)} >
                                                                                    <span className="main-layer">
                                                                                        {!switchToRemove && promoArray?.nPercentage === range?.nPercentage ? "Remove" : "Apply"}
                                                                                    </span>
                                                                                </button>
                                                                                {/* <img src={info} alt="ticket" /> */}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </>
                                                    ))
                                                    :
                                                    <tr>
                                                        <td colSpan="7" className="text-center">Currently, No promotional offers available.</td>
                                                    </tr>
                                                }
                                                {/* <tr>
                                                    <td
                                                        width="25%"
                                                    >
                                                        <p>Propser Data FTD </p>
                                                    </td>
                                                    <td
                                                        width="15%"
                                                    >
                                                        <p>FTD</p>
                                                    </td>
                                                    <td
                                                        width="30%"
                                                    >
                                                        <p> Mar 23, 2022, 6:60 PM</p>
                                                    </td>

                                                    <td width="30%" className="text-center">
                                                        <div className=''>
                                                            <button
                                                                type="button"
                                                                className="yellow-btn"
                                                            >
                                                                <span className="main-layer">
                                                                    Apply
                                                                </span>
                                                            </button>
                                                            <img src={info} alt="ticket" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        width="25%"
                                                    >
                                                        <p>Propser Data FTD </p>
                                                    </td>
                                                    <td
                                                        width="15%"
                                                    >
                                                        <p>FTD</p>
                                                    </td>
                                                    <td
                                                        width="30%"
                                                    >
                                                        <p> Mar 23, 2022, 6:60 PM</p>
                                                    </td>

                                                    <td width="30%" className="text-center">
                                                        <div className=''>
                                                            <button
                                                                type="button"
                                                                className="yellow-btn"
                                                            >
                                                                <span className="main-layer">
                                                                    Apply
                                                                </span>
                                                            </button>
                                                            <img src={info} alt="ticket" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        width="25%"
                                                    >
                                                        <p>Propser Data FTD </p>
                                                    </td>
                                                    <td
                                                        width="15%"
                                                    >
                                                        <p>FTD</p>
                                                    </td>
                                                    <td
                                                        width="30%"
                                                    >
                                                        <p> Mar 23, 2022, 6:60 PM</p>
                                                    </td>

                                                    <td width="30%" className="text-center">
                                                        <div className=''>
                                                            <button
                                                                type="button"
                                                                className="yellow-btn"
                                                            >
                                                                <span className="main-layer">
                                                                    Apply
                                                                </span>
                                                            </button>
                                                            <img src={info} alt="ticket" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        width="25%"
                                                    >
                                                        <p>Propser Data FTD </p>
                                                    </td>
                                                    <td
                                                        width="15%"
                                                    >
                                                        <p>FTD</p>
                                                    </td>
                                                    <td
                                                        width="30%"
                                                    >
                                                        <p> Mar 23, 2022, 6:60 PM</p>
                                                    </td>

                                                    <td width="30%" className="text-center">
                                                        <div className=''>
                                                            <button
                                                                type="button"
                                                                className="yellow-btn"
                                                            >
                                                                <span className="main-layer">
                                                                    Apply
                                                                </span>
                                                            </button>
                                                            <img src={info} alt="ticket" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        width="25%"
                                                    >
                                                        <p>Propser Data FTD </p>
                                                    </td>
                                                    <td
                                                        width="15%"
                                                    >
                                                        <p>FTD</p>
                                                    </td>
                                                    <td
                                                        width="30%"
                                                    >
                                                        <p> Mar 23, 2022, 6:60 PM</p>
                                                    </td>

                                                    <td width="30%" className="text-center">
                                                        <div className=''>
                                                            <button
                                                                type="button"
                                                                className="yellow-btn"
                                                            >
                                                                <span className="main-layer">
                                                                    Apply
                                                                </span>
                                                            </button>
                                                            <img src={info} alt="ticket" />
                                                        </div>
                                                    </td>
                                                </tr> */}
                                            </tbody>
                                        </Table>

                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                </Fragment>

            </div>

        </>
    );
}

export default connect()(DepositCash);
