import React from "react";
import { Link } from "react-router-dom";

// import Home
import Footer from "../auth/Footer";
import Header from "../auth/Header";
import blog_img from "../../assets/images/blog/blog-img1.png";

const ReferEarn = () => {
  return (
    <div>
      {/* <!--***** Start Body *****-->
            <!--***** Header *****--> */}
      {/* <Header /> */}
      <Header />
      {/* <!--***** //Header *****--> */}
      {/* <!-- Tab Panel Section --> */}
      <section className="site-content cmn-banner-bg cmn-pad">
        <div className="container">
          <div className="page-title cmn-pad mb-0">
            <h3 className="text-center">refer & earn</h3>
          </div>
          <div className="about-us-content text-center">
            <h1 className="cmn-pad">Comming Soon</h1>
          </div>
        </div>
      </section>
      {/* Delete below code */}
      <div className="mar-top-25">
        <div className="about-us-content how-to-play-area">
          <div className="tabbable game-tab-panel tabs-left d-flex">
            <ul className="nav nav-tabs">
              <li>
                <a className="active" href="#rummy-game-13" data-toggle="tab">
                  13 Rummy Game
                </a>
              </li>
              {/* <li><a href="#rummy-game-21" data-toggle="tab">21 Rummy Game</a></li> */}
              {/* <li><a href="#rummy-game-36" data-toggle="tab">36 Rummy Game</a></li> */}
            </ul>
            <div className="tab-content">
              <div className="tab-pane active" id="rummy-game-13">
                <div className="">
                  <h3>13 Card Rummy Game</h3>
                  <p style={{ color: "black" }}>
                    At RummyRani.co.in, we have the 13 and 21 Rummy card games that
                    are filled with twists and turns. Each Rummy game variant
                    has a practice match that can be played with a set of
                    pre-defined practice chips that are set by the admin.
                  </p>
                  <p style={{ textDecoration: "underline" ,color: "black" }}>
                    <strong>Variants for 13 Rummy gameplay are:</strong>
                  </p>
                  <h5>Points Rummy Gameplay</h5>
                  <p style={{ color: "black" }}>
                    When the user selects the points rummy, it will ask the user
                    to select the following:{" "}
                  </p>
                  <ul>
                    <li>Select Players (2 players / 6 players)</li>
                    <li>
                      Select Entry fees – the user can set the points value and
                      entry fees accordingly.{" "}
                    </li>
                  </ul>
                  <p style={{ color: "black" }}>
                    In Points Rummy, the players play for points with
                    pre-decided rupee value.
                  </p>
                  <p style={{ color: "black" }}>
                    One player who makes the first valid declaration wins the
                    game with 0 points and wins all the chips based on the
                    opponent&apos;s total points.
                  </p>
                  <p style={{ color: "black" }}>
                    Which player will make the first move, is decided by dealing
                    one card each at random and whoever has the highest card,
                    will make the first move. After that, each player is dealt
                    13 cards at random.
                  </p>
                  <p style={{ color: "black" }}>
                    The first card from the pack is placed in the open deck
                    indicating that the game has started.
                  </p>
                  <p style={{ color: "black" }}>
                    A joker card is randomly selected at the beginning of each
                    game. Two types of joker are used in the game: Wild Joker
                    and printed Joker.
                  </p>
                  <p style={{ color: "black" }}>
                    Both Jokers will be used to replace a card to make a
                    sequence or pair. Players can also use the joker (selected
                    randomly at the beginning of the game) in place of any
                    missing card to complete a set/sequence.
                  </p>
                  <p style={{ color: "black" }}>
                    <strong>Objective:</strong> Arrange all 13 cards in-hand in
                    sequences and/or sets.
                  </p>
                  <p style={{ color: "black" }}>
                    Each player takes turns to pick/discard a card from the
                    closed/open deck to complete sets and/or sequences. The user
                    can use one or more jokers to complete the sets and/or
                    sequences.
                  </p>
                  <p style={{ color: "black" }}>
                    The user can arrange 3-4 cards and arrange them into groups
                    to make sets/sequences.
                  </p>
                  <p style={{ color: "black" }}>
                    The user can move a card to the finish slot to declare sets
                    and sequences. To declare the player must show the cards in
                    hand by grouping them in sets/sequences and clicking on the
                    &apos;Declare&apos; button.
                  </p>
                  <p style={{ color: "black" }}>
                    <strong>Compulsory:</strong> To finish the game, you must
                    declare at least two sequences, one of which must be a pure
                    sequence.
                  </p>
                  <p style={{ color: "black" }}>
                    Caution: With two decks, you cannot use the same card twice
                    in a set for instance 7 ♠ 7 ♠ 7 ♥ is not a valid set.
                  </p>
                  <p style={{ color: "black" }}>
                    There will be 2 timers available for the user. One is the
                    Turn Time and another is a Time Bank.{" "}
                  </p>
                  <p style={{ color: "black" }}>
                    Turn Time is the 30 seconds, each player gets to take their
                    turn. If they miss out, extra 10 seconds will be taken from
                    the time bank and if the user does not take their turn in
                    that 10 seconds (Time bank), their turn will be considered
                    over.
                  </p>
                  <p style={{ color: "black" }}>
                    There will be an auto drop check box so the user can select
                    it and he/she will be auto drop when their turn comes. To go
                    back to the table again they have to click on the “I&apos;m
                    back” button.
                  </p>
                </div>
              </div>
              <div className="tab-pane" id="rummy-game-21">
                <div className="">
                  <p style={{ color: "black" }}>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </p>
                </div>
              </div>
              <div className="tab-pane" id="rummy-game-36">
                <div className="">
                  <p style={{ color: "black" }}>
                    Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--***** //Middle Section *****--> */}
      {/* <!--***** Footer *****--> */}
      <Footer />
    </div>
  );
};

export default ReferEarn;
