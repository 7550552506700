import { toast } from 'react-toastify'
import axios from '../axios'

import constants from '../constants'

const errMsg = 'Server is unavailable.'

export const getPromocodeList = (nMinimumAmount, nMaximumAmount, token) => (dispatch) => {
    dispatch({ type: constants.CLEAR_DEPOSIT })
    axios.post('/promocode/filter', {
        nMinimumAmount: nMinimumAmount,
        nMaximumAmount: nMaximumAmount
    }, {
        headers: {
            'authorization': token
        }
    })
        .then((response) => {
            dispatch({
                type: constants.GET_PROMOCODE_LIST,
                payload: {
                    data: response.data.data,
                    resStatus: true,
                    resMessage: response.data.message
                }
            })
        }).catch((error) => {
            dispatch({
                type: constants.GET_PROMOCODE_LIST,
                payload: {
                    resStatus: false,
                    resMessage: error.response ? error.response.data.message : errMsg
                }
            })
        })
}


export const getBonusList = (token) => (dispatch) => {
    dispatch({ type: constants.CLEAR_DEPOSIT })
    axios.get('bonus/active/promotion', {
        headers: {
            'authorization': token
        }
    })
        .then((response) => {
            dispatch({
                type: constants.GET_PROMOCODE_LIST,
                payload: {
                    data: response.data.data,
                    resStatus: true,
                    resMessage: response.data.message
                }
            })
        }).catch((error) => {
            dispatch({
                type: constants.GET_PROMOCODE_LIST,
                payload: {
                    resStatus: false,
                    resMessage: error.response ? error.response.data.message : errMsg
                }
            })
        })
}


export const depositCash = (nAmount, iPromotionId, token) => (dispatch) => {
    dispatch({ type: constants.CLEAR_DEPOSIT })
    // axios.post('/transaction/addChips', {
    axios.post('/transaction/buycash', {
        nAmount,
        ...(iPromotionId?.length ? { iPromotionId } : {}),
    }, {
        headers: {
            'authorization': token
        }
    })
        .then((response) => {
            dispatch({
                type: constants.ADD_DEPOSIT_CASH,
                payload: {
                    data: response.data.data,
                    resStatus: true,
                    resMessage: response.data.message
                }
            })
        }).catch((error) => {
            toast.error(error.response?.data?.message);
            dispatch({
                type: constants.ADD_DEPOSIT_CASH,
                payload: {
                    resStatus: false,
                    resMessage: error.response ? error.response.data.message : errMsg
                }
            })
        })
}

export const depositRealCash = (nAmount, iPromotionId, token) => (dispatch) => {
    dispatch({ type: constants.CLEAR_DEPOSIT })
    axios.post('/transaction/buy', {
        ...(nAmount ? { nAmount: nAmount } : {}),
        ...(iPromotionId?.length ? { iPromotionId } : {})
    }, {
        headers: {
            'authorization': token
        }
    })
        .then((response) => {
            dispatch({
                type: constants.ADD_DEPOSIT_CASH,
                payload: {
                    data: response.data.data,
                    resStatus: true,
                    resMessage: response.data.message
                }
            })
        }).catch((error) => {
            dispatch({
                type: constants.ADD_DEPOSIT_CASH,
                payload: {
                    resStatus: false,
                    resMessage: error.response ? error.response.data.message : errMsg
                }
            })
        })
}