/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane, Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Input } from 'reactstrap'
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import { useQueryState } from 'react-router-use-location-state'
import qs from 'query-string'
import moment from 'moment';
import { createPrivateTable, dismissDailyBonus, getBonusWinAmount, getDailyBonus, getJoinedTables, getJoinedTournaments, getTournamentQualifiers, joinPrivateTable } from '../../actions/lobby';
import Practice from './gameType/Practice';
import Cash from './gameType/Cash'
import Tournament from './gameType/Tournament';
import CreatePrivateTable from './components/CreatePrivateTable';
import { getProfile, getSettings } from '../../actions/account';
import Loading from '../../components/Loading';
import config from '../../config'
import howPlay from '../../assets/images/icons/how-to-play.png';
import reportProblem from '../../assets/images/icons/report-problem.png';
import termsCondition from '../../assets/images/icons/terms-conditions.png';
import privacyPolicy from '../../assets/images/icons/privacy-policy.png';
import avatar from '../../assets/images/icons/user-icon.png';
import refresh from '../../assets/images/refresh.svg';
import border from '../../assets/images/Boarder.png'
import spinBtn from '../../assets/images/SpinButton.png'
import newWheel from '../../assets/images/New_WheelArrow.png'
import cup from '../../assets/images/cup-icon.svg';
import UserIcon from "../../assets/images/icons/user-icon.png";
import CloseIcon from "../../assets/images/icons/Close.png";
import StepRangeSlider from "react-step-range-slider";
import SixPlayerTable from "../../assets/images/point-6-table.png";


function LobbyDetails() {

    const [modal, setModal] = useState(false);
    const [activeTabtables, setActiveTabtables] = useState('1');
    const [activeTab, setActiveTab] = useState('');
    const [joinedTablesList, setJoinedTablesList] = useState([]);
    // const [privateTableModal, setPrivateTableModal] = useState(false);
    const [activeTabPrivatetables, setActiveTabPrivatetables] = useState('5');
    const [switchToJoinTable, setSwitchToJoinTable] = useState(false);
    const [switchToCreateTable, setSwitchToCreateTable] = useState(false);
    const [code, setCode] = useState('');
    const [disableJoinTable, setDisableJoinTable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [joinedTableId, seJoinedTableId] = useState([]);
    const [top, setTop] = useState(null);
    const [left, setLeft] = useState(null);
    const [activeMainTab, setActiveMainTab] = useQueryState('activeTab', '1')
    const [activeModal, setActiveModal] = useQueryState('modal', '1')
    const [spinModal, setSpinModal] = useState(false);
    const [spinBonusList, setSpinBonusList] = useState([]);
    const [joinedTournamentList, setJoinedTournamentList] = useState();
    const [spinTimeout, setSpinTimeout] = useState(false);
    const [joinGameModal, setJoinGameModal] = useState(false);
    const [privateModal, setPrivateModal] = useState(false);
    const [gameModal, setGameModal] = useState(false);
    const [privatetable, setPrivatetable] = useState("create");
    const [playerToggle, setPlayerToggle] = useState(2);
    const [privatetableRange, setPrivatetableRange] = useState([]);
    const [pointValue, setPointValue] = useState(0);
    const [players, setPlayers] = useState(0);
    const [tableFee, setTableFee] = useState(0);
    const [rangeSlider, setRangeSlider] = useState([]);
    const [tourQualifierModal, setTourQualifierModal] = useState(false);
    const [tournamentQualifiersData, setTournamentQualifiersData] = useState([]);
    const [timer, setTimer] = useState(0);
    const [firstPoint, setFirstPoint] = useState(0);
    const [maintainance, setMaintainance] = useState(false);
    // const [endMaintainanace, setEndMaintainanace] = useState('');
    const [showWarning, setShowWarning] = useState(false);
    const [isInsufficientChips, setIsInsufficientChips] = useState(false);


    const dispatch = useDispatch()
    const profileData = useSelector(state => state.account.dataProfile)
    const token = useSelector(state => state.auth.token)
    const joinedTablesData = useSelector(state => state.lobby.joinedTablesData)
    const joinPrivateTableMessage = useSelector(state => state.lobby.joinPrivateTableResMessage)
    const createPrivateTableMessage = useSelector(state => state.lobby.createPrivateTableResMessage)
    const joinTableStatus = useSelector(state => state.lobby.joinTableResStatus)
    const joinTableMessage = useSelector(state => state.lobby.joinTableResMessage)
    const createPrivateTableStatus = useSelector(state => state.lobby.createPrivateTableResStatus)
    const createPrivateTableData = useSelector(state => state.lobby.createPrivateTableData)
    const joinPrivateTableStatus = useSelector(state => state.lobby.joinPrivateTableResStatus)
    const joinPrivateTableData = useSelector(state => state.lobby.joinPrivateTableData)
    const joinTableId = useSelector(state => state.lobby.joinTableId)
    const joinedTournamentData = useSelector(state => state.lobby.joinedTournamentsData)
    const spinData = useSelector(state => state.lobby.spinData)
    const bonusData = useSelector(state => state.lobby.bonusData)
    const takeSeatData = useSelector(state => state.tournament.takeSeatData)
    const tourQualifiersData = useSelector(state => state.lobby.tournamentQualifiersData)


    function privateModalTable() {
        setPrivateModal(!privateModal)
        if (privateModal === false) {
            dispatch(getSettings)
            var range = gameSettings?.aPrivateTableRange.map(function (rg) { return rg.nTablePoint; });
            setPointValue(range?.length ? range[0] : 0.5)
            setPlayerToggle(2)
            setCode('')
            setPrivatetable("create")
        }

    }

    const MyJoinGame = () => setGameModal(!gameModal);
    const gameSettings = useSelector(state => state.account.data)
    const joinTournamentMessage = useSelector((state) => state.tournament.joinResMessage);
    const withdrawTournamentMessage = useSelector((state) => state.tournament.withdrawResMessage);


    const previousProps = useRef({ joinedTablesData, joinTournamentMessage, withdrawTournamentMessage, joinPrivateTableMessage, createPrivateTableMessage, joinTableStatus, createPrivateTableStatus, spinData }).current

    useEffect(() => {
        if (previousProps.joinTournamentMessage !== joinTournamentMessage) {
            if (joinTournamentMessage) {
                setLoading(false)
                toast(
                    <div>
                        <div>{joinTournamentMessage === "success." ? "Registered Successfully." : joinTournamentMessage}</div>
                    </div>
                    , { autoClose: 3000 });
            }
        }
        return () => {
            previousProps.joinTournamentMessage = joinTournamentMessage;
        };
    }, [joinTournamentMessage]);

    useEffect(() => {
        if (previousProps.withdrawTournamentMessage !== withdrawTournamentMessage) {
            if (withdrawTournamentMessage) {
                setLoading(false)
                toast(
                    <div>
                        <div>{withdrawTournamentMessage === "success." ? "Withdraw Successfully." : withdrawTournamentMessage}</div>
                    </div>
                    , { autoClose: 3000 });
            }
        }
        return () => {
            previousProps.withdrawTournamentMessage = withdrawTournamentMessage;
        };
    }, [withdrawTournamentMessage]);

    const [rHour, setRHour] = useState(0);
    const [rMin, setRMin] = useState(0);
    const [rSec, setRSec] = useState(0);

    function getTime() {
        const startDate = new Date(gameSettings?.oMaintenance?.dEndAt)
        const finishHours = startDate.getHours() + startDate.getMinutes() / 60 + startDate.getSeconds() / 3600;
        const currentHours = new Date().getHours() + new Date().getMinutes() / 60 + new Date().getSeconds() / 3600;
        const remainingHours = finishHours - currentHours;

        const remainingHour = Math.floor(remainingHours);
        const remainingMinute = Math.floor((remainingHours - remainingHour) * 60);
        const remainingSecond = Math.floor(((remainingHours - remainingHour) * 60 - remainingMinute) * 60)

        setRHour(remainingHour);
        setRMin(remainingMinute);
        setRSec(remainingSecond);
    }


    useEffect(() => {
        if (gameSettings?.aPrivateTableRange) {
            var range = gameSettings?.aPrivateTableRange.map(function (rg) { return rg.nTablePoint; });
            setPrivatetableRange(range)
            setPointValue(range?.length ? range[0] : 0.5)
            setFirstPoint(range?.length ? range[0] : 0.5)
        }
        if (gameSettings) {
            if (gameSettings?.oMaintenance?.eMode == "enabled" && gameSettings?.oMaintenance?.eState == "running") {
                setInterval(function () {
                    getTime()
                }, 1000);
                setMaintainance(true)
                // const date = moment(gameSettings?.oMaintenance?.dEndAt).format("DD MMMM, hh:mm A")
                // setEndMaintainanace(date)
            }
        }
    }, [gameSettings]);


    useEffect(() => {
        document.title = "RUMMY RANI | Lobby"
        setLoading(true);
        dispatch(getJoinedTables(token))
        dispatch(getSettings)
        // dispatch(getDailyBonus(token))

        const obj = 's'
        obj?.activeTab ? setActiveTab(obj.activeTab) : setActiveTab('2')
        obj?.modal === 'MyJoinedGames' && setJoinGameModal(true)
        // if (obj?.modal === 'privateModal') {
        //     // dispatch(getSettings)
        //     setPrivateTableModal(true)
        // }
        localStorage.removeItem('RegistrationOTP')
        localStorage.removeItem('LoginOTP')
        localStorage.removeItem('SocialLoginOTP')
        localStorage.removeItem('ForgotPasswordOTP');
        localStorage.removeItem('OTPTimer');

        setTop(window.top.outerHeight / 2 + window.top.screenY - (590 / 2))
        setLeft(window.top.outerWidth / 2 + window.top.screenX - (1079 / 2))

    }, [])

    useEffect(() => {
        if (tourQualifiersData) {
            setTournamentQualifiersData(tourQualifiersData)
        }
    }, [tourQualifiersData]);



    function togglejoinGameModal() {
        setJoinGameModal(!joinGameModal);
        // setActiveTabtables('1')
        // setActiveModal("MyJoinedGames")
        if (joinGameModal === true) {
            setActiveModal(null)
        }
        if (joinGameModal === false) {
            dispatch(getJoinedTables(token))
        }

    }

    useEffect(() => {
        if (pointValue) {
            setTableFee(pointValue * 240)
        }
    }, [pointValue])

    function handleSlider(e) {
        const rangeArray = gameSettings?.aPrivateTableRange
        switch (e.target.value) {
            case "0":
                setPointValue(rangeArray[0])
                break;
            case "1":
                setPointValue(rangeArray[1])
                break;
            case "2":
                setPointValue(rangeArray[2])
                break;
            case "3":
                setPointValue(rangeArray[3])
                break;
            case "4":
                setPointValue(rangeArray[4])
                break;
            case "5":
                setPointValue(rangeArray[5])
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        if (previousProps.joinedTablesData !== joinedTablesData) {
            if (joinedTablesData) {
                setLoading(false)
                setJoinedTablesList(joinedTablesData)
            }
        }
        return () => {
            previousProps.joinedTablesData = joinedTablesData
        }
    }, [joinedTablesData])

    useEffect(() => {
        if (previousProps.joinedTournamentData !== joinedTournamentData) {
            if (joinedTournamentData) {
                setLoading(false)
                setJoinedTournamentList(joinedTournamentData)
            }
        }
        return () => {
            previousProps.joinedTournamentData = joinedTournamentData
        }
    }, [joinedTournamentData])

    useEffect(() => {
        if (spinData?.aDailyBonus?.length) {
            setSpinModal(true)
            setSpinBonusList(spinData?.aDailyBonus)
        }
    }, [spinData])

    useEffect(() => {
        if (spinTimeout === true) {
            setTimeout(() => {
                setSpinModal(false)
                toast(
                    <div>
                        <div>Congratulations! You won &#8377;{bonusData?.nValue} bonus</div>
                    </div>
                );
            }, 4000);
            setSpinTimeout(false)
        }
    }, [spinTimeout]);


    function spinWheel(degree) {

        let wheelofspin = document.querySelector(".wheel");
        let borderofspin = document.querySelector(".border-image");

        wheelofspin.style.transform = "rotate(" + degree + "deg)";
        borderofspin.style.transform = "rotate(" + degree + "deg)";

        setSpinTimeout(true)
    }

    useEffect(() => {
        if (bonusData) {
            const index = spinBonusList.findIndex(x => x._id === bonusData?._id);
            switch (index) {
                case 0:
                    spinWheel(1890)
                    break
                case 1:
                    spinWheel(1840)
                    break
                case 2:
                    spinWheel(3040)
                    break
                case 3:
                    spinWheel(2090)
                    break
                case 4:
                    spinWheel(2040)
                    break
                case 5:
                    spinWheel(1990)
                    break
                case 6:
                    spinWheel(1940)
                    break
            }
        }
    }, [bonusData])


    useEffect(() => {
        if (takeSeatData) {
            dispatch(getJoinedTables(token))
        }
    }, [takeSeatData])


    useEffect(() => {
        if (bonusData) {

            const index = spinBonusList.findIndex(x => x._id === bonusData?._id);

            switch (index) {
                case 0:
                    spinWheel(1890)
                    break
                case 1:
                    spinWheel(1840)
                    break
                case 2:
                    spinWheel(3040)
                    break
                case 3:
                    spinWheel(2090)
                    break
                case 4:
                    spinWheel(2040)
                    break
                case 5:
                    spinWheel(1990)
                    break
                case 6:
                    spinWheel(1940)
                    break
            }
        }
    }, [bonusData])

    useEffect(() => {
        if (code?.length) {
            setDisableJoinTable(true)
        } else {
            setDisableJoinTable(false)
        }
    }, [code])

    useEffect(() => {
        if (tourQualifiersData) {
            setTimer(Math.ceil(((tournamentQualifiersData?.nStrategicDuration) / 1000)))
        }
    }, [tournamentQualifiersData]);

    useEffect(() => {
        if (timer > 0) {
            setTimeout(() => {
                setTimer(timer - 1)
            }, 1000);
        }
    }, [timer]);

    // function toggle() {
    //     setModal(!modal)
    //     setActiveTabtables('1')
    //     setActiveModal("MyJoinedGames")
    //     if (modal === true) {
    //         setActiveModal(null)
    //     }
    //     if (modal === false) {
    //         dispatch(getJoinedTables(token))
    //         dispatch(getJoinedTournaments(token))
    //     }
    // }
    function toggleSpin() {
        setSpinModal(!spinModal)
        if (spinModal === true) {
            dispatch(dismissDailyBonus(token))
        }
    }

    useEffect(() => {
        if (previousProps.joinTableStatus !== joinTableStatus) {
            if (joinTableStatus === true) {
                dispatch(getJoinedTables(token))
                dispatch(getProfile(token))
                seJoinedTableId([...joinedTableId, joinTableId?.iTableId])
                window.open(`${config?.GAME_URL}?sAuthToken=${token}&sTableId=${joinTableId?.iTableId}&nChips=${joinTableId?.nPracticeChips || joinTableId?.nChips}`, `${joinTableId?.iTableId}`, `popup=1, toolbar=0,status=0,width=1318,height=725, top=${top}, left=${left}`);
            }
            if (joinTableStatus === false) {
                // if (joinTableMessage === 'Couldn t find the table. Please try after sometim') {
                //     return
                // }
                if (joinTableMessage === 'success.') {
                    setJoinGameModal(true);

                }
                if (joinTableMessage === 'Insufficient Rummy chips') {
                    setIsInsufficientChips(true)
                }

                toast(
                    <div>
                        <div>{joinTableMessage}</div>
                    </div>
                    , { autoClose: 3000 });
            }
        }
        return () => {
            previousProps.joinTableStatus = joinTableStatus
        }
    }, [joinTableStatus])

    useEffect(() => {
        if (previousProps.joinPrivateTableStatus !== joinPrivateTableStatus) {
            if (joinPrivateTableStatus === true) {
                setLoading(false)
                dispatch(getJoinedTables(token))
                dispatch(getProfile(token))
                setCode('')
                window.open(`${config?.GAME_URL}?sAuthToken=${token}&sTableId=${joinPrivateTableData?.iTableId}&nChips=${joinPrivateTableData?.nChips}`, '', `popup=1, toolbar=0,status=0,width=1318,height=725, top=${top}, left=${left}`);
            }
            if (joinPrivateTableStatus === false) {
                if (joinPrivateTableMessage?.length && joinPrivateTableMessage !== "success.") {
                    setLoading(false)
                    setCode('')
                    toast(
                        <div>
                            <div>{joinPrivateTableMessage}</div>
                        </div>
                    );
                }
            }
        }
        return () => {
            previousProps.joinPrivateTableStatus = joinPrivateTableStatus
        }
    }, [joinPrivateTableStatus])


    useEffect(() => {
        if (previousProps.createPrivateTableStatus !== createPrivateTableStatus) {
            if (createPrivateTableStatus === true) {
                setLoading(false)
                dispatch(getProfile(token))
                // setPrivateTableModal(false)
                window.open(`${config?.GAME_URL}?sAuthToken=${token}&sTableId=${createPrivateTableData?.iTableId}&nChips=${createPrivateTableData?.nChips}&sPrivateCode=${createPrivateTableData?.sPrivateCode}`, '', `popup=1, toolbar=0,status=0,width=1318,height=725, top=${top}, left=${left}`);
            }
            if (createPrivateTableStatus === false) {
                setLoading(false)
            }
        }
        return () => {
            previousProps.createPrivateTableStatus = createPrivateTableStatus
        }
    }, [createPrivateTableStatus])


    useEffect(() => {
        if (previousProps.createPrivateTableStatus !== createPrivateTableStatus) {
            if (createPrivateTableStatus === true) {
                dispatch(getJoinedTables(token))
            }
        }
        return () => {
            previousProps.createPrivateTableStatus = createPrivateTableStatus
        }
    }, [createPrivateTableStatus])

    useEffect(() => {
        if (previousProps.createPrivateTableMessage !== createPrivateTableMessage) {
            if (createPrivateTableMessage?.length && createPrivateTableMessage !== "success.") {
                setLoading(false)
                toast(
                    <div>
                        <div>{createPrivateTableMessage}</div>
                    </div>
                );
            }
        }
        return () => {
            previousProps.createPrivateTableMessage = createPrivateTableMessage
        }
    }, [createPrivateTableMessage])


    // function privateTableToggle() {
    //     setPrivateTableModal(!privateTableModal)
    //     setActiveModal("privateModal")
    //     if (privateTableModal === false) {
    //         dispatch(getSettings)
    //         setSwitchToJoinTable(false)
    //         setSwitchToCreateTable(false)
    //     }
    //     if (privateTableModal === true) {
    //         setActiveModal(null)
    //     }
    // }

    const toggletables = tab => {
        if (activeTabtables !== tab) setActiveTabtables(tab);
    }

    const privateTableToggleTables = tab => {
        if (activeTabPrivatetables !== tab) setActiveTabPrivatetables(tab);
    }

    const toogleMainTab = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    function handleEnterCode(e) {
        setCode(e.target.value)
    }

    function handleJoinTable() {
        if (maintainance == false) {
            setLoading(true)
            dispatch(joinPrivateTable(code, token))
        } else if (maintainance == true) {
            setShowWarning(true)
        }
    }

    function handleRefreshJoinedGames() {
        setLoading(true)
        dispatch(getJoinedTables(token))
        dispatch(getJoinedTournaments(token))
    }

    function joinedGamePopup(tableId, type, privateCode) {
        if (maintainance == false) {
            const matchedWindow = joinedTableId?.filter(item => {
                return item === tableId;
            });
            if (matchedWindow) {
                window.open(`${config?.GAME_URL}?sAuthToken=${token}&sTableId=${tableId}&nChips=${type == "practice" ? profileData?.nPracticeChips : profileData?.nChips}&privateCode=${privateCode}`, `${matchedWindow}`, `popup=1, toolbar=0,status=0,width=1318,height=725, top=${top}, left=${left}`);
            } else {
                window.open(`${config?.GAME_URL}?sAuthToken=${token}&sTableId=${tableId}&nChips=${type == "practice" ? profileData?.nPracticeChips : profileData?.nChips}&privateCode=${privateCode}`, "", `popup=1, toolbar=0,status=0,width=1318,height=725, top=${top}, left=${left}`);
            }
        } else if (maintainance == true) {
            setShowWarning(true)
        }
    }

    function handleSpin() {
        dispatch(getBonusWinAmount(token))
    }

    useEffect(() => {
        if (privatetableRange?.length) {
            const range = privatetableRange
            let slider

            switch (range?.length) {
                case 1:
                    slider = [
                        { value: range[0] }
                    ]
                    break;
                case 2:
                    slider = [
                        { value: range[0], step: range[1] - range[0] },
                        { value: range[1] }
                    ]
                    break;
                case 3:
                    slider = [
                        { value: range[0], step: range[1] - range[0] },
                        { value: range[1], step: range[2] - range[1] },
                        { value: range[2] }
                    ]
                    break;
                case 4:
                    slider = [
                        { value: range[0], step: range[1] - range[0] },
                        { value: range[1], step: range[2] - range[1] },
                        { value: range[2], step: range[3] - range[2] },
                        { value: range[3] }
                    ]
                    break;
                case 5:
                    slider = [
                        { value: range[0], step: range[1] - range[0] },
                        { value: range[1], step: range[2] - range[1] },
                        { value: range[2], step: range[3] - range[2] },
                        { value: range[3], step: range[4] - range[3] },
                        { value: range[4] }
                    ]
                    break;
                case 6:
                    slider = [
                        { value: range[0], step: range[1] - range[0] },
                        { value: range[1], step: range[2] - range[1] },
                        { value: range[2], step: range[3] - range[2] },
                        { value: range[3], step: range[4] - range[3] },
                        { value: range[4], step: range[5] - range[4] },
                        { value: range[5] }
                    ]
                    break;
                default:
                    break;
            }




            // const slider = [
            //     { value: range[0], step: range[1] - range[0] },
            //     { value: range[1], step: range[2] - range[1] },
            //     { value: range[2], step: range[3] - range[2] },
            //     { value: range[3], step: range[4] - range[3] },
            //     { value: range[4], step: range[5] - range[4] },
            //     { value: range[5] }
            // ]
            setRangeSlider(slider)
        }
    }, [privatetableRange]);

    function handleChangeSlider(value) {
        setPointValue(value)
    }

    function handleCreateTable() {
        // setLoading(true)
        // dispatch(createPrivateTable('point', pointValue, playerToggle, token))

        if (maintainance == false) {
            setLoading(true)
            dispatch(createPrivateTable('point', pointValue, playerToggle, token))
        } else if (maintainance == true) {
            setShowWarning(true)
        }

    }

    function confirmSeat(tableId, tournamentId) {
        if (tableId === '-') {
            setTourQualifierModal(true)
            dispatch(getTournamentQualifiers(tournamentId, token))
        } else {
            window.open(`${config?.GAME_URL}?sAuthToken=${token}&sTableId=${tableId}&nChips=${profileData?.nChips}`, `${tableId}`, `popup=1, toolbar=0,status=0,width=1318,height=725, top=${top}, left=${left}`);
        }
    }

    function toggleTourQualifierModal() {
        setTourQualifierModal(!tourQualifierModal);
    }

    function formattedSeconds(sec) {
        return moment().startOf('day').seconds(sec).format('mm:ss');
    }

    return (
        <div className="after-login">
            {loading && <Loading />}
            <div className="main-content">
                <div className="point-table table-section points-rummy-section">
                    <div className="container">
                        <div className="tab-main">
                            <div className="table-heading">Points Rummy</div>
                            <div className='point-rummy-sec'>
                                <div className='points-rummy-inner-sec'>
                                    <div className="tab-header tab-main-header d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center modal-btns">
                                            {/* <Button className="btn privateTable-button"  onClick={privateModalTable}> <span> Private Table </span></Button> */}

                                        </div>
                                    </div>

                                    <div className="tab-main-body points-tabs">
                                        <TabContent activeTab={activeTab} className="tab-inner practice">
                                            <TabPane tabId="1">
                                                <Practice mainTab={activeTab} maintainance={maintainance} hour={rHour} min={rMin} sec={rSec} />
                                            </TabPane>
                                            <TabPane tabId="2">
                                                <Cash mainTab={activeTab} maintainance={maintainance} hour={rHour} min={rMin} sec={rSec} />
                                            </TabPane>
                                            <TabPane tabId="3">
                                                <Tournament mainTab={activeTab} maintainance={maintainance} hour={rHour} min={rMin} sec={rSec} />
                                            </TabPane>
                                            <TabPane tabId="4">
                                                <div className="table-cnt table-cnt-box">
                                                    <h1 className="text-center">Coming Soon</h1>
                                                </div>
                                            </TabPane>
                                        </TabContent>
                                        {/* <div className="footer-links">
                                    <ul className=" list-unstyled">
                                        <li>
                                            <Link to="/how-to-play-prv" target="_blank"><img src={howPlay} alt="how-play" />How to Play</Link>
                                        </li>
                                        <li>
                                            <Link to="/report-problem"><img src={reportProblem} alt="report-problem" />Report Problem</Link>
                                        </li>
                                        <li>
                                            <Link to="/tearms-of-service-prv" target="_blank"><img src={termsCondition} alt="termsCondition" />Terms and Conditions</Link>
                                        </li>
                                        <li>
                                            <Link to="/privacy-prv" target="_blank"><img src={privacyPolicy} alt="privacyPolicy" />Privacy Policy</Link>
                                        </li>
                                    </ul>
                                </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className='text-center'>
                                <div className='footer-link'>
                                    <ul className=" list-unstyled">
                                        <li>
                                            <Link to="/how-to-play-prv" target="_blank"><img src={howPlay} alt="how-play" />How to Play</Link>
                                        </li>
                                        <li>
                                            <Link to="/report-problem" target="_blank"><img src={reportProblem} alt="report-problem" />Report Problem</Link>
                                        </li>

                                    </ul>
                                </div>
                                <Button className="btn privateTable-button" onClick={togglejoinGameModal} > <span>My Joined Games  ({joinedTablesList?.length})</span> </Button>
                                <div className='footer-link-2'>
                                    <ul className=" list-unstyled">
                                        <li>
                                            <Link to="/tearms-of-service-prv" target="_blank"><img src={termsCondition} alt="termsCondition" />Terms & Conditions</Link>
                                        </li>
                                        <li>
                                            <Link to="/privacy-prv" target="_blank"><img src={privacyPolicy} alt="privacyPolicy" />Privacy Policy</Link>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                {/* <div className="contact-info">
                    <div className="container ">
                        <div className="text-center">
                            For any queries, contact us on &nbsp;
                            <a href="mailto:support@rummyrani.co.in">
                                support@rummyrani.co.in
                            </a>
                        </div>
                    </div>
                </div> */}
            </div >


            {/* <Modal isOpen={spinModal} toggle={toggleSpin} modalClassName="spinner-modal">
                <ModalHeader className="justify-content-center" toggle={toggleSpin}>SPIN</ModalHeader>
                <ModalBody>
                    <div className="spinner d-flex justify-content-center">
                        <div id="main" className="main">
                            <img src={border} alt="border-image" className="border-image" />
                            <div id="wheel" className="wheel">
                                {
                                    spinBonusList?.map((spin, i) => (
                                        <div className={`content content-${i + 1}`} key={i}>
                                            <div className="desc">
                                                <span> &#8377; {spin?.nValue}</span>
                                                <p>BONUS</p>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            <button id="spin" type="button" onClick={handleSpin} className="spin"><img src={spinBtn} alt="spin-image"
                                className="img-fluid" /></button>
                            <img src={newWheel} alt="wheel-arrow" className="img-fluid arrow-image" />
                        </div>
                    </div>
                </ModalBody>
            </Modal> */}
            <Modal isOpen={modal} className="join-modal">
                <ModalHeader className="text-center">

                </ModalHeader>

                <ModalBody>


                </ModalBody>
            </Modal>

            <Modal isOpen={joinGameModal} toggle={togglejoinGameModal} className="modal-dialog-centered joinTableModal myJoinTableModalWrap"  >
                <ModalBody>
                    <div className="row d-flex justify-content-center my-join-game-popup">
                        <h3 className="addlogout-Tittle"
                            // className="modal-title text-center"
                            id="exampleModalLongTitle"
                        >
                            My Joined Games ({joinedTablesList?.length}) <span></span>
                        </h3>
                        {joinedTablesList?.length ? joinedTablesList.map((joinedtable, i) => (
                            <div className="col-xl-4 text-center d-flex justify-content-center" key={i}>
                                <div className="old-game-wrap">
                                    <div className="img-box text-center d-flex align-items-center justify-content-center">
                                        <img src={UserIcon} alt="user-icon" />
                                        <div className="outer-box">
                                            <div className="inner-box">
                                                <div className="inner-blue-bg">
                                                    <p>{joinedtable?.eRummyType == "point" ? 'Points Rummy' : joinedtable?.eRummyType == 'pool' ? 'Pools Rummy' : joinedtable?.eRummyType == "point21" ? 'Point21 Rummy' : 'Deals Rummy'}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <img src={UserIcon} alt="user-icon" />
                                    </div>
                                    <div className="old-game-detail-wrap">
                                        <div className="d-flex justify-content-between flex-wrap">
                                            <div className="old-detail-box">
                                                <div className="blue-bg">
                                                    <p>Type</p>
                                                    <p>{joinedtable?.eGameType}</p>
                                                </div>
                                            </div>
                                            <div className="old-detail-box">
                                                <div className="blue-bg">
                                                    <p>{joinedtable?.eRummyType == "point" || joinedtable?.eRummyType == "point21" ? 'Decks' : joinedtable?.eRummyType == 'pool' ? 'Type' : 'Deals'}</p>
                                                    <p>{joinedtable?.eRummyType == "point" || joinedtable?.eRummyType == "point21" ? '2' : joinedtable?.eRummyType == 'pool' ? joinedtable?.nTablePoint : joinedtable?.nTablePoint}</p>
                                                </div>
                                            </div>
                                            <div className="old-detail-box">
                                                <div className="blue-bg">
                                                    <p>{joinedtable?.eRummyType == "point" || joinedtable?.eRummyType == "point21" ? 'Points Value' : joinedtable?.eRummyType == 'pool' ? 'Entry Fee' : 'Entry Fee'}</p>
                                                    <p>{joinedtable?.eRummyType == "point" || joinedtable?.eRummyType == "point21" ? joinedtable?.nTablePoint : joinedtable?.eRummyType == 'pool' ? joinedtable?.nTableFee : joinedtable?.nTableFee}</p>
                                                </div>
                                            </div>
                                            {/* <div className="old-detail-box">
                                                                        <div className="blue-bg">
                                                                            <p>Min Entry</p>
                                                                            <p>100</p>
                                                                        </div>
                                                                    </div> */}
                                        </div>
                                        <div className="text-center">
                                            <Button
                                                type="button"
                                                className="green-button"
                                                style={{ "padding": "13px 38px" }}
                                                onClick={() => { joinedtable?.eGameType === "tournament" ? confirmSeat(joinedtable?._id, joinedtable?.iTournamentId) : joinedGamePopup(joinedtable?._id, joinedtable?.eGameType, joinedtable?.sPrivateCode) }}
                                            >
                                                <span>Join</span>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )) : <div className="text-center no-tournment">
                            <p>You haven&apos;t yet joined any Tables.</p>
                        </div>}
                    </div>
                </ModalBody>

            </Modal>

            <Modal isOpen={privateModal} toggle={privateModalTable} className="modal-dialog-centered modal-private-modal-wrap CreateModalWrap">
                <ModalHeader toggle={privateModalTable}>
                    <h3 className="modal-title text-center" id="exampleModalLongTitle1" >
                        Private Table
                    </h3>
                </ModalHeader>

                <ModalBody>
                    <div className="panel with-nav-tabs panel-info">
                        <div className="panel-heading">
                            <div className="tabs-wrap d-flex">
                                <div
                                    className={`inner-tab ${privatetable === "create" && "active"
                                        }`}
                                    id="create-rummy-tab"
                                    data-toggle="tab"
                                    href="#create-rummy"
                                    role="tab"
                                    aria-controls="create-rummy"
                                    aria-selected="true"
                                    onClick={() => { setPrivatetable("create"); setPointValue(firstPoint) }}
                                >
                                    <a>Create</a>
                                </div>
                                <div
                                    className={`inner-tab ${privatetable === "join" && "active"}`}
                                    id="join-rummy-tab"
                                    data-toggle="tab"
                                    href="#join-rummy"
                                    role="tab"
                                    aria-controls="join-rummy"
                                    aria-selected="false"
                                    onClick={() => setPrivatetable("join")}
                                >
                                    <a>join</a>
                                </div>
                            </div>
                        </div>

                        <div className="panel-body">
                            <div className="tab-content">
                                <div className="tab-content" id="tabInnerContent">
                                    {privatetable === "create" && (
                                        <div
                                            className="tab-pane fade show active"
                                            id="create-rummy"
                                            role="tabpanel"
                                            aria-labelledby="create-rummy-tab"
                                        >
                                            <div className="text-center" id="two-player-tab"
                                            >
                                                <div
                                                    className="btn-group btn-group-toggle player-radio-btns-group"

                                                >
                                                    <button type='button' onClick={() => setPlayerToggle(2)} className={playerToggle == 2 ? 'active' : ''}>
                                                        <span> 2 Players </span>
                                                    </button>
                                                    <button type='button' onClick={() => setPlayerToggle(6)} className={playerToggle == 6 ? 'active' : ''}>
                                                        <span> 6 Players </span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-center">
                                                <p>
                                                    Point Value :
                                                    <span id="loan-term"> {pointValue}</span>
                                                    {/* <span>{pointValue}</span> */}
                                                </p>{" "}
                                                {/* <p>Entry Fee : ₹ 100 */}
                                                {/* <span>&#8377; {pointValue * 240}</span> */}
                                                {/* </p> */}
                                            </div>


                                            <div className=" slider-btn-wrapper d-flex align-items-center">
                                                {/* <button type="button" className="btn minus-btn"></button> */}
                                                <div className="w-100 ">
                                                    <StepRangeSlider
                                                        value={0.5}
                                                        defaultValue={0.5}
                                                        range={rangeSlider}
                                                        onChange={value => handleChangeSlider(value)}
                                                    />
                                                    <div className='d-flex justify-content-between  range-values-wrap'>
                                                        {privatetableRange?.length &&
                                                            <>
                                                                <span className='range-first'>{privatetableRange[0]}</span>
                                                                <span>{privatetableRange[1]}</span>
                                                                <span>{privatetableRange[2]}</span>
                                                                <span>{privatetableRange[3]}</span>
                                                                <span>{privatetableRange[4]}</span>
                                                                <span>{privatetableRange[5]}</span>
                                                            </>
                                                        }
                                                        {/* <span>2.0</span>
                                                                                <span>0.05</span>
                                                                                <span>0.10</span>
                                                                                <span>0.50</span>
                                                                                <span>1.0</span>
                                                                                <span>4.0</span> */}
                                                    </div>
                                                </div>
                                                {/* <button type="button" className="btn pluse-btn"></button> */}
                                            </div>
                                            <button type="button" onClick={handleCreateTable} className="backgroundbtn" >
                                                <span > Create Table </span>
                                            </button>
                                        </div>
                                    )}


                                    {/*  */}
                                    {privatetable === "join" && (
                                        <div className="tab-pane fade show active" id="join-rummy" role="tabpanel" aria-labelledby="join-rummy-tab" >
                                            <div className="join-table-content">
                                                <div className="form-group">
                                                    <input type="text" onInput={(e) => e.target.value = e.target.value.slice(0, 6)} maxLength="6" onChange={handleEnterCode} value={code} className="form-control number" id="room-no" placeholder="Enter the code and join table" />
                                                </div>
                                                <div className=" text-center">
                                                    <button type="button" disabled={!disableJoinTable} onClick={handleJoinTable} className="backgroundbtn" style={{ "padding": "22px 20px" }}>
                                                        <span>Join Table</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={tourQualifierModal} toggle={toggleTourQualifierModal} className=" qualifier-modal-wrap" centered>
                {/* <div className="modal-cnt"> */}
                <ModalHeader toggle={toggleTourQualifierModal}>
                    <h3 className="modal-title text-center" id="exampleModalLongTitle1" >
                        Tournament Qualifiers {tournamentQualifiersData?.aQualifier?.length && `(${tournamentQualifiersData?.aQualifier?.length}/${tournamentQualifiersData?.nTotalQualifier})`}
                    </h3>
                </ModalHeader>
                {tournamentQualifiersData?.aQualifier?.length ?
                    <>
                        <div className="table-responsive">
                            <table className="common-table table">
                                <thead>
                                    <tr>
                                        <th className="text-center">User</th>
                                        <th className="text-center">Score</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tournamentQualifiersData?.aQualifier?.map((qual, i) => (
                                        <tr key={i}>
                                            <td className="text-left">
                                                <img src={UserIcon} alt="user-icon" />
                                                {qual?.sUserName}
                                            </td>
                                            <td className="text-left">
                                                {qual?.aScoreBoard?.length && qual?.aScoreBoard[0]}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <p className='text-center'>New tournament round {(tournamentQualifiersData?.nTournamentRound) + 1} will start in {formattedSeconds(timer)}


                            {/* {formattedSeconds(Math.ceil(((tournamentQualifiersData?.nStrategicDuration) / 1000)))} */}
                        </p>
                    </>
                    : <div className="data-not-found">
                        <h4 className="text-center">No Qualifiers Found.</h4>
                    </div>}
                {/* <p className='text-center'>New tournament round {(tournamentQualifiersData?.nTournamentRound) + 1} will start in {Math.ceil(((tournamentQualifiersData?.nStrategicDuration) / 1000))} seconds</p> */}
                {/* </div> */}
            </Modal>
            <Modal isOpen={showWarning} className="logout-modal modal-dialog-centered " >
                <div className="modal-body logoutbackground maintainace-bg">
                    <div className="txt-msg-box">
                        <p><strong>The game is currently under maintenance.</strong> <br /> <br />It will be back in {rHour > 0 && ("0" + rHour).slice(-2)}{rHour > 0 && ":"}{("0" + rMin).slice(-2)}:{("0" + rSec).slice(-2)}.
                            {/* {endMaintainanace}. */}
                        </p>
                    </div>
                    <div className="new-create-box text-center">
                        <button
                            type="button"
                            className="btn theme-orange-btn"
                            onClick={() => setShowWarning(false)}
                        >
                            <span>okay</span>
                        </button>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={isInsufficientChips}
                className='logout-modal modal-dialog-centered start-game-popup '
            >
                <div className='modal-body '>
                    <div className='txt-msg-box insufficientChips-popup'>
                        <p>Insufficient Rummy chips</p>
                    </div>
                    <div className='new-create-box text-center'>
                        <button type='button' className='btn theme-orange-btn' onClick={() => setIsInsufficientChips(false)}>
                            <span>ok</span>
                        </button>

                    </div>
                </div>
            </Modal>
        </div >
    );
}
export default connect()(LobbyDetails);
